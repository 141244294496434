import React from 'react';

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

import { PdfFileViewer } from '../PdfFileViewer/PdfFileViewer';

export const FileViewerModal = ({
  file,
  onClose,
}: {
  file: {
    url: string;
    type: 'pdf' | 'image';
  };
  onClose: () => void;
}) => {
  const renderPdfFile = () => {
    return <PdfFileViewer url={file.url} />;
  };

  const renderImageFile = () => {
    return (
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <img src={file.url} />
      </div>
    );
  };

  const renderUnknownFileTypeError = () => (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <p>Error opening the file. Received unknown file type: {file.type}</p>
    </div>
  );

  const renderContent = () => {
    if (file.type === 'pdf') {
      return renderPdfFile();
    }

    if (file.type === 'image') {
      return renderImageFile();
    }

    return renderUnknownFileTypeError();
  };

  return (
    <Dialog open onClose={onClose} fullScreen>
      <DialogContent>{renderContent()}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary' variant='contained'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
