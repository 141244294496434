import { useState } from 'react';

import { Dialog, DialogActions, DialogContent, TextField } from '@mui/material';
import { Button, useCreate, useCreateSuggestionContext } from 'react-admin';

import { offerOptions } from '../../../../constants/choices/offer.options.choices';

interface ModalOfferOptionCreateProps {
  type: string;
}

const ModalOfferOptionCreate = ({ type }: ModalOfferOptionCreateProps) => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext();
  const [value, setValue] = useState(filter || '');
  const [create] = useCreate();
  const offerOptionSelected = offerOptions.find(option => option.id === type);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    create(
      'offer-options',
      {
        data: { type, name: value },
      },
      {
        onSuccess: data => {
          setValue('');
          onCreate(data);
        },
      },
    );
  };

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <TextField
            label={`New ${offerOptionSelected?.name || 'Offer Option'}`}
            value={value}
            onChange={(event: any) => setValue(event.target.value)}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Button type='submit' label='Save' />
          <Button onClick={onCancel} label='Cancel' />
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalOfferOptionCreate;
