import { OfferUrlConstant } from 'src/constants';
import {
  CompleteOfferDto,
  CreateOfferDto,
  GetOfferDto,
  GetOfferListDto,
  GetPublicOfferDto,
  NewOfferCardPaymentAccountLinkDto,
  OfferDetailsDto,
  OfferDto,
  PatchOfferDto,
  PublicOfferDetailsDto,
  PublicOfferDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class OffersApi {
  private _configService: ConfigService;

  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async list(dto?: GetOfferListDto): Promise<OfferDto[]> {
    return httpRequest<OfferDto[]>({
      method: 'GET',
      url: OfferUrlConstant.LIST,
      queryParams: dto?.queryParams,
      authToken: this._configService.authToken,
    });
  }

  async retrieve({ params }: GetOfferDto): Promise<OfferDetailsDto> {
    const url = replacePlaceholders(OfferUrlConstant.RETRIEVE, params);

    return httpRequest<OfferDetailsDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async listPublic(): Promise<PublicOfferDto[]> {
    return httpRequest<PublicOfferDto[]>({
      method: 'GET',
      url: OfferUrlConstant.LIST_PUBLIC,
      authToken: this._configService.authToken,
    });
  }

  async retrievePublic({ params }: GetPublicOfferDto): Promise<PublicOfferDetailsDto> {
    const url = replacePlaceholders(OfferUrlConstant.RETRIEVE_PUBLIC, params);

    return httpRequest<PublicOfferDetailsDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async create({ body }: CreateOfferDto): Promise<OfferDetailsDto> {
    return httpRequest<OfferDetailsDto>({
      method: 'POST',
      url: OfferUrlConstant.CREATE,
      body,
      authToken: this._configService.authToken,
    });
  }

  async patch({ params, body }: PatchOfferDto): Promise<OfferDetailsDto> {
    const url = replacePlaceholders(OfferUrlConstant.PATCH, params);

    return httpRequest<OfferDetailsDto>({
      method: 'PATCH',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }

  async complete({ params, body }: CompleteOfferDto): Promise<OfferDetailsDto> {
    const url = replacePlaceholders(OfferUrlConstant.COMPLETE, params);

    return httpRequest<OfferDetailsDto>({
      method: 'PATCH',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }

  async newCardPaymentAccountLink({ params }: NewOfferCardPaymentAccountLinkDto): Promise<OfferDetailsDto> {
    const url = replacePlaceholders(OfferUrlConstant.NEW_CARD_PAYMENT_ACCOUNT_LINK, params);

    return httpRequest<OfferDetailsDto>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }
}
