import { Button } from 'react-admin';
import { useFormContext } from 'react-hook-form';

export const AddDefaultSecurityDeliveryOptionsButton = () => {
  const { setValue } = useFormContext();

  const onAdd = () => {
    setValue(
      'externalDetails.disclaimer',
      'This Offering requires you to send funds directly from an existing bank account to the Offering Escrow Account. ' +
        'You will receive an email with instructions on how to complete your purchase. For this offering type, If you ' +
        'have funds in your My IPO account they cannot be transferred to the Offering Escrow Account. The securities ' +
        'purchased will be held in electronic form at the Transfer Agent of the Issuer. Some brokerage firms may not hold ' +
        'these securities or may charge fees to deposit these securities.',
      {
        shouldDirty: true,
      },
    );
    setValue('externalDetails.securityDeliveryOptions', [
      {
        hasTooltip: true,
        isReadOnly: false,
        label: 'Retain at the <tooltip>Transfer Agent</tooltip> (Securities held in electronic form)',
        initialValue: true,
        tooltip:
          'Public companies typically use transfer agents to keep track of the individuals and entities that own their securities.' +
          "Transfer agents keep records of who owns a company's securities and how those securities are held—whether by the owner in certificate form" +
          ", by the company in book-entry form (electronic form), or by the investor's brokerage firm in street name. They also keep records " +
          'of how many shares each investor owns.',
      },
    ]);
  };

  return <Button onClick={onAdd} label='Add Default Security Delivery Options' />;
};
