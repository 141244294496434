export const stringifyUrlQueryParams = (params: Record<string, any>): string => {
  const qs = Object.entries(params)
    .filter(aParam => Boolean(aParam[1]))
    .map(pair => {
      if (Array.isArray(pair[1])) {
        return `${pair[0]}=${JSON.stringify(pair[1])}`;
      }

      if (typeof pair[1] === 'object') {
        return `${pair[0]}=${JSON.stringify(pair[1])}`;
      }

      return `${pair[0]}=${pair[1]}`;
    });

  return `${qs.join('&')}`;
};
