import * as React from 'react';

import { isEmpty } from 'lodash';
import { Labeled, SelectField, useRecordContext } from 'react-admin';
import { Row, Col } from 'react-grid-system';

import {
  liquidityNeedChoices,
  investmentObjectiveChoices,
  riskToleranceChoices,
  timeHorizonChoices,
  yearsOfExperienceChoices,
  tradesPerYearsChoices,
} from '../../../../constants/choices/suitability.information.choices';
import { FIEFieldType, SuitabilityInformationDto } from '../../../../dtos/accounts.dtos';

export const capitalizeSentenceWords = (sentence: string) =>
  isEmpty(sentence)
    ? ''
    : sentence.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) => letter.toUpperCase());

export const InvestmentExperience = (props: any) => {
  const record = useRecordContext(props);
  if (props)
    return (
      <>
        <Row>
          <Col sm={7}>
            <div className='investmentExperience'>
              <span>{capitalizeSentenceWords(record.name)}</span>
              <Row>
                <Col sm={6}>
                  <div className='fieldTitle'>Years of Experience</div>
                  <div className='fieldValue'>
                    <SelectField source='yearsOfExperience' record={record.body} choices={yearsOfExperienceChoices} />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className='fieldTitle'>Trades Per Year</div>
                  <div className='fieldValue'>
                    <SelectField source='tradesPerYear' record={record.body} choices={tradesPerYearsChoices} />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </>
    );

  return <></>;
};

export const SuitabilityInformationShow = (record: SuitabilityInformationDto) => {
  const { financialInvestmentExperience } = record;

  return (
    <div className='showContent'>
      <Row>
        <Col sm={12}>
          <h2 className='title'>Suitability Information</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled label='Liquidity Needs'>
            <SelectField source='liquidityNeeds' record={record} choices={liquidityNeedChoices} />
          </Labeled>
        </Col>
        <Col md={6}>
          <Labeled label='Investment Objectives'>
            <SelectField source='investmentObjectives' record={record} choices={investmentObjectiveChoices} />
          </Labeled>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled label='Risk Tolerance'>
            <SelectField source='riskTolerance' record={record} choices={riskToleranceChoices} />
          </Labeled>
        </Col>
        <Col md={6}>
          <Labeled label='Time Horizon'>
            <SelectField source='timeHorizon' record={record} choices={timeHorizonChoices} />
          </Labeled>
        </Col>
      </Row>
      {financialInvestmentExperience && <div className='subHeader'>Investment Experience</div>}

      {financialInvestmentExperience &&
        Object.keys(financialInvestmentExperience).map(key => (
          <InvestmentExperience
            key={key}
            record={{ name: key, body: financialInvestmentExperience?.[key as keyof FIEFieldType] }}
          />
        ))}
    </div>
  );
};
