import * as React from 'react';

import { Grid } from '@mui/material';
import { Show, EmailField, ReferenceField, TextField, SimpleShowLayout } from 'react-admin';

import ActiveField from '../Fields/ActiveField';

export const IndicationOfInterestShow = () => (
  <Show emptyWhileLoading title={'Offer Indication of Interest'}>
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>Offer Indication of Interest</h2>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <ReferenceField source='offerId' reference='offers' sortBy='name' sortable={false} link={'show'}>
            <TextField label='Offer' source='name' fullWidth sortBy='name' />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <TextField source='firstName' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <TextField source='lastName' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <EmailField source='email' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <TextField source='phoneNumber' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <TextField source='stateCode' />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <TextField source='investmentRange' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <ActiveField source='active' fullWidth />
      </Grid>
    </Grid>
  </Show>
);

export default IndicationOfInterestShow;
