import * as React from 'react';

import { getOfferOrderStatusDtoTagStyle } from 'src/utils';

import { OfferOrderStatusDto } from '../../dtos/offerOrders.dtos';
import { OfferOrderStatus } from '../../models/offerOrders.models';

interface OfferOrderStatusDtoTagProps {
  value: OfferOrderStatusDto;
}

export const OfferOrderStatusDtoTag = ({ value }: OfferOrderStatusDtoTagProps) => {
  const model = new OfferOrderStatus(value);

  return <div style={getOfferOrderStatusDtoTagStyle(value)}>{model.label}</div>;
};
