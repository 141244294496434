import { Grid } from '@mui/material';
import { ArrayField, Datagrid, FunctionField, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';
import { OfferDetailsDto } from 'src/dtos';

export const OfferEmailsShow = () => {
  const record = useRecordContext<OfferDetailsDto>();

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>Offer Emails</h2>
        </SimpleShowLayout>
      </Grid>
      {record.orderStatusUpdateEmailFields && (
        <Grid item xs={12} sm={12}>
          <SimpleShowLayout>
            <FunctionField
              label='Offer Order Status Update Email'
              render={(record: OfferDetailsDto) => (
                <ArrayField source='orderStatusUpdateEmailFields' record={record}>
                  <Datagrid bulkActionButtons={false}>
                    <TextField source='status' label='Status' />
                    <TextField source='line' label='Line' />
                    <TextField source='footer' label='Footer' />
                  </Datagrid>
                </ArrayField>
              )}
            />
          </SimpleShowLayout>
        </Grid>
      )}
    </Grid>
  );
};
