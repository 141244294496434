import * as React from 'react';

import { List, Datagrid, TextField, DateField, EmailField, BooleanField, FunctionField } from 'react-admin';
import { StatusTag } from 'src/lib';
import { User } from 'src/models';
import { getAccountStatusTagPalette } from 'src/utils';

import { UserListFilters } from '../../constants/choices/user.choices';
import { PhoneField } from '../Fields';
import { UserListActions } from './UserListActions';

const defaultSort = { field: 'createdAt', order: 'DESC' };

export const UserList = () => (
  <List title='Users' filters={UserListFilters} sort={defaultSort} actions={<UserListActions />}>
    <Datagrid rowClick='show' bulkActionButtons={false}>
      <TextField source='id' label='Id' />
      <TextField source='account.accountId' label='Account id' />
      <TextField source='account.accountNumber' label='Account number' />
      <TextField source='firstName' />
      <TextField source='lastName' />
      <EmailField source='email' />
      <PhoneField source='phoneNumber' />
      <FunctionField
        label='Account status'
        render={(record: User) => {
          if (record.account) {
            return (
              <StatusTag
                value={record.account.status.label}
                style={getAccountStatusTagPalette(record.account.status.value)}
              />
            );
          }

          return <StatusTag value={'NotSubmitted'} style={getAccountStatusTagPalette('NotSubmitted')} />;
        }}
      />
      <BooleanField source='isClosed' />
      <BooleanField source='isLocked' />
      <BooleanField source='isApproved' />
      <DateField source='createdAt' label='Created at' />
    </Datagrid>
  </List>
);
