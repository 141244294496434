import React from 'react';

import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import { DragDropElementTree } from '../ExportModal/models/export.models';
import DroppableArea from './DroppableArea';

interface DragAndDropAreaProps {
  items: DragDropElementTree[] | undefined;
  handleOnDragEnd: (result: DropResult) => void;
  onChangeValue: (itemId: string) => void;
}

export const DragAndDropArea = ({ items, handleOnDragEnd, onChangeValue }: DragAndDropAreaProps) => {
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <DroppableArea items={items} onChangeValue={onChangeValue} />
    </DragDropContext>
  );
};
export default DragAndDropArea;
