import { mapAccountDtoToModel } from 'src/mappers';

import { UserDto } from '../dtos/users.dtos';
import { User } from '../models/users.models';

export const mapUserDtoToModel = (item: UserDto): User => ({
  id: item.id,
  firstName: item.firstName,
  middleName: item.middleName,
  lastName: item.lastName,
  suffix: item.suffix,
  email: item.email,
  phoneNumber: item.phoneNumber,
  isLocked: item.isLocked,
  isClosed: item.isClosed,
  isApproved: item.isApproved,
  isMfaEnabled: item.isMfaEnabled,
  signUpStatus: item.signUpStatus,
  account: item.account ? mapAccountDtoToModel(item.account) : undefined,
  postCloseOffers: item.postCloseOffers,
  createdAt: item.createdAt,
});
