import { OfferDocumentUrlConstant } from 'src/constants';
import {
  OfferDocumentDto,
  CreateOfferDocumentDto,
  GetOfferDocumentDto,
  PatchOfferDocumentDto,
  DeleteOfferDocumentDto,
  GetOfferDocumentListDto,
  UploadOfferDocumentDto,
  CreateOfferDocumentUploadLinkDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class OfferDocumentsApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  private async createUploadLink({ body }: CreateOfferDocumentUploadLinkDto): Promise<string> {
    return httpRequest<string>({
      method: 'POST',
      url: OfferDocumentUrlConstant.CREATE_UPLOAD_LINK,
      body: { filename: body.filename, action: 'putObject' },
      authToken: this._configService.authToken,
    });
  }

  async create({ body }: CreateOfferDocumentDto): Promise<OfferDocumentDto> {
    return httpRequest<OfferDocumentDto>({
      method: 'POST',
      url: OfferDocumentUrlConstant.CREATE,
      body,
      authToken: this._configService.authToken,
    });
  }

  async list({ queryParams }: GetOfferDocumentListDto): Promise<OfferDocumentDto[]> {
    return httpRequest<OfferDocumentDto[]>({
      method: 'GET',
      url: OfferDocumentUrlConstant.LIST,
      queryParams,
      authToken: this._configService.authToken,
    });
  }

  async retrieve({ params }: GetOfferDocumentDto): Promise<OfferDocumentDto> {
    const url = replacePlaceholders(OfferDocumentUrlConstant.RETRIEVE, params);

    return httpRequest<OfferDocumentDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async patch({ params, body }: PatchOfferDocumentDto): Promise<OfferDocumentDto> {
    const url = replacePlaceholders(OfferDocumentUrlConstant.PATCH, params);

    return httpRequest<OfferDocumentDto>({
      method: 'PATCH',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }

  async delete({ params }: DeleteOfferDocumentDto): Promise<void> {
    const url = replacePlaceholders(OfferDocumentUrlConstant.DELETE, params);

    await httpRequest<OfferDocumentDto>({
      method: 'DELETE',
      url,
      authToken: this._configService.authToken,
    });
  }

  async upload({ body }: UploadOfferDocumentDto): Promise<string> {
    const { rawFile } = body;
    const preSignedUrl = await this.createUploadLink({ body });

    await httpRequest<void>({
      method: 'PUT',
      url: preSignedUrl,
      headers: { contentType: rawFile.type },
      body: rawFile,
    });

    const [protocol, , , domain] = preSignedUrl.split('/');

    return `${protocol}//${domain}/${body.filename}`;
  }
}
