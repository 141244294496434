import { GetListParams, GetListResult } from 'react-admin';
import { getClientApi } from 'src/data-communication';
import { LegacyUserDto } from 'src/dtos';

const clientApi = getClientApi();

export const getLegacyUserList = async (params: GetListParams): Promise<GetListResult<LegacyUserDto>> => {
  const { pagination } = params;
  const { data, total } = await clientApi.legacyUsers.list({
    queryParams: {
      skip: pagination.perPage && pagination.perPage * (pagination.page - 1),
      take: pagination.perPage,
    },
  });

  return {
    data,
    total,
  };
};
