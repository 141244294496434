import React from 'react';

import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Droppable } from 'react-beautiful-dnd';

import { DragDropElementTree } from '../ExportModal/models/export.models';
import DraggableElement from './DraggableElement';
import DraggableGroupElements from './DraggableElementGroup';

interface DroppableAreaProps {
  items: DragDropElementTree[] | undefined;
  droppableAreaId?: string;
  onChangeValue: (itemId: string) => void;
}

const useStyles = makeStyles({
  droppableGrid: {
    backgroundColor: (props: any) => {
      return props.isDraggingOver ? 'rgb(229 250 234)' : 'rgb(229 239 250)';
    },
    overflowY: 'unset',
  },
});

export const DroppableArea = ({ items, droppableAreaId = 'parent', onChangeValue }: DroppableAreaProps) => {
  const isParent = droppableAreaId === 'parent';

  const renderElements = () => {
    return items?.map((item, index) => {
      if (item.children?.length) {
        return (
          <DraggableGroupElements
            key={item.id}
            item={item}
            indexedElement={index}
            droppableParentAreaId={droppableAreaId}
            onChangeValue={onChangeValue}
          />
        );
      }

      return <DraggableElement key={item.id} item={item} indexedElement={index} onChangeValue={onChangeValue} />;
    });
  };

  return (
    <Droppable
      droppableId={droppableAreaId}
      type={isParent ? `droppableItem-${droppableAreaId}` : `droppableSubItem-${droppableAreaId}`}
      isCombineEnabled>
      {(provided, snapshot) => {
        const classes = useStyles({ isDraggingOver: snapshot.isDraggingOver });

        return (
          <Grid
            item
            container
            direction='column'
            justifyContent='space-between'
            ref={provided.innerRef}
            {...provided.droppableProps}
            className={classes.droppableGrid}>
            <Box
              component='div'
              sx={isParent ? { p: 2, border: '1px dashed grey', overflowY: 'auto', overflowX: 'hidden' } : {}}>
              {renderElements()}

              <Grid item xs={12}>
                {provided.placeholder}
              </Grid>
            </Box>
          </Grid>
        );
      }}
    </Droppable>
  );
};
export default DroppableArea;
