import * as React from 'react';

import { EmailField, Labeled, TextField } from 'react-admin';
import { Row, Col } from 'react-grid-system';

import { TrustedContactDto } from '../../../../dtos/accounts.dtos';
import { CountryField, StateField, FullNameField, PhoneField } from '../../../Fields';

export const TrustedContactShow = (record: TrustedContactDto) => {
  const { phones } = record;

  return (
    <div className='showContent'>
      <Row>
        <Col sm={12}>
          <h2 className='title'>Trusted Contact</h2>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Labeled label='Full Name'>
            <FullNameField record={record} />
          </Labeled>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled label='Email'>
            <EmailField source='email' record={record} />
          </Labeled>
        </Col>
        <Col md={6}>
          <>
            <div className={'fieldLabel'}>Phone</div>
            {phones.map((e: any) => (
              <PhoneField key={e.phoneNumber} source='phone' record={e} />
            ))}
          </>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled label='Street Address (Line 1)'>
            <TextField source='address1' record={record} />
          </Labeled>
        </Col>
        <Col md={6}>
          <Labeled label='Street Address (Line 2)'>
            <TextField source='address2' record={record} />
          </Labeled>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled label='Country'>
            <CountryField record={record} source='country' />
          </Labeled>
        </Col>
        <Col md={6}>
          <Labeled label='State'>
            <StateField record={record} source='state' />
          </Labeled>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Labeled label='City'>
            <TextField source='city' record={record} />
          </Labeled>
        </Col>
        <Col md={6}>
          <Labeled label='Postal Code'>
            <TextField source='postalCode' record={record} />
          </Labeled>
        </Col>
      </Row>
    </div>
  );
};
