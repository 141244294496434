import { every, isUndefined } from 'lodash';

export const areCheckInstructionsEmpty = (values: any): boolean => {
  if (!values) {
    return true;
  }

  const isPayableToUntouched = isUndefined(values.payableTo) || values.payableTo === '';
  const isBankNameUntouched = isUndefined(values.bankName) || values.bankName === '';
  const isAddressUntouched = isUndefined(values.address) || every(values.address, value => value === '');

  return isPayableToUntouched && isBankNameUntouched && isAddressUntouched;
};

export const formatPreviewImage = (value: any) => {
  if (!value || typeof value === 'string') {
    // Value is null or the url string from the backend, wrap it in an object so the form input can handle it
    return { src: value };
  } else {
    // Else a new image is selected which results in a value object already having a preview link under the url key
    return value;
  }
};
