import React from 'react';

import { DateField, Labeled, SelectField, Show, TextField } from 'react-admin';
import { Row, Col } from 'react-grid-system';

import ACHRelationship from '.';
import { approvalMethods } from '../../constants/choices/ach.relationships.choices';
import StatusField from '../Fields/StatusField';

const ACHRelationShow = () => {
  return (
    <>
      <Show>
        <div className='showContent'>
          <Row>
            <Col sm={12}>
              <h2 className='title'>ACH Relationship Details</h2>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='ID'>
                <TextField source='id' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Account Number'>
                <TextField source='accountNumber' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Account Name'>
                <TextField source='accountName' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Account Title'>
                <TextField source='accountTitle' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Approval Method'>
                <SelectField source='approvalMethod' choices={approvalMethods} />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Bank Name'>
                <TextField source='bankName' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Bank Account Owner'>
                <TextField source='bankAccountOwnerNameSecret' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Status'>
                <StatusField
                  source='status'
                  success={['Approved', 'FundsPosted', 'SentToBank', 'Complete']}
                  warning={[
                    'PendingRepCreateApproval',
                    'PendingFirmCreateApproval',
                    'Pending',
                    'FundsHeld',
                    'PendingPrinting',
                    'Postponed',
                    'Requested',
                  ]}
                  fail={[
                    'Unknown',
                    'Rejected',
                    'Canceled',
                    'Void',
                    'Returned',
                    'StopPayment',
                    'CreateRejected',
                    'FailedAtBank',
                  ]}
                />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Create Date'>
                <DateField source='date' />
              </Labeled>
            </Col>

            <Col md={6}>
              <ACHRelationship.Actions
                bodyStyle={{
                  justifyContent: 'flex-start',
                }}
              />
            </Col>
          </Row>
        </div>
      </Show>
    </>
  );
};
export default ACHRelationShow;
