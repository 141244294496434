import * as React from 'react';

import { Datagrid, List, SelectField, TextField, SelectInput, useStore } from 'react-admin';

import { offerOptions } from '../../constants/choices/offer.options.choices';

const defaultSort = { field: 'type', order: 'ASC' };
const offerFilters = [<SelectInput key='type' label='Type' source='type' choices={offerOptions} alwaysOn />];

const OfferOptionList = () => {
  const [, setOfferOptionsList] = useStore('offer-options.selected', null);

  const postRowClick = (record: any) => {
    setOfferOptionsList(record);

    return 'edit';
  };

  return (
    <List title='Offer Options' sort={defaultSort} filters={offerFilters}>
      <Datagrid rowClick={(id, resource, record) => postRowClick(record)} bulkActionButtons={false}>
        <TextField source='name' />
        <SelectField source='type' choices={offerOptions} />
      </Datagrid>
    </List>
  );
};

export default OfferOptionList;
