import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

import { OfferOrderList } from './OfferOrderList';
import { OfferOrderShow } from './OfferOrderShow';

const OfferOrders = {
  list: OfferOrderList,
  show: OfferOrderShow,
  icon: ShoppingBasketIcon,
};

export default OfferOrders;
