import { Grid } from '@mui/material';
import { isEmpty } from 'lodash';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  FunctionField,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from 'react-admin';
import { OfferDetailsDto } from 'src/dtos';

export const ExternalOfferShow = () => {
  const record = useRecordContext<OfferDetailsDto>();

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>External Details</h2>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <BooleanField label='Is External' source='isExternalOffer' fullWidth />
        </SimpleShowLayout>
      </Grid>
      {record.isExternalOffer && record.externalDetails?.disclaimer && (
        <Grid item xs={12} sm={12} md={12}>
          <SimpleShowLayout>
            <TextField label='Offer Disclaimer' source='externalDetails.disclaimer' fullWidth />
          </SimpleShowLayout>
        </Grid>
      )}
      {record.isExternalOffer && record.externalDetails?.completedOfferOrderDisclaimer && (
        <Grid item xs={12} sm={12} md={12}>
          <SimpleShowLayout>
            <TextField
              label='Completed Offer Order Disclaimer'
              source='externalDetails.completedOfferOrderDisclaimer'
              fullWidth
            />
          </SimpleShowLayout>
        </Grid>
      )}
      {record.isExternalOffer && (
        <Grid item xs={12} sm={12}>
          <SimpleShowLayout>
            {record.externalDetails?.transferAgentName && (
              <TextField label='Transfer Agent Name' source='externalDetails.transferAgentName' fullWidth />
            )}
            {record.externalDetails?.bankName && (
              <TextField label='Bank Name' source='externalDetails.bankName' fullWidth />
            )}
            {record.externalDetails?.finalCircularUrl && (
              <TextField label='Final Circular Url' source='externalDetails.finalCircularUrl' fullWidth />
            )}
          </SimpleShowLayout>
        </Grid>
      )}
      {record.externalDetails?.wireInstructions && (
        <>
          <Grid item xs={12} sm={12} md={12}>
            <SimpleShowLayout>
              <h2>Wire Instructions</h2>
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={12} md={6}>
            <SimpleShowLayout>
              <TextField source='externalDetails.wireInstructions.bankName' label='Bank Name' fullWidth />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={12} md={6}>
            <SimpleShowLayout>
              <TextField source='externalDetails.wireInstructions.abaNumber' label='ABA #' fullWidth />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={12} md={6}>
            <SimpleShowLayout>
              <TextField source='externalDetails.wireInstructions.accountNumber' label='A/C #' fullWidth />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={12} md={6}>
            <SimpleShowLayout>
              <TextField source='externalDetails.wireInstructions.accountName' label='A/C Name' fullWidth />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={12} md={6}>
            <SimpleShowLayout>
              <TextField source='externalDetails.wireInstructions.attentionLine' label='Attention Line' fullWidth />
            </SimpleShowLayout>
          </Grid>
        </>
      )}
      {record.externalDetails?.checkInstructions && (
        <>
          <Grid item xs={12} md={12}>
            <h2>Check Instructions</h2>
          </Grid>
          <Grid item xs={12} md={6}>
            <SimpleShowLayout>
              <TextField source='externalDetails.checkInstructions.payableTo' label='Payable To' fullWidth />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={12} md={6}>
            <SimpleShowLayout>
              <TextField source='externalDetails.checkInstructions.bankName' label='Bank Name' fullWidth />
            </SimpleShowLayout>
          </Grid>
          <Grid item xs={12} md={6}>
            <SimpleShowLayout>
              <TextField source='externalDetails.checkInstructions.address.address1' label='Address Line 1' fullWidth />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <TextField source='externalDetails.checkInstructions.address.address2' label='Address Line 2' fullWidth />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <TextField source='externalDetails.checkInstructions.address.city' label='City' fullWidth />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <TextField source='externalDetails.checkInstructions.address.state' label='State' fullWidth />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <TextField source='externalDetails.checkInstructions.address.postalCode' label='Postal Code' fullWidth />
            </SimpleShowLayout>
            <SimpleShowLayout>
              <TextField source='externalDetails.checkInstructions.address.country' label='Country' fullWidth />
            </SimpleShowLayout>
          </Grid>
        </>
      )}
      {record.isExternalOffer && !isEmpty(record.externalDetails?.securityDeliveryOptions) && (
        <Grid item xs={12} sm={12} md={12}>
          <SimpleShowLayout>
            <FunctionField
              label='Security Delivery Information Options'
              render={(record: OfferDetailsDto) => (
                <ArrayField source='externalDetails.securityDeliveryOptions' record={record}>
                  <Datagrid bulkActionButtons={false}>
                    <TextField source='label' label='Option Label' />
                    <BooleanField source='isReadOnly' label='Is Read Only' />
                    <BooleanField source='initialValue' label='Initial Value (Checked or Unchecked)' />
                    <BooleanField source='hasTooltip' label='Has Tooltip' />
                    <TextField source='tooltip' label='Tooltip text' />
                  </Datagrid>
                </ArrayField>
              )}
            />
          </SimpleShowLayout>
        </Grid>
      )}
    </Grid>
  );
};
