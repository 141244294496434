import { isEmpty } from 'lodash';
import { GetListParams, GetOneParams, GetOneResult, UpdateParams, UpdateResult } from 'react-admin';
import { getClientApi } from 'src/data-communication';
import { SearchAchRelationshipSortByQueryParamTypeDto, SortDirectionTypeDto } from 'src/dtos';
import {
  mapAchRelationshipDtoToModel,
  mapAchRelationshipDetailsDtoToModel,
  mapGetAchRelationshipListResponseDtoToModel,
  mapSearchAchRelationshipListDtoToModel,
} from 'src/mappers';
import { AchRelationship, AchRelationshipDetails } from 'src/models';
import { Maybe } from 'src/utils';

const clientApi = getClientApi();

export const mapGetListSortOrderToSortDirectionQueryParamTypeDto = (sort: any): SortDirectionTypeDto => {
  return sort?.order ? sort.order.toLowerCase() : SortDirectionTypeDto.Asc;
};

export const mapSearchAchRelationshipListFilterToFilterByQueryParamDto = (
  filter: any,
): Maybe<{ query?: string; status?: string }> => {
  let result: { query?: string; status?: string } = {};

  if (filter.query) {
    result.query = filter.query;
  }

  if (filter.status) {
    result.status = filter.status;
  }

  if (isEmpty(result)) {
    return;
  }

  return result;
};

export const searchAchRelationshipList = async (params: GetListParams) => {
  const { pagination, sort } = params;

  const { data, total } = await clientApi.achRelationships.search({
    queryParams: {
      skip: pagination.perPage && pagination.perPage * (pagination.page - 1),
      take: pagination.perPage,
      filterBy: mapSearchAchRelationshipListFilterToFilterByQueryParamDto(params.filter),
      sortDirection: mapGetListSortOrderToSortDirectionQueryParamTypeDto(sort),
      sortBy: sort.field as SearchAchRelationshipSortByQueryParamTypeDto,
    },
  });

  return {
    data: mapSearchAchRelationshipListDtoToModel(data),
    total,
  };
};

export const getAchRelationshipList = async (params: GetListParams) => {
  const { filter } = params;
  const accountId = filter.accountId;
  const { data, total } = await clientApi.achRelationships.list({
    params: {
      accountId,
    },
  });

  return {
    data: mapGetAchRelationshipListResponseDtoToModel(data),
    total,
  };
};

export const getAchRelationship = async (
  params: GetOneParams<AchRelationshipDetails>,
): Promise<GetOneResult<AchRelationshipDetails>> => {
  const { id } = params;
  const data = await clientApi.achRelationships.retrieve({
    params: {
      id: id.toString(),
    },
  });

  return {
    data: mapAchRelationshipDetailsDtoToModel(data),
  };
};

export const updateAchRelationship = async (params: UpdateParams): Promise<UpdateResult<AchRelationship>> => {
  const id = params.id;
  const { accountId, nickName, note } = params.data;

  const data = await clientApi.achRelationships.update({
    params: {
      accountId,
    },
    queryParams: {
      relationshipId: id.toString(),
    },
    body: {
      nickName,
      adminNote: note,
    },
  });

  return {
    data: mapAchRelationshipDtoToModel(data),
  };
};

export const cancelAchRelationship = async (params: UpdateParams): Promise<UpdateResult<AchRelationship>> => {
  const id = params.id;
  const { accountId, comment } = params.data;

  const data = await clientApi.achRelationships.cancel({
    params: {
      accountId,
    },
    queryParams: {
      relationshipId: id.toString(),
    },
    body: {
      comment,
    },
  });

  return {
    data: mapAchRelationshipDtoToModel(data),
  };
};

export const approveAchRelationship = async (params: any): Promise<UpdateResult<AchRelationship>> => {
  const id = params.id;
  const { accountId, note } = params.data;

  const data = await clientApi.achRelationships.approve({
    params: {
      accountId,
    },
    queryParams: {
      relationshipId: id.toString(),
    },
    body: {
      adminNote: note,
    },
  });

  return {
    data: mapAchRelationshipDtoToModel(data),
  };
};

export const rejectAchRelationship = async (params: any): Promise<UpdateResult<AchRelationship>> => {
  const id = params.id;
  const { accountId, note } = params.data;

  const data = await clientApi.achRelationships.reject({
    params: {
      accountId,
    },
    queryParams: {
      relationshipId: id.toString(),
    },
    body: {
      adminNote: note,
    },
  });

  return {
    data: mapAchRelationshipDtoToModel(data),
  };
};
