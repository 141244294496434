import { BaseListResponseDto, PaginationQueryParams, SortDirectionQueryParams } from './common.dtos';

export interface PrivateQuoteSnapDto {
  symbol: string;
  name?: string;
  lastPrice: number;
  closePrice: number;
  netChange: number;
}

export interface CreatePrivateQuoteBodyDto {
  symbol: string;
  name: string;
  purchasePrice: number;
  currentPrice: number;
}

export interface PrivateQuoteDto extends CreatePrivateQuoteBodyDto {
  id: string;
  createdAt: string;
}

export interface BasePrivateQuoteParamsDto {
  id: string;
}

export interface CreatePrivateQuoteDto {
  body: CreatePrivateQuoteBodyDto;
}

export enum GetPrivateQuoteListSortByQueryParamTypeDto {
  CreatedAt = 'createdAt',
}

export interface GetPrivateQuoteListFilterByQueryParamDto {
  symbol?: number;
  purchasePrice?: number;
  currentPrice?: number;
}

export interface GetPrivateQuoteListQueryParamsDto
  extends Partial<SortDirectionQueryParams>,
    Partial<PaginationQueryParams> {
  filterBy?: GetPrivateQuoteListFilterByQueryParamDto;
  sortBy?: GetPrivateQuoteListSortByQueryParamTypeDto;
}

export interface GetPrivateQuoteListDto {
  queryParams: GetPrivateQuoteListQueryParamsDto;
}

export interface GetPrivateQuoteListResponseDto extends BaseListResponseDto<PrivateQuoteDto[]> {}

export interface GetPrivateQuoteDto {
  params: BasePrivateQuoteParamsDto;
}

export interface PatchPrivateQuoteBodyDto extends Partial<CreatePrivateQuoteDto> {}

export interface PatchPrivateQuoteDto {
  params: BasePrivateQuoteParamsDto;
  body: PatchPrivateQuoteBodyDto;
}

export interface DeletePrivateQuoteDto {
  params: BasePrivateQuoteParamsDto;
}

export interface SnapPrivateQuoteListQueryParamsDto {
  symbols: string[];
}

export interface SnapPrivateQuoteListDto {
  queryParams: SnapPrivateQuoteListQueryParamsDto;
}

export interface SnapPrivateQuoteListResponseDto extends BaseListResponseDto<PrivateQuoteSnapDto[]> {}
