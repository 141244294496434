import { useEffect } from 'react';

import { Chip } from '@mui/material';
import {
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  NumberField,
  Pagination,
  TextField,
  useList,
  useRecordContext,
} from 'react-admin';
import { OfferOrderRefund, OfferOrderDetails } from 'src/models';
import { getRefundStatusColor } from 'src/utils';

import { usePagination } from '../../../hooks/usePagination';

export const OfferOrderRefundList = () => {
  const record = useRecordContext<OfferOrderDetails>();

  const { dataPage, setData, page, setPage, perPage, setPerPage } = usePagination<OfferOrderRefund>({});
  const listContext = useList({ data: dataPage });

  useEffect(() => {
    setData(record.refunds);
  }, [record.refunds]);

  return (
    <ListContextProvider
      value={{
        ...listContext,
        setPage,
        page,
      }}>
      <Datagrid bulkActionButtons={false}>
        <TextField label='Mechanism' source='mechanism' />
        <TextField label='Refund id' source='externalId' />
        <NumberField label='Amount' source='amount' options={{ style: 'currency', currency: 'USD' }} />
        <FunctionField
          label='Status'
          render={(record: OfferOrderRefund) => (
            <Chip size='small' label={record.status.label} color={getRefundStatusColor(record.status.value)} />
          )}
        />
        <FunctionField label='Reason' render={(record: OfferOrderRefund) => record.reason.label} />
        <TextField label='Description' source='description' />
        <TextField label='Failure reason' source='failureReason' />
        <TextField label='Bank name' source='bankName' />
        <TextField label='Admin note' source='adminNote' />
        <DateField label='Created at' source='createdAt' />
      </Datagrid>
      <Pagination
        perPage={perPage}
        total={record.refunds.length}
        rowsPerPageOptions={[5, 10]}
        page={page}
        setPerPage={setPerPage}
      />
    </ListContextProvider>
  );
};
