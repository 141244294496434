import { Labeled, TextField, SelectField } from 'react-admin';
import { Row, Col } from 'react-grid-system';

import '@react-pdf-viewer/core/lib/styles/index.css';

import { statementDelivery } from '../../../../constants/choices/disclosure.choices';
import { DisclosureDto } from '../../../../dtos/accounts.dtos';

export const DisclosureShow = (record: DisclosureDto) => {
  const { stakeholder, industryEmployed } = record;

  return (
    <>
      <div className='showContent'>
        <Row>
          <Col sm={12}>
            <h2 className='title'>Disclosures & Others</h2>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Labeled label='Statement Delivery'>
              <SelectField source='statementDelivery' record={record} choices={statementDelivery} />
            </Labeled>
          </Col>
        </Row>
        {(stakeholder || industryEmployed) && (
          <>
            {' '}
            <h3 className='subHeader2'>Individual Representations</h3>
            {industryEmployed && (
              <Row>
                <Col sm={12}>
                  <Labeled label='Industry Employed Firm Name'>
                    <TextField source='industryEmployed.firmName' record={record} />
                  </Labeled>
                </Col>
              </Row>
            )}
            {stakeholder && (
              <Row>
                <Col sm={12}>
                  <Labeled label='Stakeholder'>
                    <TextField source='stakeholder.tickerSymbol' record={record} />
                  </Labeled>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    </>
  );
};
