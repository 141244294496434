import * as React from 'react';

import { Grid } from '@mui/material';
import { FunctionField, Loading, NumberField, SimpleShowLayout, useGetList, useRecordContext } from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { OfferOrderStatusDto } from 'src/dtos';
import { OfferOrderAggregate } from 'src/models/offerOrders.models';

export const OrdersShow = () => {
  const record = useRecordContext();
  const { data: aggregateList, isLoading } = useGetList<OfferOrderAggregate>(
    DataProviderResource.OfferOrdersAggregate,
    {
      filter: {
        offerId: record.id,
        status: [
          OfferOrderStatusDto.PendingFunds,
          OfferOrderStatusDto.PendingOfferClose,
          OfferOrderStatusDto.PendingAction,
          OfferOrderStatusDto.Approved,
          OfferOrderStatusDto.Complete,
        ],
      },
    },
  );

  const offerAggregate = aggregateList?.[0] ?? { sumRaisedInvestment: 0 };

  if (isLoading) return <Loading />;

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>Orders Information</h2>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <NumberField
            record={offerAggregate}
            label='Total Raise Amount'
            source='sumRaisedInvestment'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <NumberField
            label='Maximum raise Amount'
            source='maxTotalRaiseCurrencyAmt'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <NumberField
            label='Minimum Investment Currency'
            source='minimumInvestCurrencyAmt'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleShowLayout>
          <FunctionField
            label='Total Oversubscribed Amount'
            record={record}
            render={(offer: any) => {
              const overSubscriptionValue = offerAggregate.sumRaisedInvestment - offer.maxTotalRaiseCurrencyAmt;

              return `${
                overSubscriptionValue > 0
                  ? `$${overSubscriptionValue.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : 'N/A'
              }`;
            }}
          />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};
export default OrdersShow;
