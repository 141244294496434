import { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import { Button } from 'react-admin';

import { UpdateOfferOrderListStatusModal } from './components/UpdateOfferOrderListStatusModal';

export interface OfferOrderListEditActionsProps {
  selectedIds?: string[];
}

export const OfferOrderListEditActions = ({ selectedIds }: OfferOrderListEditActionsProps) => {
  const [isUpdateStatusModalVisible, setIsUpdateStatusModalVisible] = useState<boolean>(false);

  return (
    <>
      <Button label='Status' startIcon={<EditIcon />} onClick={() => setIsUpdateStatusModalVisible(true)} />
      <UpdateOfferOrderListStatusModal
        selectedIds={selectedIds}
        isOpen={isUpdateStatusModalVisible}
        onClose={() => setIsUpdateStatusModalVisible(false)}
      />
    </>
  );
};
