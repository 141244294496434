import SyncIcon from '@mui/icons-material/Sync';
import { Button, useNotify, useRefresh, useUpdateMany } from 'react-admin';
import { DataProviderResource } from 'src/constants';

export interface SyncAcountsStatusButtonProps {
  label?: string;
  ids: string[];
}

export const SyncAccountsStatusButton = ({ ids, label = 'Accounts Status' }: SyncAcountsStatusButtonProps) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const [updateMany, { isLoading }] = useUpdateMany();

  const onSyncAccountsStatus = async () => {
    updateMany(
      DataProviderResource.AccountSyncStatusList,
      {
        ids,
      },
      {
        onSuccess: () => {
          notify('Account/s status synchronization request was successfully submitted.', { type: 'success' });
        },
        onSettled: () => {
          refresh();
        },
        onError: err => {
          notify(`Account/s status synchronization request error: ${err}`, {
            type: 'error',
          });
        },
      },
    );
  };

  return (
    <Button label={label} onClick={onSyncAccountsStatus} disabled={isLoading} style={{ marginTop: '8px' }}>
      <SyncIcon />
    </Button>
  );
};
