import * as React from 'react';

import { Grid } from '@mui/material';
import { BooleanField, SimpleShowLayout, TextField, WithRecord } from 'react-admin';

export const BankTransferConfigShow = () => (
  <Grid container direction='row' spacing={2}>
    <Grid item xs={12} md={6}>
      <SimpleShowLayout>
        <BooleanField
          label='Disable Inbound Bank Transfer in Funnel (To: My IPO / Deposit)'
          source='appConfig.bankTransfer.disabledInboundFunnel'
          fullWidth
        />
      </SimpleShowLayout>
    </Grid>
    <Grid item xs={12} md={6}>
      <WithRecord
        render={(record: any) =>
          record?.appConfig?.bankTransfer?.disabledInboundFunnel && (
            <SimpleShowLayout>
              <TextField
                label='Inbound Transfer Funnel Alert Text'
                source='appConfig.bankTransfer.disabledInboundDisclaimerFunnel'
                style={{ whiteSpace: 'pre-wrap' }}
                fullWidth
              />
            </SimpleShowLayout>
          )
        }
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SimpleShowLayout>
        <BooleanField
          label='Disable Outbound Bank Transfer in Funnel (From: My IPO / Withdrawal)'
          source='appConfig.bankTransfer.disabledOutboundFunnel'
          fullWidth
        />
      </SimpleShowLayout>
    </Grid>
    <Grid item xs={12} md={6}>
      <WithRecord
        render={(record: any) =>
          record?.appConfig?.bankTransfer?.disabledOutboundFunnel && (
            <SimpleShowLayout>
              <TextField
                label='Outbound Transfer Funnel Alert Text'
                source='appConfig.bankTransfer.disabledOutboundDisclaimerFunnel'
                style={{ whiteSpace: 'pre-wrap' }}
                fullWidth
              />
            </SimpleShowLayout>
          )
        }
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SimpleShowLayout>
        <BooleanField
          label='Disable Inbound Bank Transfer in Mobile (To: My IPO / Deposit)'
          source='appConfig.bankTransfer.disabledInboundMobile'
          fullWidth
        />
      </SimpleShowLayout>
    </Grid>
    <Grid item xs={12} md={6}>
      <WithRecord
        render={(record: any) =>
          record?.appConfig?.bankTransfer?.disabledInboundMobile && (
            <SimpleShowLayout>
              <TextField
                label='Inbound Transfer Mobile Alert Text'
                source='appConfig.bankTransfer.disabledInboundDisclaimerMobile'
                style={{ whiteSpace: 'pre-wrap' }}
                fullWidth
              />
            </SimpleShowLayout>
          )
        }
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SimpleShowLayout>
        <BooleanField
          label='Disable Outbound Bank Transfer in Mobile (To: My IPO / Deposit)'
          source='appConfig.bankTransfer.disabledOutboundMobile'
          fullWidth
        />
      </SimpleShowLayout>
    </Grid>
    <Grid item xs={12} md={6}>
      <WithRecord
        render={(record: any) =>
          record?.appConfig?.bankTransfer?.disabledOutboundMobile && (
            <SimpleShowLayout>
              <TextField
                label='Outbound Transfer Mobile Alert Text'
                source='appConfig.bankTransfer.disabledOutboundDisclaimerMobile'
                style={{ whiteSpace: 'pre-wrap' }}
                fullWidth
              />
            </SimpleShowLayout>
          )
        }
      />
    </Grid>
  </Grid>
);

export default BankTransferConfigShow;
