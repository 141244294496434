import Button from '@mui/material/Button';
import { withStyles } from '@mui/styles';

import Colors from '../../constants/colors';

export const PrimaryButton = withStyles(() => ({
  root: {
    color: Colors.WHITE,
    backgroundColor: Colors.MY_IPO_BLUE,
    '&:hover': {
      backgroundColor: Colors.LIGHTER_MY_IPO_BLUE,
    },
  },
}))(Button);
