import { RequestQuote } from '@mui/icons-material';
import { ResourceProps } from 'react-admin';

import { PrivateQuoteCreate } from './PrivateQuoteCreate';
import { PrivateQuoteEdit } from './PrivateQuoteEdit';
import { PrivateQuoteShow } from './PrivateQuoteShow';
import { PrivateQuotesList } from './PrivateQuotesList';

export const PrivateQuotes: ResourceProps = {
  name: 'private-quotes',
  icon: RequestQuote,
  create: PrivateQuoteCreate,
  list: PrivateQuotesList,
  edit: PrivateQuoteEdit,
  show: PrivateQuoteShow,
};
