export enum TradingBlockDocumentNameEnum {
  DriversLicense = 'DriversLicense',
  StateIdCard = 'StateIdCard',
  Passport = 'Passport',
  MilitaryId = 'MilitaryId',
  SsnCard = 'SsnCard',
  SsaLetter = 'SsaLetter',
  IrsItinLetter = 'IrsItinLetter',
  OtherGovernmentId = 'OtherGovernmentId',
  CddDocument = 'CddDocument',
  AllPassingCipResults = 'AllPassingCipResults',
  IrsW9Form = 'IrsW9Form',
  SignatureImage = 'SignatureImage',
  IdDocument = 'IdDocument',
  AffiliateApproval = 'AffiliateApproval',
  Document = 'Document',
  Other = 'Other',
  GovernmentId = 'GovernmentId',
}

export enum TradingBlockAccountInvestigationStateEnum {
  New = 'New',
  Pending = 'Pending',
  Indeterminate = 'Indeterminate',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Appealed = 'Appealed',
  Error = 'Error',
  Cancelled = 'Cancelled',
}

export enum TradingBlockInvestigationApplicationCategoryNameEnum {
  Signature = 'Signature',
  GovernmentId = 'GovermentId',
  AffiliateApproval = 'AffiliateApproval',
}

export enum TradingBlockInvestigationInvestigationCategoryNameEnum {
  Name = 'Name',
  DateOfBirth = 'DateOfBirth',
  TaxId = 'TaxId',
  Address = 'Address',
}

export enum TradingBlockUploadedDocumentStateEnum {
  PendingReview = 'PendingReview',
  Submitted = 'Submitted',
  Rejected = 'Rejected',
}

export enum TradingBlockAccountApplicationStateEnum {
  New = 'New',
  Pending = 'Pending',
  InvestigationSubmitted = 'InvestigationSubmitted',
  ReadyForBackOffice = 'ReadyForBackOffice',
  BackOffice = 'BackOffice',
  AccountSetup = 'AccountSetup',
  Complete = 'Complete',
  ActionRequired = 'ActionRequired',
  Rejected = 'Rejected',
  Suspended = 'Suspended',
  Error = 'Error',
  Cancelled = 'Cancelled',
  PendingSubmit = 'PendingSubmit',
}

export interface TradingBlockInvestigationInvestigationAdminNote {
  createdBy?: string;
  createdDate: string;
  id: string;
  text: string;
}

export interface TradingBlockInvestigationApplicationCipCategory {
  name: TradingBlockInvestigationApplicationCategoryNameEnum;
  isResolved: boolean;
  requestedDocuments?: Array<TradingBlockDocumentNameEnum>;
}

export interface TradingBlockInvestigationInvestigationCipCategory {
  name: TradingBlockInvestigationInvestigationCategoryNameEnum;
  isResolved: boolean;
  state: TradingBlockAccountInvestigationStateEnum;
  requestedDocuments?: Array<TradingBlockDocumentNameEnum>;
}

export interface TradingBlockInvestigationApplicationUploadedDocument {
  state: TradingBlockUploadedDocumentStateEnum;
  documentId: string;
  documentName: TradingBlockDocumentNameEnum;
  fileName: string;
  miscDocumentId: number;
  originalFileName: string;
  description?: string;
  takenOn?: string;
}

export interface TradingBlockInvestigationInvestigationUploadedDocument {
  state: TradingBlockUploadedDocumentStateEnum;
  documentId: string;
  documentName: TradingBlockDocumentNameEnum;
  fileName: string;
  miscDocumentId: number;
  originalFileName: string;
  description?: string;
  takenOn?: string;
  uploadDateTime?: string;
  uploadUserName?: string;
}

export interface TradingBlockInvestigationInvestigation {
  adminNote?: string;
  adminNotes?: TradingBlockInvestigationInvestigationAdminNote[];
  cipCategories?: Array<TradingBlockInvestigationInvestigationCipCategory>;
  firstName?: string;
  investigationId: number;
  lastName?: string;
  state: TradingBlockAccountInvestigationStateEnum;
  uploadedDocuments?: Array<TradingBlockInvestigationInvestigationUploadedDocument>;
}

export interface TradingBlockInvestigationApplication {
  accountHolderId: number;
  appCategories?: Array<TradingBlockInvestigationApplicationCipCategory>;
  firstName?: string;
  lastName?: string;
  state?: TradingBlockAccountInvestigationStateEnum;
  uploadedDocuments?: Array<TradingBlockInvestigationApplicationUploadedDocument>;
}

export interface BaseInvestigationParamsDto {
  accountId: number;
}

export interface GetInvestigationListDto {
  params: BaseInvestigationParamsDto;
}

export interface GetInvestigationListResponseDto {
  applications?: TradingBlockInvestigationApplication[];
  investigations?: TradingBlockInvestigationInvestigation[];
  state: TradingBlockAccountApplicationStateEnum;
}

export interface UploadInvestigationDocumentParamsDto extends BaseInvestigationParamsDto {
  investigationId: number;
}

export interface FileDto {
  path: string;
  bytes: number;
  filename: string;
  headers: Record<string, string>;
}

interface BaseUploadDocumentBodyDto {
  documentName: TradingBlockDocumentNameEnum;
  description?: string;
  imageName?: string;
  file: FileDto;
}

export interface UploadInvestigationDocumentDto {
  params: UploadInvestigationDocumentParamsDto;
  body: BaseUploadDocumentBodyDto;
}

export interface UploadInvestigationDocumentResponseDto {
  description: string;
  documentName: string;
  imageName: string;
  takenOn: string;
}

export interface DownloadInvestigationDocumentParamsDto {
  accountId: number;
  investigationId: number;
  documentId: string;
}

export interface DownloadInvestigationDocumentDto {
  params: DownloadInvestigationDocumentParamsDto;
}

export interface AppealInvestigationsParamsDto extends BaseInvestigationParamsDto {}

export interface AppealInvestigationDto {
  params: AppealInvestigationsParamsDto;
}

export interface RejectInvestigationsParamsDto extends BaseInvestigationParamsDto {}

export interface RejectInvestigationDto {
  params: RejectInvestigationsParamsDto;
}

export interface SubmitInvestigationDocumentParamsDto extends BaseInvestigationParamsDto {
  miscDocumentId: number;
}

export interface SubmitInvestigationDocumentDto {
  params: SubmitInvestigationDocumentParamsDto;
}

export interface DeleteInvestigationDocumentParamsDto extends BaseInvestigationParamsDto {
  miscDocumentId: number;
}

export interface DeleteInvestigationDocumentDto {
  params: DeleteInvestigationDocumentParamsDto;
}

export interface UploadApplicationDocumentParamsDto extends BaseInvestigationParamsDto {
  investigationId: number;
}

export interface UploadApplicationDocumentDto {
  params: UploadApplicationDocumentParamsDto;
  body: BaseUploadDocumentBodyDto;
}

export interface UploadApplicationDocumentResponseDto {
  description: string;
  documentName: string;
  imageName: string;
  takenOn: string;
}

export interface DownloadApplicationDocumentParamsDto {
  accountId: number;
  accountHolderId: string;
  documentId: string;
}

export interface DownloadApplicationDocumentDto {
  params: DownloadApplicationDocumentParamsDto;
}

export interface SubmitApplicationDocumentParamsDto extends BaseInvestigationParamsDto {
  miscDocumentId: number;
}

export interface SubmitApplicationDocumentDto {
  params: SubmitInvestigationDocumentParamsDto;
}

export interface ContinueApplicationDto {
  params: BaseInvestigationParamsDto;
}

export interface ContinueApplicationResponseDto {
  accountId: number;
  accountNUmber?: string;
  state: TradingBlockAccountApplicationStateEnum;
}

export interface DeleteApplicationDocumentParamsDto extends BaseInvestigationParamsDto {
  miscDocumentId: number;
}

export interface DeleteApplicationDocumentDto {
  params: DeleteApplicationDocumentParamsDto;
}
