import { Show, TabbedShowLayout, Tab, useShowController, Loading } from 'react-admin';

import OfferOrderDetailsShow from './components/OfferOrderDetails/OfferOrderDetailsShow';
import UserInfoDetailsShow from './components/UserInfoDetails/UserInfoDetailsShow';
import { OfferOrderActions } from './OfferOrderActions';

export const OfferOrderShow = () => {
  const { isLoading } = useShowController();

  if (isLoading) return <Loading />;

  return (
    <Show emptyWhileLoading title={'Offer Order'} actions={<OfferOrderActions />}>
      <TabbedShowLayout>
        <Tab label='Offer Order Info'>
          <OfferOrderDetailsShow />
        </Tab>
        <Tab label='User Info'>
          <UserInfoDetailsShow />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
