import { SnapQuoteDto } from '../dtos/quoteMedia.dto';
import { SnapQuote } from '../models/quoteMedia.model';

export const mapSnapQuoteDtoToModel = (dto: SnapQuoteDto): SnapQuote => ({
  id: dto.symbol,
  last: dto.pricedata.last,
  change: dto.pricedata.changepercent,
  description: dto.longname,
  symbol: dto.symbol,
});

export const mapGetSnapQuotesListResponseDtoToModel = (dtosList: SnapQuoteDto[]): SnapQuote[] =>
  dtosList.map(mapSnapQuoteDtoToModel);
