import React, { useState } from 'react';

import { CheckCircle, Cancel } from '@mui/icons-material';
import { useNotify, useRefresh, useUpdate } from 'react-admin';

import Colors from '../../../../constants/colors';
import Styles from '../../../../constants/styles';

const Actions = ({ bodyStyle = {}, accountIdentifier, accountHolderId, requestId, status }: any) => {
  const [btnDisabled, setBtnDisabled] = useState(false);

  const notify = useNotify();
  const refresh = useRefresh();
  const [update] = useUpdate();

  const approveHandle = () => {
    actionEntity('update-requests.APPROVE', requestId, { accountIdentifier, accountHolderId });
  };

  const rejectHandle = () => {
    actionEntity('update-requests.REJECT', requestId, { accountIdentifier, accountHolderId });
  };

  const actionEntity = (actionType: string, id: string, data: any) => {
    setBtnDisabled(true);

    update(
      actionType,
      { id, data },
      {
        onError: (error: any) => {
          notify(error.message ?? 'An uncaught error occurred!', { type: 'error' });
        },
        mutationMode: 'undoable',
        onSuccess: () => {
          getNotificationMessage(actionType);
          refresh();
        },
        onSettled: () => {
          setBtnDisabled(false);
        },
      },
    );
  };

  const getNotificationMessage = (type: string) => {
    switch (type) {
      case 'update-requests.APPROVE':
        return notify('Approved successfully', { type: 'success', undoable: true });
      case 'update-requests.REJECT':
        return notify('Rejected successfully', { type: 'success', undoable: true });

      default:
        break;
    }
  };

  const getItems = (state: string) => {
    if (state === 'PendingFirmReview') {
      return (
        <>
          <button
            disabled={btnDisabled}
            className='customButton'
            style={Styles.achRelationship.menuItem}
            onClick={() => approveHandle()}>
            <CheckCircle style={{ color: Colors.SUCCESS }} />
            <span style={{ color: Colors.SUCCESS_DARK, paddingLeft: 5 }}>APPROVE</span>
          </button>
          <button
            disabled={btnDisabled}
            className='customButton'
            style={Styles.achRelationship.menuItem}
            onClick={() => rejectHandle()}>
            <Cancel style={{ color: Colors.DANGER }} />
            <span style={{ color: Colors.DANGER_DARK, paddingLeft: 5 }}>REJECT</span>
          </button>
        </>
      );
    }

    return <></>;
  };

  if (status) {
    return (
      <>
        <div style={{ ...Styles.defaults.alignCenter, ...bodyStyle }}>{getItems(status)}</div>
      </>
    );
  } else return <></>;
};
export default Actions;
