import * as React from 'react';

import { Datagrid, DateField, List, ReferenceField, SelectField, TextField, UrlField } from 'react-admin';

import { offerDocumentsType } from '../../constants/choices/offer.documents.choices';

const defaultSort = { field: 'updatedAt', order: 'ASC' };

const OfferDocumentList = () => (
  <List title='Offer Documents' sort={defaultSort}>
    <Datagrid rowClick='edit' bulkActionButtons={false}>
      <ReferenceField source='offerId' reference='offers' sortBy='name' sortable={false} link={'show'}>
        <TextField label='Offer' source='name' fullWidth sortBy='name' />
      </ReferenceField>
      <SelectField source='type' choices={offerDocumentsType} />
      <UrlField label='URL' source='url' target='_blank' fullWidth />
      <DateField source='createdAt' label='Created At' showTime />
      <DateField source='updatedAt' label='Updated At' showTime />
    </Datagrid>
  </List>
);

export default OfferDocumentList;
