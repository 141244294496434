import * as React from 'react';

import { Grid } from '@mui/material';
import _ from 'lodash';
import { BooleanInput, FormDataConsumer, required, TextInput } from 'react-admin';

interface BankTransferCreateEditProps {
  isEditMode?: boolean;
}

export const BankTransferCreateEdit = ({ isEditMode = false }: BankTransferCreateEditProps) => (
  <Grid container direction='row' spacing={2}>
    <Grid item xs={12}>
      <h2>{isEditMode ? 'Edit' : 'Add'} Bank Transfer Configuration</h2>
    </Grid>
    <Grid item xs={12} md={6}>
      <BooleanInput
        label='Disable Inbound Bank Transfer in Funnel (To: My IPO / Deposit)'
        source='appConfig.bankTransfer.disabledInboundFunnel'
        validate={[required()]}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData?.appConfig?.bankTransfer?.disabledInboundFunnel && (
            <TextInput
              {...rest}
              source='appConfig.bankTransfer.disabledInboundDisclaimerFunnel'
              label='Inbound Transfer Funnel Alert Text'
              validate={value => (_.isEmpty(value) ? 'A disclaimer text needs to be provided' : null)}
              fullWidth
            />
          )
        }
      </FormDataConsumer>
    </Grid>
    <Grid item xs={12} md={6}>
      <BooleanInput
        label='Disable Outbound Bank Transfer in Funnel (From: My IPO / Withdrawal)'
        source='appConfig.bankTransfer.disabledOutboundFunnel'
        validate={[required()]}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData?.appConfig?.bankTransfer?.disabledOutboundFunnel && (
            <TextInput
              {...rest}
              source='appConfig.bankTransfer.disabledOutboundDisclaimerFunnel'
              label='Outbound Transfer Funnel Alert Text'
              validate={value => (_.isEmpty(value) ? 'A disclaimer text needs to be provided' : null)}
              fullWidth
            />
          )
        }
      </FormDataConsumer>
    </Grid>
    <Grid item xs={12} md={6}>
      <BooleanInput
        label='Disable Inbound Bank Transfer in Mobile (To: My IPO / Deposit)'
        source='appConfig.bankTransfer.disabledInboundMobile'
        validate={[required()]}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData?.appConfig?.bankTransfer?.disabledInboundMobile && (
            <TextInput
              {...rest}
              source='appConfig.bankTransfer.disabledInboundDisclaimerMobile'
              label='Inbound Transfer Mobile Alert Text'
              validate={value => (_.isEmpty(value) ? 'A disclaimer text needs to be provided' : null)}
              fullWidth
            />
          )
        }
      </FormDataConsumer>
    </Grid>
    <Grid item xs={12} md={6}>
      <BooleanInput
        label='Disable Outbound Bank Transfer in Mobile (From: My IPO / Withdrawal)'
        source='appConfig.bankTransfer.disabledOutboundMobile'
        validate={[required()]}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData?.appConfig?.bankTransfer?.disabledOutboundMobile && (
            <TextInput
              {...rest}
              source='appConfig.bankTransfer.disabledOutboundDisclaimerMobile'
              label='Outbound Transfer Mobile Alert Text'
              validate={value => (_.isEmpty(value) ? 'A disclaimer text needs to be provided' : null)}
              fullWidth
            />
          )
        }
      </FormDataConsumer>
    </Grid>
  </Grid>
);
export default BankTransferCreateEdit;
