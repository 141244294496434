import * as React from 'react';

import { Grid } from '@mui/material';
import { ArrayInput, FileInput, FileField, required, SelectInput, SimpleFormIterator } from 'react-admin';

import { offerDocumentsType } from '../../../../constants/choices/offer.documents.choices';

export const DocumentsCreate = () => (
  <Grid container direction='row' spacing={2}>
    <Grid item xs={12}>
      <h2>Add Documents Information</h2>
    </Grid>
    <Grid item xs={12}>
      <ArrayInput source='_documents_temp' label={false}>
        <SimpleFormIterator inline fullWidth>
          <SelectInput source='type' validate={required()} choices={offerDocumentsType} />
          <FileInput source='_files' label='Document' accept='application/pdf' validate={required()}>
            <FileField source='src' title='title' target='_blank' />
          </FileInput>
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
  </Grid>
);
export default DocumentsCreate;
