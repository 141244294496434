import * as React from 'react';

import { Grid } from '@mui/material';
import { Create, required, SelectInput, SimpleForm, TextInput } from 'react-admin';

import { offerOptions } from '../../constants/choices/offer.options.choices';

export const OfferOptionCreate = () => (
  <Create redirect='list'>
    <SimpleForm>
      <Grid container direction='row' justifyContent='space-between' spacing={2}>
        <Grid item xs={12}>
          <h2>Create offer options</h2>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectInput source='type' validate={[required()]} choices={offerOptions} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput source='name' validate={[required()]} fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export default OfferOptionCreate;
