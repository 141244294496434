import * as React from 'react';

import { Grid } from '@mui/material';
import { Datagrid, DeleteButton, EditButton, ReferenceManyField, SelectField, UrlField } from 'react-admin';

import { offerDocumentsType } from '../../../../constants/choices/offer.documents.choices';

export const DocumentsEdit = () => (
  <Grid container direction='row' spacing={2}>
    <Grid item xs={12}>
      <h2>Edit Documents Information</h2>
    </Grid>
    <Grid item xs={12}>
      <ReferenceManyField label='Documents' reference='offer-documents' target='offerId'>
        <Datagrid bulkActionButtons={false}>
          <SelectField source='type' choices={offerDocumentsType} fullWidth />
          <UrlField source='url' target='_blank' />
          <EditButton />
          <DeleteButton redirect={false} />
        </Datagrid>
      </ReferenceManyField>
    </Grid>
  </Grid>
);
export default DocumentsEdit;
