import { GetListParams, GetOneParams, GetOneResult } from 'react-admin';
import { getClientApi } from 'src/data-communication';
import { GetAllOfferWatchListSortByQueryParamDto, OfferWatchDto, SortDirectionTypeDto } from 'src/dtos';

const clientApi = getClientApi();

export const getOfferWatchList = async (params: GetListParams) => {
  const { pagination, sort } = params;
  const queryParams = {
    skip: pagination.perPage && pagination.perPage * (pagination.page - 1),
    take: pagination.perPage,
    sortBy: sort?.field as GetAllOfferWatchListSortByQueryParamDto,
    sortDirection: sort?.order.toLowerCase() as SortDirectionTypeDto,
    offerId: params?.filter?.offerId,
    email: params?.filter?.email,
    active: params?.filter?.active,
  };
  const { data, total } = await clientApi.offerWatches.listAll({
    queryParams,
  });

  return {
    data,
    total,
  };
};

export const getOfferWatch = async (params: GetOneParams<OfferWatchDto>): Promise<GetOneResult<OfferWatchDto>> => {
  const response = await clientApi.offerWatches.retrieve({ params });

  return {
    data: response,
  };
};
