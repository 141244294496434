import CableIcon from '@mui/icons-material/Cable';

import ActionModals from './actionModals';
import Actions from './actions';
import ActionsDropdown from './actionsDropdown';
import DecideRelationship from './DecideRelationship';
import List from './List';
import Show from './Show';

const ACHRelationship = {
  ActionsDropdown,
  Actions,

  DecideRelationship,
  ActionModals,

  Icon: CableIcon,
  List,
  Show,
};

export default ACHRelationship;
