import { useCallback, useMemo, useState } from 'react';

import { useDataProvider, useListContext } from 'react-admin';
import { DataProviderResource } from 'src/constants';

import { OfferDto } from '../../../dtos/offers.dtos';
import { UserDto } from '../../../dtos/users.dtos';
import { mapOfferOrderDtoToOfferOrderExportView } from '../../../mappers/offerOrders.mappers';
import { AccountBalance } from '../../../models/accountBalances.models';
import { OfferOrderExportView, OfferOrder } from '../../../models/offerOrders.models';

const useExportOfferOrderList = () => {
  const [isLoadingExport, setIsLoadingExport] = useState<boolean>(false);

  const dataProvider = useDataProvider();
  const { total, page, perPage, filterValues, sort } = useListContext();

  const getDataToExport = useCallback(
    async (pageNumber: number, pageSize: number): Promise<OfferOrderExportView[]> => {
      setIsLoadingExport(true);

      const getOfferOptionsIds = (offersData: OfferDto[]) => {
        const foreignKeyIds = offersData?.map((item: OfferDto) => [
          item['offerTypeId'],
          item['securityTypeId'],
          item['categoryId'],
          item['industryId'],
          item['statusId'],
        ]);
        const listOfIds: string[] = foreignKeyIds.reduce((acc, el) => acc.concat(el), []);

        return [...new Set(listOfIds)];
      };

      const { data: dataOfferOrders }: { data: OfferOrder[] } = await dataProvider.getList(
        DataProviderResource.OfferOrders,
        {
          pagination: { perPage: pageSize || perPage, page: pageNumber || page },
          filter: filterValues,
          sort,
        },
      );

      if (!dataOfferOrders) return [];

      const offerIds = dataOfferOrders.map(anOfferOrder => anOfferOrder.offerId);
      const userIds = dataOfferOrders.map(anOfferOrder => anOfferOrder.userId);
      const accountIds = dataOfferOrders.map(anOfferOrder => anOfferOrder.accountId);

      const result = await Promise.allSettled([
        dataProvider.getMany('offers', { ids: offerIds }),
        dataProvider.getMany('users', { ids: userIds, meta: { unmaskTaxId: true } }),
        dataProvider.getMany('balances', { ids: accountIds }),
      ]);

      const dataOffers: OfferDto[] = result[0].status === 'fulfilled' ? result[0].value.data : [];
      const dataUsers: UserDto[] = result[1].status === 'fulfilled' ? result[1]?.value?.data : [];
      const balances: AccountBalance[] = result[2].status === 'fulfilled' ? result[2].value.data : [];

      const offerOptionIds = getOfferOptionsIds(dataOffers || []);

      const { data: dataOfferOptions } =
        offerOptionIds && offerOptionIds.length
          ? await dataProvider.getMany('offer-options', { ids: offerOptionIds })
          : { data: [] };

      setIsLoadingExport(false);

      return mapOfferOrderDtoToOfferOrderExportView(dataOfferOrders, dataOffers, dataUsers, dataOfferOptions, balances);
    },
    [dataProvider, setIsLoadingExport, page, perPage, filterValues, sort],
  );

  return useMemo(
    () => ({
      total,
      isLoadingExport,
      getDataToExport,
    }),
    [total, isLoadingExport, getDataToExport],
  );
};

export default useExportOfferOrderList;
