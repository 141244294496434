import * as React from 'react';

import { Edit } from 'react-admin';

import SimpleCreateEditForm from './SimpleCreateEditForm';

export const OfferDocumentEdit = () => (
  <Edit redirect='list'>
    <SimpleCreateEditForm isEditMode />
  </Edit>
);

export default OfferDocumentEdit;
