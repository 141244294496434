import React from 'react';

import { Viewer, LoadError, SpecialZoomLevel, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import { WORKER_URL } from '../../constants/urls';

export interface PdfFileViewerProps {
  url: string;
}

export const PdfFileViewer = ({ url }: PdfFileViewerProps) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const renderError = (error: LoadError) => {
    return (
      <div
        style={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
        }}>
        <div
          style={{
            backgroundColor: '#e53e3e',
            borderRadius: '0.25rem',
            color: 'white',
            padding: '0.5rem',
          }}>
          <p>Error opening the Pdf file. Received error: {error.name}</p>
          <p>{JSON.stringify(error.message)}</p>
        </div>
      </div>
    );
  };

  return (
    <Worker workerUrl={WORKER_URL}>
      <Viewer
        renderError={renderError}
        fileUrl={url}
        plugins={[defaultLayoutPluginInstance]}
        defaultScale={SpecialZoomLevel.PageFit}
      />
    </Worker>
  );
};
