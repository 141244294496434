import _ from 'lodash';
import {
  CreateParams,
  CreateResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { getClientApi } from 'src/data-communication';
import { OfferOptionDto, OfferOptionTypeDto, PatchOfferOptionBodyDto } from 'src/dtos';
import { mapOfferOptionDtoToModel } from 'src/mappers/offerOptions.mappers';
import { OfferOption } from 'src/models';
import { paginate } from 'src/utils';

import { offerOptions } from '../constants/choices/offer.options.choices';

const clientApi = getClientApi();

export const listOfferOptions = async (params: GetListParams): Promise<GetListResult<OfferOption>> => {
  const { pagination, sort } = params;
  const queryParams = {
    skip: pagination.perPage && pagination.perPage * (pagination.page - 1),
    take: pagination.perPage,
    sortBy: sort?.field,
    sortDirection: sort?.order.toLowerCase(),
  };
  let listOfChoices = [...offerOptions];
  if (params?.filter?.type) listOfChoices = offerOptions.filter(({ id }: { id: string }) => params.filter.type === id);
  const offerOptionList = await Promise.all(
    listOfChoices.map(aType =>
      clientApi.offerOptions
        .list({ params: { type: aType.id as OfferOptionTypeDto } })
        .then(res => res.map(anItem => mapOfferOptionDtoToModel(anItem, aType.id as OfferOptionTypeDto))),
    ),
  );
  const list: OfferOption[] = offerOptionList.reduce((acc, el) => acc.concat(el), []);

  const sortDirection: 'asc' | 'desc' = queryParams?.sortDirection?.toLowerCase() as 'asc' | 'desc';
  const listOffersFiltered: OfferOption[] = _.orderBy(list, [queryParams?.sortBy || 'asc'], [sortDirection]);
  const paginatedOffers: OfferOption[] =
    params?.pagination?.perPage && params?.pagination?.page
      ? paginate(listOffersFiltered, params.pagination.perPage, params.pagination.page)
      : listOffersFiltered;

  return { data: paginatedOffers, total: listOffersFiltered.length };
};

export const getManyOfferOptions = async (params: GetManyParams): Promise<GetManyResult<OfferOption>> => {
  const offerOptionList = await Promise.all(
    offerOptions.map(aType =>
      clientApi.offerOptions
        .list({ params: { type: aType.id as OfferOptionTypeDto } })
        .then(res => res.map(anItem => mapOfferOptionDtoToModel(anItem, aType.id as OfferOptionTypeDto))),
    ),
  );
  const list: OfferOption[] = offerOptionList.reduce((acc, el) => acc.concat(el), []);
  const listOffersFiltered: OfferOption[] = list.filter(({ id }: { id: string }) => params.ids.includes(id));

  return { data: listOffersFiltered };
};

export const getOfferOption = async (params: GetOneParams<OfferOptionDto>): Promise<GetOneResult<OfferOption>> => {
  const response = await clientApi.offerOptions.retrieve({
    params: {
      id: params.id.toString(),
      type: params.meta.type,
    },
  });

  return {
    data: mapOfferOptionDtoToModel(response, params.meta.type),
  };
};

export const createOfferOption = async (params: CreateParams): Promise<CreateResult<OfferOption>> => {
  const { type, name } = params?.data;

  const response = await clientApi.offerOptions.create({
    params: { type },
    body: { name },
  });

  return {
    data: mapOfferOptionDtoToModel(response, type),
  };
};

export const patchOfferOption = async (
  params: UpdateParams<PatchOfferOptionBodyDto & { type: OfferOptionTypeDto }>,
): Promise<UpdateResult<OfferOption>> => {
  const { type, name } = params?.data;

  if (!type) {
    throw new Error('Unable to patch offer option. "type" parameter is required');
  }

  if (!name) {
    throw new Error('Unable to patch offer option. "name" is required');
  }

  const response = await clientApi.offerOptions.patch({
    params: { id: params.id.toString(), type },
    body: { name },
  });

  return {
    data: mapOfferOptionDtoToModel(response, type),
  };
};

export const deleteOfferOption = async (params: DeleteParams): Promise<DeleteResult<{ id: string }>> => {
  await clientApi.offerOptions.delete({
    params: { id: params.id, type: params?.previousData?.type },
  });

  return {
    data: { id: params.id.toString() },
  };
};
