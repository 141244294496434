import { useState } from 'react';

import FileDownloadIcon from '@mui/icons-material/GetApp';
import { Button, TopToolbar } from 'react-admin';

import ExportModal from '../../lib/ExportModal/ExportModal';
import { OfferOrderExportView } from '../../models/offerOrders.models';
import { modelFields } from './constants';
import useExportOfferOrderList from './hooks/useExportOfferOrderList';

const OfferOrdersListActions = () => {
  const [isExportDialogOpen, setIsExportDialogOpen] = useState<boolean>(false);

  const { total, isLoadingExport, getDataToExport } = useExportOfferOrderList();

  return (
    <TopToolbar>
      <Button label='Export' onClick={() => setIsExportDialogOpen(true)} disabled={isLoadingExport}>
        <FileDownloadIcon />
      </Button>
      <ExportModal<OfferOrderExportView>
        isOpen={isExportDialogOpen}
        onClose={() => setIsExportDialogOpen(false)}
        modelFields={modelFields}
        filename='Offer Orders - Export'
        total={total}
        isLoading={isLoadingExport}
        onExport={getDataToExport}
      />
    </TopToolbar>
  );
};

export default OfferOrdersListActions;
