import { useEffect, useState } from 'react';

import { FilePresent, FileOpen, Download } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { useDataProvider, useNotify } from 'react-admin';
import { Col } from 'react-grid-system';
import { DataProviderResource } from 'src/constants';
import { DownloadAccountAgrementResponse } from 'src/models';

export interface AccountAgreementProps {
  id: string;
  onView: (params: { url: string; type: 'pdf' }) => void;
}

export const AccountAgreement = ({ id, onView }: AccountAgreementProps) => {
  const [file, setFile] = useState<{ url: string; type: 'pdf' } | null>(null);
  const [isDownloadLoading, setIsDownloadLoading] = useState<boolean>(false);
  const [isViewLoading, setIsViewLoading] = useState<boolean>(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const openSaveFileDialog = (url: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.style.display = 'none';

    // Append the link to the document and trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Clean up by removing the link
    document.body.removeChild(downloadLink);
  };

  const downloadFile = async () => {
    return dataProvider.getOne<DownloadAccountAgrementResponse>(DataProviderResource.AccountAgreements, { id });
  };

  const onDownload = async () => {
    try {
      setIsDownloadLoading(true);

      if (!file) {
        const response = await downloadFile();
        setFile(response.data.file);
        openSaveFileDialog(response.data.file.url);

        return;
      }
      openSaveFileDialog(file.url);
    } catch (error) {
      notify(`Error downloading file. Received ${JSON.stringify(error)}`, {
        type: 'error',
      });
    } finally {
      setIsDownloadLoading(false);
    }
  };

  const _onView = async () => {
    try {
      setIsViewLoading(true);

      if (!file) {
        const response = await downloadFile();
        setFile(response.data.file);
        onView(response.data.file);

        return;
      }
      onView(file);
    } catch (error) {
      notify(`Error opening file. Received ${JSON.stringify(error)}`, {
        type: 'error',
      });
    } finally {
      setIsViewLoading(false);
    }
  };

  useEffect(() => {
    if (file) {
      URL.revokeObjectURL(file.url);
    }
  }, []);

  return (
    <>
      <Col md={1} className={'alignAllCenter'}>
        <FilePresent />
      </Col>

      <Col md={8} className={'alignVerticalCenter'}>
        <div>
          <span>Account Agreement</span>
          <br />
        </div>
      </Col>

      <Col md={2}>
        <IconButton onClick={onDownload} disabled={isDownloadLoading}>
          {isDownloadLoading ? <CircularProgress color='inherit' size='1em' /> : <Download />}
        </IconButton>
        <IconButton onClick={_onView} disabled={isViewLoading}>
          {isViewLoading ? <CircularProgress color='inherit' size='1em' /> : <FileOpen />}
        </IconButton>
      </Col>
    </>
  );
};
