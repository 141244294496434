import {
  CreateParams,
  DataProvider,
  DeleteParams,
  GetListParams,
  GetOneParams,
  GetManyReferenceParams,
  UpdateManyParams,
} from 'react-admin';

import { DataProviderResource } from '../constants/common.constants';
import { DataProviderResourceNotFoundError } from '../errors';
import { getAccountBalance, getAccountBalanceList } from './accountBalances.providers';
import {
  listAccountHolderUpdateRequests,
  approveAccountHolderUpdateRequest,
  rejectAccountHolderUpdateRequest,
} from './accountHolders.providers';
import { downloadAccountAgreement, syncAccountStatusList } from './accounts.providers';
import {
  searchAchRelationshipList,
  getAchRelationshipList,
  getAchRelationship,
  approveAchRelationship,
  cancelAchRelationship,
  rejectAchRelationship,
  updateAchRelationship,
} from './achRelationships.providers';
import { createConfig, getConfigList, getConfig, updateConfig, deleteConfig } from './configs.providers';
import {
  getInvestigationList,
  submitInvestigationDocument,
  downloadInvestigationDocument,
  appealInvestigation,
  rejectInvestigation,
  deleteInvestigationDocument,
  submitApplicationDocument,
  downloadApplicationDocument,
  deleteApplicationDocument,
  continueApplication,
} from './investigations.providers';
import { getLegacyUserList } from './legacyUsers.providers';
import {
  createOfferDocument,
  listOfferDocuments,
  getManyReferenceOfferDocuments,
  getOfferDocument,
  patchOfferDocument,
  deleteOfferDocument,
} from './offerDocuments.providers';
import {
  createOfferOption,
  listOfferOptions,
  getManyOfferOptions,
  getOfferOption,
  patchOfferOption,
  deleteOfferOption,
} from './offerOptions.providers';
import {
  getOfferOrderDetails,
  getOfferOrderAggregateList,
  getOfferOrderList,
  updateOfferOrder,
  updateOfferOrderListStatus,
  deleteOfferOrder,
  createOfferOrderRefund,
  getOfferOrderCardPaymentList,
  getOfferOrderCardPaymentDetails,
} from './offerOrders.providers';
import {
  createOffer,
  getOfferList,
  getManyOffers,
  getOffer,
  patchOffer,
  completeOffer,
  newOfferCardPaymentAccountLink,
} from './offers.providers';
import { getOfferWatchList, getOfferWatch } from './offerWatches.providers';
import { getOrderList, getPositionList } from './orders.providers';
import {
  createPrivateQuote,
  getPrivateQuoteList,
  snapPrivateQuoteList,
  getPrivateQuote,
  deletePrivateQuote,
  patchPrivateQuote,
} from './privateQuotes.providers';
import {
  searchTransferList,
  getTransferList,
  getTransfer,
  firmApproveTransfer,
  repApproveTransfer,
  rejectTransfer,
  cancelTransfer,
  updateTransfer,
} from './transfers.providers';
import {
  approveUser,
  closeUser,
  disableUserMfa,
  enableUserMfa,
  getUser,
  getUserList,
  getManyUsers,
  userPostOfferCloseInvest,
  lockUser,
  unlockUser,
} from './users.providers';

const createProvider = async (resource: string, params: CreateParams) => {
  switch (resource) {
    case 'offers':
      return createOffer(params);
    case 'offer-options':
      return createOfferOption(params);
    case 'offer-documents':
      return createOfferDocument(params);
    case 'configs':
      return createConfig(params);
    case 'private-quotes':
      return createPrivateQuote(params);
    case DataProviderResource.OfferOrderRefunds:
      return createOfferOrderRefund(params);
    default:
      throw new DataProviderResourceNotFoundError(resource);
  }
};

const getOneProvider = async (resource: string, params: GetOneParams) => {
  switch (resource) {
    case 'users':
      return getUser(params);
    case 'achrelationships':
      return getAchRelationship(params);
    case DataProviderResource.AccountAgreements:
      return downloadAccountAgreement(params);
    case 'achtransfers':
      return getTransfer(params);
    case 'offers':
      return getOffer(params);
    case 'offer-options':
      return getOfferOption(params);
    case 'offer-documents':
      return getOfferDocument(params);
    case DataProviderResource.OfferOrders:
      return getOfferOrderDetails(params);
    case DataProviderResource.OfferOrderCardPayments:
      return getOfferOrderCardPaymentDetails(params);
    case 'indication-interests':
      return getOfferWatch(params);
    case 'configs':
      return getConfig(params);
    case 'balances':
      return getAccountBalance(params);
    case 'private-quotes':
      return getPrivateQuote(params);
    case 'application-document':
      return downloadApplicationDocument(params);
    case 'investigation-document':
      return downloadInvestigationDocument(params);
    default:
      throw new DataProviderResourceNotFoundError(resource);
  }
};

const getListProvider = async (resource: string, params: GetListParams) => {
  switch (resource) {
    case 'users':
      return getUserList(params);
    case 'preexistingusers':
      return getLegacyUserList(params);
    case 'achrelationships':
      return searchAchRelationshipList(params);
    case 'account-achrelationships':
      return getAchRelationshipList(params);
    case 'achtransfers':
      return searchTransferList(params);
    case DataProviderResource.Transfers:
      return getTransferList(params);
    case 'offers':
      return getOfferList(params);
    case 'offer-options':
      return listOfferOptions(params);
    case 'offer-documents':
      return listOfferDocuments(params);
    case DataProviderResource.OfferOrders:
      return getOfferOrderList(params);
    case 'indication-interests':
      return getOfferWatchList(params);
    case 'update-requests':
      return listAccountHolderUpdateRequests(params);
    case 'account-investigations':
      return getInvestigationList(params);
    case 'configs':
      return getConfigList(params);
    case DataProviderResource.OfferOrdersAggregate:
      return getOfferOrderAggregateList(params);
    case DataProviderResource.OfferOrderCardPayments:
      return getOfferOrderCardPaymentList(params);
    case DataProviderResource.Orders:
      return getOrderList(params);
    case 'orders.positions':
      return getPositionList(params);
    case 'private-quotes':
      return getPrivateQuoteList(params);
    default:
      throw new DataProviderResourceNotFoundError(resource);
  }
};

const getManyProvider = async (resource: string, params: any) => {
  switch (resource) {
    case 'users':
      return getManyUsers(params);
    case 'offers':
      return getManyOffers(params);
    case 'offer-options':
      return getManyOfferOptions(params);
    case 'offer-documents':
      return listOfferDocuments(params);
    case 'indication-interests':
      return getOfferWatchList(params);
    case 'achtransfers':
      return getTransfer(params);
    case 'configs':
      return getConfigList(params);
    case 'private-quotes':
      return snapPrivateQuoteList(params);
    case 'balances':
      return getAccountBalanceList(params);
    default:
      throw new DataProviderResourceNotFoundError(resource);
  }
};

const getManyReferenceProvider = async (resource: string, params: GetManyReferenceParams) => {
  switch (resource) {
    case 'offer-documents':
      return getManyReferenceOfferDocuments(params);
    default:
      throw new DataProviderResourceNotFoundError(resource);
  }
};

const updateProvider = async (resource: string, params: any) => {
  switch (resource) {
    case 'ACHRelationship.EDIT':
      return updateAchRelationship(params);
    case 'ACHRelationship.CANCEL':
      return cancelAchRelationship(params);
    case 'ACHRelationship.APPROVE':
      return approveAchRelationship(params);
    case 'ACHRelationship.REJECT':
      return rejectAchRelationship(params);
    case 'ACHTransfer.EDIT':
      return updateTransfer(params);
    case 'ACHTransfer.CANCEL':
      return cancelTransfer(params);
    case 'ACHTransfer.REP_APPROVE':
      return repApproveTransfer(params);
    case 'ACHTransfer.FIRM_APPROVE':
      return firmApproveTransfer(params);
    case 'ACHTransfer.REJECT':
      return rejectTransfer(params);
    case 'offers':
      return patchOffer(params);
    case 'offers.offer-orders':
      return completeOffer(params);
    case 'offer-options':
      return patchOfferOption(params);
    case 'offer-documents':
      return patchOfferDocument(params);
    case DataProviderResource.OfferOrders:
      return updateOfferOrder(params);
    case DataProviderResource.NewOfferCardPaymentAccountLink:
      return newOfferCardPaymentAccountLink(params);
    case 'users.lockUser':
      return lockUser(params);
    case 'users.unlockUser':
      return unlockUser(params);
    case 'users.closeUser':
      return closeUser(params);
    case 'users.approveUser':
      return approveUser(params);
    case 'users.investPostClosingOffers':
      return userPostOfferCloseInvest(params);
    case 'users.enableMfa':
      return enableUserMfa(params);
    case 'users.disableMfa':
      return disableUserMfa(params);
    case 'update-requests.APPROVE':
      return approveAccountHolderUpdateRequest(params);
    case 'update-requests.REJECT':
      return rejectAccountHolderUpdateRequest(params);
    case 'investigation-document':
      return submitInvestigationDocument(params);
    case 'investigation.APPEAL':
      return appealInvestigation(params);
    case 'investigation.REJECT':
      return rejectInvestigation(params);
    case 'application-document':
      return submitApplicationDocument(params);
    case 'application.CONTINUE':
      return continueApplication(params);
    case 'configs':
      return updateConfig(params);
    case 'private-quotes':
      return patchPrivateQuote(params);
    default:
      throw new DataProviderResourceNotFoundError(resource);
  }
};

const updateManyProvider = async (resource: string, params: UpdateManyParams) => {
  switch (resource) {
    case DataProviderResource.OfferOrdersStatus:
      return updateOfferOrderListStatus(params);
    case DataProviderResource.AccountSyncStatusList:
      return syncAccountStatusList(params);
    default:
  }
};

const deleteProvider = async (resource: string, params: DeleteParams) => {
  switch (resource) {
    case 'offer-options':
      return deleteOfferOption(params);
    case 'offer-documents':
      return deleteOfferDocument(params);
    case 'investigation-document':
      return deleteInvestigationDocument(params);
    case 'application-document':
      return deleteApplicationDocument(params);
    case 'configs':
      return deleteConfig(params);
    case 'private-quotes':
      return deletePrivateQuote(params);
    case DataProviderResource.OfferOrders:
      return deleteOfferOrder(params);
    default:
      throw new DataProviderResourceNotFoundError(resource);
  }
};

const dataProvider = {
  create: createProvider,
  getOne: getOneProvider,
  getList: getListProvider,
  getMany: getManyProvider,
  getManyReference: getManyReferenceProvider,
  update: updateProvider,
  updateMany: updateManyProvider,
  delete: deleteProvider,
} as DataProvider;

export default dataProvider;
