import { CardActions } from '@mui/material';
import { ListButton, Loading, Show, ShowController, Tab, TabbedShowLayout, TabbedShowLayoutTabs } from 'react-admin';
import { User } from 'src/models';

import UserPage from './';

interface TitleProps {
  name: string;
  username: string;
  email: string;
}

const Title = ({ name, username, email }: TitleProps) => (
  <span>
    User:{' '}
    <strong>
      {name} {username}
    </strong>{' '}
    ({email})
  </span>
);

const Actions = ({ recordData }: { recordData: User }) => {
  return (
    <CardActions>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'flex-start',
        }}>
        <ListButton />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <UserPage.Actions element={recordData} />
      </div>
    </CardActions>
  );
};

export const UserShow = (props: User) => (
  <ShowController {...props}>
    {controllerProps => {
      if (controllerProps.isLoading || !controllerProps.record.account) {
        return <Loading />;
      } else
        return (
          <Show
            {...props}
            {...controllerProps}
            title={<Title {...controllerProps.record} />}
            actions={<Actions recordData={controllerProps?.record} />}>
            <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant='scrollable' />}>
              <Tab label='Personal' sx={{ overflow: 'auto' }}>
                <UserPage.PersonalInformation {...controllerProps.record} />
              </Tab>
              {controllerProps?.record?.account && (
                <Tab label='Account'>
                  <UserPage.Account {...controllerProps.record.account} />
                </Tab>
              )}

              {controllerProps?.record?.account?.accountId && (
                <Tab label='Investigations'>
                  <UserPage.Investigation {...controllerProps.record.account} />
                </Tab>
              )}
              {controllerProps?.record?.account?.primaryAccountHolder?.physicalAddress && (
                <Tab label='Physical Address'>
                  <UserPage.PhysicalAddress {...controllerProps.record.account.primaryAccountHolder.physicalAddress} />
                </Tab>
              )}
              {controllerProps?.record?.account?.primaryAccountHolder?.mailingAddress && (
                <Tab label='Mailing Address'>
                  <UserPage.MailingAddress {...controllerProps.record.account.primaryAccountHolder.mailingAddress} />
                </Tab>
              )}
              {controllerProps?.record?.account?.primaryAccountHolder?.trustedContact && (
                <Tab label='Trusted Contact'>
                  <UserPage.TrustedContact {...controllerProps.record.account.primaryAccountHolder.trustedContact} />
                </Tab>
              )}
              {controllerProps?.record?.account?.primaryAccountHolder?.suitabilityInformation && (
                <Tab label='Suitability'>
                  <UserPage.SuitabilityInformation
                    {...controllerProps.record.account.primaryAccountHolder.suitabilityInformation}
                  />
                </Tab>
              )}
              {controllerProps?.record?.account?.primaryAccountHolder?.financialInformation && (
                <Tab label='Financial'>
                  <UserPage.FinancialInformation
                    {...controllerProps.record.account.primaryAccountHolder.financialInformation}
                  />
                </Tab>
              )}
              {controllerProps?.record?.account?.primaryAccountHolder?.disclosure && (
                <Tab label='Disclosures'>
                  <UserPage.Disclosure
                    {...controllerProps.record.account.primaryAccountHolder.disclosure}
                    userId={controllerProps.record.id}
                  />
                </Tab>
              )}
              {controllerProps?.record?.account?.accountId && (
                <Tab label='Summary'>
                  <UserPage.Summary accountId={controllerProps.record.account.accountId} />
                </Tab>
              )}
              {controllerProps?.record?.account?.accountId && (
                <Tab label='History'>
                  <UserPage.History accountId={controllerProps?.record?.account?.accountId} />
                </Tab>
              )}
              {controllerProps?.record?.account?.accountId && (
                <Tab label='Update Requests'>
                  <UserPage.AccountUpdateRequests record={controllerProps.record.account} />
                </Tab>
              )}
            </TabbedShowLayout>
          </Show>
        );
    }}
  </ShowController>
);
