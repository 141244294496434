import { Edit } from 'react-admin';

import { PrivateQuoteCreateEditForm } from './PrivateQuoteCreateEditForm';

export const PrivateQuoteEdit = () => {
  const transformToPatchDto = (data: any) => ({
    ...data,
    id: undefined,
    createdAt: undefined,
  });

  return (
    <Edit redirect='list' transform={transformToPatchDto}>
      <PrivateQuoteCreateEditForm isEditMode />
    </Edit>
  );
};
