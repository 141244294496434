import { InvestigationConstant } from '../constants/investigations.constants';

export const isImageFile = (filename?: string) => {
  if (!filename) {
    return false;
  }
  const parts = filename.split('.');
  const lastPart = parts[parts.length - 1];

  return InvestigationConstant.IMAGE_FILE_EXT_LIST.includes(lastPart?.toLowerCase());
};
