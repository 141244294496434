export enum OfferOptionTypeDto {
  STATUS = 'offerStatus',
  OFFER_TYPE = 'offerType',
  INDUSTRY = 'offerIndustry',
  CATEGORY = 'offerCategory',
  SECURITY_TYPE = 'offerSecurityType',
}

export interface OfferOptionDto {
  id: string;
  name: string;
}

export interface BaseOfferOptionParamsDto {
  type: OfferOptionTypeDto;
}

export interface CreateOfferOptionBodyDto {
  name: string;
}

export interface CreateOfferOptionDto {
  params: BaseOfferOptionParamsDto;
  body: CreateOfferOptionBodyDto;
}

export interface GetOfferOptionListDto {
  params: BaseOfferOptionParamsDto;
}

export interface GetOfferOptionParamsDto extends BaseOfferOptionParamsDto {
  id: string;
}

export interface GetOfferOptionDto {
  params: GetOfferOptionParamsDto;
}

export interface PatchOferOptionParamsDto extends GetOfferOptionParamsDto {}

export interface PatchOfferOptionBodyDto extends CreateOfferOptionBodyDto {}

export interface PatchOfferOptionDto {
  params: PatchOferOptionParamsDto;
  body: PatchOfferOptionBodyDto;
}

export interface DeleteOfferOptionParamsDto extends GetOfferOptionParamsDto {}

export interface DeleteOfferOptionDto {
  params: DeleteOfferOptionParamsDto;
}
