import * as React from 'react';

import _ from 'lodash';
import { useRecordContext } from 'react-admin';

//props are not necessary thus props interface can vary
export const FullNameField = (props: any = {}) => {
  const record = useRecordContext(props);
  if (_.isEmpty(record.middleName))
    return (
      <span className='fullNameLabel'>
        {record.firstName} {record.lastName}
      </span>
    );

  return (
    <span className='fullNameLabel'>
      {record.firstName} {record.middleName} {record.lastName}
    </span>
  );
};
export default FullNameField;
