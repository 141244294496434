import * as React from 'react';

import { Grid } from '@mui/material';
import { BooleanField, SimpleShowLayout, TextField, WithRecord } from 'react-admin';

export const BankLinkConfigShow = () => (
  <Grid container direction='row' spacing={2}>
    <Grid item xs={12} md={6}>
      <SimpleShowLayout>
        <BooleanField label='Disable Bank Linkage in Funnel' source='appConfig.bankLink.disableInFunnel' fullWidth />
      </SimpleShowLayout>
    </Grid>
    <Grid item xs={12} md={6}>
      <WithRecord
        render={(record: any) =>
          record?.appConfig?.bankLink?.disableInFunnel && (
            <SimpleShowLayout>
              <TextField
                label='Funnel Alert Text'
                source='appConfig.bankLink.funnelDisclaimer'
                style={{ whiteSpace: 'pre-wrap' }}
                fullWidth
              />
            </SimpleShowLayout>
          )
        }
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <SimpleShowLayout>
        <BooleanField label='Disable Bank Linkage in Mobile' source='appConfig.bankLink.disableInMobile' fullWidth />
      </SimpleShowLayout>
    </Grid>
    <Grid item xs={12} md={6}>
      <WithRecord
        render={(record: any) =>
          record?.appConfig?.bankLink?.disableInMobile && (
            <SimpleShowLayout>
              <TextField
                label='Mobile Alert Text'
                source='appConfig.bankLink.mobileDisclaimer'
                style={{ whiteSpace: 'pre-wrap' }}
                fullWidth
              />
            </SimpleShowLayout>
          )
        }
      />
    </Grid>
  </Grid>
);

export default BankLinkConfigShow;
