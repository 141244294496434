import React, { useState } from 'react';

import { AccountBalance, MoreVert } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { useGetList, Button } from 'react-admin';
import { Col, Container, Row } from 'react-grid-system';
import { AchRelationship } from 'src/models';

import Styles from '../../../../constants/styles';
import ACHRelationship from '../../../ACHRelationships';
import StatusField from '../../../Fields/StatusField';

const sort = { field: 'id', order: 'DESC' };

interface UserAchRelationsListProps {
  accountId?: number;
}

export const UserAchRelationsList = ({ accountId }: UserAchRelationsListProps) => {
  const [curElement, setCurElement] = useState<AchRelationship>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>, element: AchRelationship) => {
    setAnchorEl(event.currentTarget);
    setCurElement(element);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data, isLoading, error } = useGetList('account-achrelationships', {
    filter: { accountId },
    sort,
  });
  if (isLoading)
    return (
      <div className='alignAllCenter'>
        <CircularProgress />
      </div>
    );
  if (error) return <div>An error occurred while loading this table !</div>;

  return (
    <div>
      <Container fluid>
        {data?.map(element => (
          <div key={element.id}>
            <Row className='achRelationship'>
              <Col md={1} className={'alignAllCenter'}>
                <AccountBalance />
              </Col>
              <Col md={4} className={'alignVerticalCenter'}>
                <div>
                  <span>{element.nickName}</span>
                  <br />
                  <span className='description'>
                    {element.bankName} ({element.bankAccountType})
                  </span>
                  <br />
                </div>
              </Col>
              <Col md={2} className={'alignVerticalCenter'}>
                <div>
                  <span className='fieldLabel'>Bank Account</span>
                  <br />
                  <span>{element.bankAccount}</span>
                </div>
              </Col>
              <Col md={2} className={'alignVerticalCenter'}>
                <div>
                  <span className='fieldLabel'>Bank Account Owner</span>
                  <br />
                  <span>{element.bankAccountOwnerName}</span>
                </div>
              </Col>
              <Col md={3} className={'alignAllCenter'}>
                <StatusField
                  status={element.state}
                  success={['Approved', 'FundsPosted', 'SentToBank', 'Complete']}
                  warning={[
                    'PendingRepCreateApproval',
                    'PendingFirmCreateApproval',
                    'Pending',
                    'FundsHeld',
                    'PendingPrinting',
                    'Postponed',
                    'Requested',
                  ]}
                  fail={[
                    'Unknown',
                    'Rejected',
                    'Canceled',
                    'Cancelled',
                    'Void',
                    'Returned',
                    'StopPayment',
                    'CreateRejected',
                    'FailedAtBank',
                  ]}
                />
              </Col>

              <Button
                aria-haspopup='listbox'
                aria-controls='lock-menu'
                onClick={e => {
                  handleClickListItem(e, element);
                }}
                style={Styles.achRelationship.moreButton}>
                <MoreVert fontSize='small' />
              </Button>
            </Row>
          </div>
        ))}
      </Container>
      {curElement && (
        <ACHRelationship.ActionsDropdown element={curElement} onClose={handleClose} anchorElement={anchorEl} />
      )}
    </div>
  );
};
