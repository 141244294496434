import { BaseListResponseDto, PaginationQueryParams, SortDirectionQueryParams } from './common.dtos';

export enum ConfigTypeDto {
  BANK_LINK = 'BANK_LINK',
  BANK_TRANSFER = 'BANK_TRANSFER',
}

export interface ConfigBankLinkDto {
  disableInFunnel: boolean;
  funnelDisclaimer?: string | null;
  disableInMobile: boolean;
  mobileDisclaimer?: string | null;
}

export interface ConfigBankTransferDto {
  disabledInboundFunnel: boolean;
  disabledInboundDisclaimerFunnel?: string | null;
  disabledOutboundFunnel: boolean;
  disabledOutboundDisclaimerFunnel?: string | null;
  disabledInboundMobile: boolean;
  disabledInboundDisclaimerMobile?: string | null;
  disabledOutboundMobile: boolean;
  disabledOutboundDisclaimerMobile?: string | null;
}

export interface AppConfigDto {
  bankLink?: ConfigBankLinkDto | null;
  bankTransfer?: ConfigBankTransferDto | null;
}

export interface ConfigDto {
  id: string;
  configType: ConfigTypeDto;
  appConfig: AppConfigDto;
  createdAt?: string | null;
  updatedAt?: string | null;
  revision: number;
}

export interface CreateConfigBodyDto {
  configType: ConfigTypeDto;
  appConfig: AppConfigDto;
}

export interface CreateConfigDto {
  body: CreateConfigBodyDto;
}

export interface BaseConfigParamsDto {
  id: string;
}

export enum GetConfigListSortByQueryParamTypeDto {
  CreatedAt = 'createdAt',
  ConfigType = 'configType',
}

export interface GetConfigListQueryParamsDto extends Partial<SortDirectionQueryParams>, Partial<PaginationQueryParams> {
  sortBy?: GetConfigListSortByQueryParamTypeDto;
}

export interface GetConfigListDto {
  queryParams: GetConfigListQueryParamsDto;
}

export interface GetConfigListResponseDto extends BaseListResponseDto<ConfigDto[]> {}

export interface GetConfigDto {
  params: BaseConfigParamsDto;
}

export type PatchConfigBodyDto = Pick<CreateConfigBodyDto, 'appConfig'>;

export interface PatchConfigDto {
  params: BaseConfigParamsDto;
  body: PatchConfigBodyDto;
}

export interface DeleteConfigDto {
  params: BaseConfigParamsDto;
}
