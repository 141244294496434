import { OfferWatchUrlConstant } from 'src/constants';
import {
  OfferWatchDto,
  CreateOfferWatchDto,
  GetOfferWatchDto,
  GetAllOfferWatchListDto,
  GetAllOfferWatchListResponseDto,
  DeleteOfferWatchDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class OfferWatchesApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async create({ body }: CreateOfferWatchDto): Promise<OfferWatchDto> {
    return httpRequest<OfferWatchDto>({
      method: 'POST',
      url: OfferWatchUrlConstant.CREATE,
      body,
      authToken: this._configService.authToken,
    });
  }

  async list(): Promise<OfferWatchDto[]> {
    return httpRequest<OfferWatchDto[]>({
      method: 'GET',
      url: OfferWatchUrlConstant.LIST,
      authToken: this._configService.authToken,
    });
  }

  async listAll({ queryParams }: GetAllOfferWatchListDto): Promise<GetAllOfferWatchListResponseDto> {
    return httpRequest<GetAllOfferWatchListResponseDto>({
      method: 'GET',
      url: OfferWatchUrlConstant.LIST_ALL,
      queryParams,
      authToken: this._configService.authToken,
    });
  }

  async retrieve({ params }: GetOfferWatchDto): Promise<OfferWatchDto> {
    const url = replacePlaceholders(OfferWatchUrlConstant.RETRIEVE, params);

    return httpRequest<OfferWatchDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async delete({ params }: DeleteOfferWatchDto): Promise<void> {
    const url = replacePlaceholders(OfferWatchUrlConstant.DELETE, params);

    await httpRequest<OfferWatchDto>({
      method: 'DELETE',
      url,
      authToken: this._configService.authToken,
    });
  }
}
