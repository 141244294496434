/**
 * Types of admin levels and their access:
 *  - Full: Allow full access to view/edit any resource
 *  - Supervisor: Able to update basic customer info (Address, telephone, email, etc.),
 *      edit suitability info, and approve user resources
 *  - Regular: Able to only update basic customer info (Address, telephone, email, etc.)
 *      and suitability info
 *  - Read only: Allows for only viewing resources
 */

export enum ADMIN_LEVELS {
  ROOT_ADMIN_GROUP = 'ROOT_ADMIN_GROUP',
  SUPER_ADMIN_GROUP = 'SUPER_ADMIN_GROUP',
  READ_ONLY_ADMIN_GROUP = 'READ_ONLY_ADMIN_GROUP',
}
