import React, { useEffect } from 'react';

import { Grid } from '@mui/material';
import { NumberInput, BooleanInput, required, Loading, useRecordContext, useGetList } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { DataProviderResource } from 'src/constants';
import { OfferOrderAggregate } from 'src/models/offerOrders.models';

import { OfferOrderStatusDto } from '../../../../dtos/offerOrders.dtos';
import { OfferCategoryNameDto } from '../../../../dtos/offers.dtos';
import { GridBreak } from '../../../../lib/GridBreak/GridBreak';

interface PriceCreateEditProps {
  isEditMode?: boolean;
  categoryName?: string;
}

export const PriceCreateEdit = ({ isEditMode = false, categoryName }: PriceCreateEditProps) => {
  const record = useRecordContext();
  const { setValue, register } = useFormContext();
  const [curTotalRaiseCurrency, setCurTotalRaiseCurrency] = React.useState<string>();
  const { data: offerOrderAggregate, isLoading } = isEditMode
    ? useGetList<OfferOrderAggregate>(DataProviderResource.OfferOrdersAggregate, {
        filter: {
          offerId: record?.id,
          status: [
            OfferOrderStatusDto.PendingFunds,
            OfferOrderStatusDto.PendingOfferClose,
            OfferOrderStatusDto.PendingAction,
            OfferOrderStatusDto.Approved,
            OfferOrderStatusDto.Complete,
            OfferOrderStatusDto.PendingFirmCancellation,
          ],
        },
      })
    : { data: [], isLoading: false };

  useEffect(() => {
    if (Array.isArray(offerOrderAggregate)) {
      const curTotalRaiseCurrencyAmt = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(offerOrderAggregate?.[0]?.sumRaisedInvestment || 0);
      setCurTotalRaiseCurrency(curTotalRaiseCurrencyAmt);
    }
  }, [offerOrderAggregate]);

  useEffect(() => {
    setValue('_categoryName', categoryName);
  }, [categoryName]);

  if (isLoading) return <Loading />;

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <h2>{isEditMode ? 'Edit' : 'Add'} Price & Limits Information </h2>
        {isEditMode ? <h6>(Current Total Raise Currency {curTotalRaiseCurrency}) </h6> : ''}
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Minimum Raise Amount'
          source='minTotalRaiseCurrencyAmt'
          validate={[required()]}
          fullWidth
          min='0'
          max='999999999'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Maximum Raise Amount'
          source='maxTotalRaiseCurrencyAmt'
          validate={[required()]}
          fullWidth
          min='0'
          max='9999999900'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Minimum Investment Currency'
          source='minimumInvestCurrencyAmt'
          validate={[required()]}
          fullWidth
          min='0'
          max='999999999'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Maximum Investment Currency'
          source='maximumInvestCurrencyAmt'
          fullWidth
          min='0'
          max='999999999'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Minimum Investment Increment'
          source='minimumInvestIncCurrencyAmt'
          validate={[required()]}
          fullWidth
          min='0'
          max='999999999'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Minimum Investment Share Increment Qty'
          source='minimumInvestShareIncrQty'
          fullWidth
          validate={required()}
          min='0'
          max='999999999'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Minimum Invest Share Qty'
          source='minimumInvestShareQty'
          fullWidth
          validate={required()}
          min='0'
          max='999999999'
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumberInput
          label='Maximum Invest Share Qty'
          source='maximumInvestShareQty'
          min='0'
          max='999999999'
          fullWidth
          validate={required()}
        />
      </Grid>
      {categoryName !== OfferCategoryNameDto.Conditional && (
        <Grid item xs={12} sm={6}>
          <NumberInput
            label='Price Per Share'
            source='pricePerShare'
            validate={[required()]}
            fullWidth
            min='0'
            max='999999999'
          />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <BooleanInput
          label='Allow restricted persons'
          source='allowRestrictedPersons'
          validate={[required()]}
          fullWidth
        />
      </Grid>
      {categoryName === OfferCategoryNameDto.Conditional && (
        <>
          <GridBreak />
          <Grid item xs={12}>
            <h3>Conditional Offer Details</h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              label='Price Per Share'
              source='pricePerShare'
              validate={[required()]}
              fullWidth
              min='0'
              max='999999999'
            />
          </Grid>
          <GridBreak />
          <Grid item xs={12} sm={6}>
            <NumberInput
              label='Min Share Price'
              source='minSharePrice'
              min='0.01'
              max='999999999'
              fullWidth
              validate={required()}
            />
          </Grid>
          <GridBreak />
          <Grid item xs={12} sm={6}>
            <NumberInput
              label='Max Share Price'
              source='maxSharePrice'
              validate={[required()]}
              fullWidth
              min='0.01'
              max='999999999'
            />
          </Grid>
          {isEditMode && <input hidden {...register('_categoryName')} />}
        </>
      )}
    </Grid>
  );
};
export default PriceCreateEdit;
