import * as React from 'react';

import { Grid } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';
import { required } from 'react-admin';

interface DescriptionCreateEditProps {
  isEditMode?: boolean;
}

export const DescriptionCreateEdit = ({ isEditMode = false }: DescriptionCreateEditProps) => (
  <Grid container direction='row' spacing={2}>
    <Grid item xs={12}>
      <h2>{isEditMode ? 'Edit' : 'Add'} Description Information *</h2>
    </Grid>
    <Grid item xs={12}>
      <RichTextInput label={false} source='description' validate={[required()]} fullWidth />
    </Grid>
  </Grid>
);
export default DescriptionCreateEdit;
