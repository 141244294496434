import { BaseListResponseDto, PaginationQueryParams } from './common.dtos';

export enum TradingBlockOrderTypeEnum {
  Undefined = 'Undefined',
  Market = 'Market',
  Limit = 'Limit',
  Stop_Market = 'Stop_Market',
  Stop_Limit = 'Stop_Limit',
  Market_On_Close = 'Market_On_Close',
}

export enum TradingBlockOrderTypeNumberEnum {
  Undefined = 0,
  Market = 1,
  Limit = 2,
  Stop_Market = 3,
  Stop_Limit = 4,
  Market_On_Close = 5,
}

export enum TradingBlockOrderActionNumberEnum {
  Undefined = 0,
  Buy = 1,
  Sell = 2,
}

export enum TradingBlockOrderActionEnum {
  Buy = 'Buy',
  Sell = 'Sell',
}

export enum TradingBlockOrderDurationEnum {
  Day = 'Day',
  GTC = 'GTC',
}

export enum TradingBlockOrderStatusIntDto {
  Undefined = 0,
  New,
  PartiallyFilled,
  Filled,
  DoneForDay,
  Cancelled,
  Replaced,
  PendingCancel,
  Stopped,
  Rejected,
  Suspended,
  PendingNew,
  Calculated,
  Expired,
  PendingReplace,
  Saved,
  LiveUntriggered,
  Scheduled,
  OCO_Untriggered,
  CancelledUntriggered,
  Initiated,
  ReplaceInitiated,
  CancelInitiated,
  CancelRejected,
  ReplaceRejected,
  Busted,
  PreAllocated,
  Acknowledged,
  PartiallyCancelled,
}

export enum TradingBlockOrderStatusDto {
  NA = 'NA',
  New = 'New',
  PartiallyFilled = 'PartiallyFilled',
  Filled = 'Filled',
  DoneForDay = 'DoneForDay',
  Cancelled = 'Cancelled',
  Replaced = 'Replaced',
  PendingCancel = 'PendingCancel',
  Stopped = 'Stopped',
  Rejected = 'Rejected',
  Suspended = 'Suspended',
  PendingNew = 'PendingNew',
  Calculated = 'Calculated',
  Expired = 'Expired',
  PendingReplace = 'PendingReplace',
  Saved = 'Saved',
  LiveUntriggered = 'LiveUntriggered',
  Scheduled = 'Scheduled',
  OCO_Untriggered = 'OCO_Untriggered',
  CancelledUntriggered = 'CancelledUntriggered',
  Initiated = 'Initiated',
  ReplaceInitiated = 'ReplaceInitiated',
  CancelInitiated = 'CancelInitiated',
  CancelRejected = 'CancelRejected',
  ReplaceRejected = 'ReplaceRejected',
  Busted = 'Busted',
  PreAllocated = 'PreAllocated',
  Acknowledged = 'Acknowledged',
  PartiallyCancelled = 'PartiallyCancelled',
}

export type CreateOrderBodyDto = {
  duration: TradingBlockOrderDurationEnum;
  orderType: TradingBlockOrderTypeEnum;
  action: TradingBlockOrderActionEnum;
  price?: number;
  stop?: number;
  quantity: number;
  symbol: string;
};

export interface OrderDto {
  AccountId: number;
  AllOrNone: boolean;
  AverageFillPrice?: number;
  BypassWarnings?: boolean;
  ClientRefId?: number;
  CommissionType?: string;
  CommissionAssessed: number;
  Date: string;
  Description?: string;
  Duration: TradingBlockOrderDurationEnum;
  FillQuantity?: number;
  Legs?: Array<{
    Action?: TradingBlockOrderActionNumberEnum;
    AssetType?: string;
    AverageLegFillPrice?: number;
    CallPut?: boolean;
    LegFillQuantity?: number;
    PositionEffect?: string;
    SpreadRatio?: number;
    Symbol?: string;
  }>;
  OrderId: number;
  OrderStatus: TradingBlockOrderStatusIntDto;
  OrderType: TradingBlockOrderTypeNumberEnum;
  Price?: number;
  Quantity: number;
  Stop?: number;
  UnderlyingSymbol?: string;
}

export interface OrderPositionDto {
  AccountId: number;
  Commission: number;
  CostBasis: number;
  DateOpened: string;
  Description?: string;
  HasWorkingCloseOrder: boolean;
  HasWorkingOpenOrder: boolean;
  IsSameSymbolGroup: true;
  NoOfLegs: number;
  OpenPrice: number;
  OpenQuantity: number;
  OptionMultiplier: number;
  OrderId: number;
  SubaccountId: number;
  Symbol: string;
  UnderlyingSymbol?: string;
}

export interface BaseOrderParamsDto {
  accountId: number;
}

export interface CreateOrderDto {
  params: BaseOrderParamsDto;
  body: CreateOrderBodyDto;
}

export interface GetOrderListQueryParamsDto extends PaginationQueryParams {
  dateFrom?: string;
  dateTo?: string;
  symbol?: string;
}

export interface GetOrderListDto {
  queryParams?: GetOrderListQueryParamsDto;
  params: BaseOrderParamsDto;
}

export interface GetOrderListResponseDto extends BaseListResponseDto<OrderDto[]> {}

export interface GetOrderParamsDto {
  id: number;
  accountId: number;
}

export interface GetOrderDto {
  params: GetOrderParamsDto;
}

export interface CancelOrderParamsDto {
  id: number;
  accountId: number;
}

export interface CancelOrderDto {
  params: CancelOrderParamsDto;
}

export interface GetOrderPositionListDto {
  params: BaseOrderParamsDto;
}

export interface GetOrderPositionListResponseDto extends BaseListResponseDto<OrderPositionDto[]> {}
