import { TextInput, NumberInput, SelectInput } from 'react-admin';

const AccountStatusList = [
  { id: 'Pending', name: 'Pending' },
  { id: 'New', name: 'New' },
  { id: 'InvestigationSubmitted', name: 'Investigation Submitted' },
  { id: 'ReadyForBackOffice', name: 'Ready For Back Office' },
  { id: 'BackOffice', name: 'Back Office' },
  { id: 'AccountSetup', name: 'Account Setup' },
  { id: 'Complete', name: 'Complete' },
  { id: 'ActionRequired', name: 'Action Required' },
  { id: 'Rejected', name: 'Rejected' },
  { id: 'Suspended', name: 'Suspended' },
  { id: 'Error', name: 'Error' },
  { id: 'Canceled', name: 'Canceled' },
  { id: 'PendingSubmit', name: 'Pending Submit' },
  { id: 'NotSubmitted', name: 'Not Submitted' },
];

export const accountType = [{ id: 'individual', name: 'Individual' }];

export const maritalStatus = [
  { id: 'divorced', name: 'Divorced' },
  { id: 'married', name: 'Married' },
  { id: 'single', name: 'Single' },
  { id: 'widowed', name: 'Widowed' },
];

export const UserListFilters = [
  <TextInput key='accountNumber' label='Account number' source='accountNumber' />,
  <NumberInput key='accountId' label='Account id' source='accountId' />,
  <TextInput key='firstName' label='First name' source='firstName' />,
  <TextInput key='lastName' label='Last name' source='lastName' />,
  <TextInput key='email' label='Email' source='email' />,
  <TextInput key='phoneNumber' label='Phone number' source='phoneNumber' />,
  <SelectInput key='accountStatus' label='Account status' source='accountStatus' choices={AccountStatusList} />,
];
