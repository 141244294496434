export const liquidityNeedChoices = [
  { id: 'notImportant', name: 'Not Important' },
  { id: 'somewhatImportant', name: 'Somewhat Important' },
  { id: 'veryImportant', name: 'Very Important' },
];

export const investmentObjectiveChoices = [
  { id: 'capitalPreservation', name: 'Capital Preservation' },
  { id: 'income', name: 'Income' },
  { id: 'growth', name: 'Growth' },
  { id: 'growthAndIncome', name: 'Growth And Income' },
  { id: 'speculation', name: 'Speculation' },
];

export const riskToleranceChoices = [
  { id: 'low', name: 'Low' },
  { id: 'medium', name: 'Medium' },
  { id: 'high', name: 'High' },
];

export const timeHorizonChoices = [
  { id: 'short', name: 'Short' },
  { id: 'average', name: 'Average' },
  { id: 'long', name: 'Long' },
];

export const yearsOfExperienceChoices = [
  { id: 'One', name: ' 1' },
  { id: 'TwoToThree', name: '2 - 3' },
  { id: 'FourToNine', name: '4 - 9' },
  { id: 'TenOrMore', name: '10+' },
];

export const tradesPerYearsChoices = [
  { id: 'OneToFive', name: '1 - 5' },
  { id: 'SixToTen', name: '6 - 10' },
  { id: 'ElevenToTwenty', name: '11 - 20' },
  { id: 'MoreThanTwenty', name: '20+' },
];
