import { isEmpty } from 'lodash';
import {
  CreateParams,
  CreateResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { QueryParamConstant } from 'src/constants';
import { getClientApi } from 'src/data-communication';
import {
  SortDirectionTypeDto,
  PrivateQuoteDto,
  CreatePrivateQuoteBodyDto,
  GetPrivateQuoteListSortByQueryParamTypeDto,
  PatchPrivateQuoteBodyDto,
  PrivateQuoteSnapDto,
} from 'src/dtos';

const clientApi = getClientApi();

export const getPrivateQuoteList = async (params: GetListParams): Promise<GetListResult<PrivateQuoteDto>> => {
  const { data, total } = await clientApi.privateQuotes.list({
    queryParams: {
      skip:
        params.pagination.perPage === QueryParamConstant.SKIP_PAGINATION
          ? undefined
          : params.pagination.perPage && params.pagination.perPage * (params.pagination.page - 1),
      take: params.pagination.perPage === QueryParamConstant.SKIP_PAGINATION ? undefined : params.pagination.perPage,
      filterBy: isEmpty(params.filter) ? undefined : params.filter,
      sortDirection: params.sort.order.toLowerCase() as SortDirectionTypeDto,
      sortBy:
        params.sort.field === QueryParamConstant.SKIP_SORT
          ? undefined
          : (params.sort.field as GetPrivateQuoteListSortByQueryParamTypeDto),
    },
  });

  return {
    data,
    total,
  };
};

export const snapPrivateQuoteList = async (
  params: GetListParams,
): Promise<{ data: PrivateQuoteSnapDto[]; total: number }> => {
  if (isEmpty(params.filter?.symbols ?? [])) {
    return {
      data: [],
      total: 0,
    };
  }
  const { data, total } = await clientApi.privateQuotes.snap({
    queryParams: {
      symbols: params.filter.symbols,
    },
  });

  return {
    data,
    total,
  };
};

export const getPrivateQuote = async (params: GetOneParams): Promise<GetOneResult<PrivateQuoteDto>> => {
  const { id } = params;

  const data = await clientApi.privateQuotes.retrieve({ params: { id } });

  return {
    data,
  };
};

export const createPrivateQuote = async (
  params: CreateParams<CreatePrivateQuoteBodyDto>,
): Promise<CreateResult<PrivateQuoteDto>> => {
  const data = await clientApi.privateQuotes.create({ body: params.data });

  return {
    data,
  };
};

export const patchPrivateQuote = async (
  params: UpdateParams<PatchPrivateQuoteBodyDto>,
): Promise<UpdateResult<PrivateQuoteDto>> => {
  const { id } = params;

  const data = await clientApi.privateQuotes.patch({ params: { id: id.toString() }, body: params.data });

  return {
    data,
  };
};

export const deletePrivateQuote = async (params: DeleteParams): Promise<DeleteResult<PrivateQuoteDto>> => {
  const { id, previousData } = params;

  await clientApi.privateQuotes.delete({ params: { id } });

  return {
    data: { ...previousData },
  };
};
