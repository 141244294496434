import React, { useState } from 'react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import {
  useShowController,
  useNotify,
  useRefresh,
  Button,
  SelectInput,
  Form,
  useCreate,
  TextInput,
  required,
  maxLength,
} from 'react-admin';

import { DataProviderResource } from '../../../constants/common.constants';
import {
  OfferOrderRefundDto,
  OfferOrderRefundMechanismDto,
  OfferOrderRefundReasonDto,
} from '../../../dtos/offerOrders.dtos';
import { getCurrencyFormatter } from '../../../utils/getCurrenyFormatter';

const reasonChoices = [
  { id: OfferOrderRefundReasonDto.Duplicate, name: 'Duplicate' },
  { id: OfferOrderRefundReasonDto.Fraudulent, name: 'Fraudulent' },
  { id: OfferOrderRefundReasonDto.RequestedByCustomer, name: 'Requested by Customer' },
];

const mechanismChoices = [
  { id: OfferOrderRefundMechanismDto.Card, name: 'Card' },
  { id: OfferOrderRefundMechanismDto.Ach, name: 'ACH' },
  { id: OfferOrderRefundMechanismDto.Check, name: 'Check' },
  { id: OfferOrderRefundMechanismDto.Wire, name: 'Wire' },
];

export interface CreateOfferOrderRefundModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface CreateOfferOrderRefundView {
  mechanism: OfferOrderRefundMechanismDto;
  reason?: OfferOrderRefundReasonDto;
  adminNote?: string;
  bankName?: string;
}

export const CreateOfferOrderRefundModal = ({ isOpen, onClose }: CreateOfferOrderRefundModalProps) => {
  const { record } = useShowController();
  const notify = useNotify();
  const refresh = useRefresh();
  const [create, { isLoading }] = useCreate<OfferOrderRefundDto>();
  const [mechanism, setMechanism] = useState<OfferOrderRefundMechanismDto>(OfferOrderRefundMechanismDto.Card);

  const onMechanismChange = (value: any) => {
    setMechanism(value.target.value);
  };

  const onCreateRefund = (values: any) => {
    create(
      DataProviderResource.OfferOrderRefunds,
      {
        data: {
          reason: values.reason ? values.reason : undefined,
          mechanism: values.mechanism,
          bankName: values.bankName ? values.bankName : undefined,
          adminNote: values.adminNote ? values.adminNote : undefined,
        },
        meta: {
          offerOrderId: record.id,
        },
      },
      {
        onSuccess: () => {
          notify(`Offer order refund was successfully created.`, {
            type: 'success',
            undoable: true,
          });
          onClose();
        },
        onSettled: () => {
          refresh();
        },
        onError: err => {
          notify(`Error creating offer order refund: ${err}`, {
            type: 'error',
          });
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth='lg'>
      <DialogTitle>Create Offer Order Refund</DialogTitle>
      <DialogContent style={{ marginTop: '32px' }}>
        <Form onSubmit={onCreateRefund} resource={DataProviderResource.OfferOrderRefunds} defaultValues={{ mechanism }}>
          <Grid container direction='column'>
            <SelectInput
              source='mechanism'
              choices={mechanismChoices}
              label='Mechanism'
              disabled={isLoading}
              onChange={onMechanismChange}
              validate={required()}
            />
            <SelectInput source='reason' choices={reasonChoices} label='Reason' disabled={isLoading} />
            {mechanism !== OfferOrderRefundMechanismDto.Card && (
              <TextInput source='bankName' label='Bank name' disabled={isLoading} validate={maxLength(255)} />
            )}
            <TextInput
              multiline
              rows={5}
              source='adminNote'
              label='Admin note'
              disabled={isLoading}
              validate={maxLength(255)}
            />
            <DialogActions>
              <Button
                variant='contained'
                label='Cancel'
                disabled={isLoading}
                color='inherit'
                size='medium'
                onClick={onClose}
              />
              <Button
                variant='contained'
                type='submit'
                label={`Refund(${getCurrencyFormatter().format(record.totalCost)})`}
                disabled={isLoading}
                color='primary'
                size='medium'
              />
            </DialogActions>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
