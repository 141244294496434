import { Datagrid, TextField, NumberField, FunctionField, DateField, List } from 'react-admin';
import { DataProviderResource, QueryParamConstant } from 'src/constants';
import { StatusTag } from 'src/lib';
import { getOrderStatusTagColor } from 'src/utils/colors.utils';

import { SortDirectionTypeDto } from '../../dtos/common.dtos';
import { Order } from '../../models/order.model';

export interface AccountOrderListProps {
  accountId: number;
}

export const AccountOrderList = ({ accountId }: AccountOrderListProps) => {
  return (
    <List
      actions={false}
      exporter={false}
      disableSyncWithLocation
      resource={DataProviderResource.Orders}
      title=' '
      empty={false}
      queryOptions={{ meta: { accountId } }}
      sort={{ field: QueryParamConstant.SKIP_SORT, order: SortDirectionTypeDto.Desc }}>
      <Datagrid isRowSelectable={() => false} bulkActionButtons={false}>
        <FunctionField
          label='Status'
          render={(record: Order) => {
            return <StatusTag value={record.status.label} style={getOrderStatusTagColor(record.status.label)} />;
          }}
        />
        <FunctionField label='Type' render={(record: Order) => record.action.label} />
        <TextField source='description' label='Security Name' />
        <TextField source='symbol' label='Security Symbol' />
        <FunctionField label='Order Type' render={(record: Order) => record.type.label} />
        <TextField source='quantity' label='Number of Share(s)' />
        <TextField source='filledQuantity' label='Number of Filled Share(s)' />
        <NumberField source='price' label='Price per Share' options={{ style: 'currency', currency: 'USD' }} />
        <NumberField source='commission' label='Service Fee' options={{ style: 'currency', currency: 'USD' }} />
        <NumberField source='totalCost' label='Total Amount' options={{ style: 'currency', currency: 'USD' }} />
        <DateField source='updatedAt' label='Date' />
        <FunctionField
          label='TB State'
          render={(record: Order) => record.status.value.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()}
        />
      </Datagrid>
    </List>
  );
};
