import * as React from 'react';

import states from '../../assets/us_states.json';
export interface StateFieldProps {
  record: any;
  source: string;
}

const getState = (abbreviation: string) => {
  return states.find(item => item.abbreviation === abbreviation);
};

const StateField = (props: StateFieldProps) => {
  const { source, record } = props;

  const abbreviation = record[source];
  const state = getState(abbreviation);

  return state ? <span>{state.name}</span> : null;
};
export default StateField;
