import { useGetList } from 'react-admin';

import { OrderPosition } from '../../models/order.model';
import { AccountPositionList } from './AccountPositionList';

export interface NonTradableUserPositionsListProps {
  accountId: number;
}

export const AccountNontradablePositionList = ({ accountId }: NonTradableUserPositionsListProps) => {
  const { data, isLoading } = useGetList<OrderPosition>('orders.positions', {
    meta: {
      accountId,
    },
  });

  const nonTradablePositions = data?.filter(aPosition => !aPosition.isTradable);

  return (
    <AccountPositionList items={nonTradablePositions ?? []} isLoading={isLoading} omitColumn={['change', 'symbol']} />
  );
};
