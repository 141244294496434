import React, { useState } from 'react';

import { CheckCircle, Cancel, Edit } from '@mui/icons-material';
import { useRecordContext } from 'react-admin';
import { AchRelationshipActionType } from 'src/constants/choices/ach.relationships.choices';
import { AchRelationship, AchRelationshipDetails, SearchAchRelationshipItem } from 'src/models';

import Colors from '../../constants/colors';
import Styles from '../../constants/styles';
import ActionModals from './actionModals';

const Actions = ({ bodyStyle = {} }: any) => {
  const record = useRecordContext();

  const [curElement, setCurElement] = useState<SearchAchRelationshipItem | AchRelationship | AchRelationshipDetails>();
  const [decideModalOpen, setDecideModalOpen] = useState<boolean>(false);
  const [activeType, setActiveType] = useState<AchRelationshipActionType>(AchRelationshipActionType.APPROVE);

  const approveHandle = (data: any) => {
    setCurElement(data);
    setActiveType(AchRelationshipActionType.APPROVE);
    setDecideModalOpen(true);
  };

  const rejectHandle = (data: any) => {
    setCurElement(data);
    setActiveType(AchRelationshipActionType.REJECT);
    setDecideModalOpen(true);
  };

  const cancelHandle = (data: any) => {
    setCurElement(data);
    setActiveType(AchRelationshipActionType.CANCEL);
    setDecideModalOpen(true);
  };

  const editHandle = (data: any) => {
    setCurElement(data);
    setActiveType(AchRelationshipActionType.EDIT);
    setDecideModalOpen(true);
  };

  const ActionModalsContent = () => {
    return curElement ? (
      <ActionModals
        curElement={curElement}
        modalOpen={decideModalOpen}
        setModalOpen={setDecideModalOpen}
        type={activeType}
      />
    ) : (
      <></>
    );
  };

  const getItems = (status: string) => {
    switch (status) {
      case 'Unknown':
      case 'Void':
        return <></>;
      case 'PendingRepCreateApproval':
      case 'PendingFirmCreateApproval':
      case 'PendingRepCreateApproval':
      case 'PendingFirmCreateApproval':
      case 'PendingCreateApproval':
        return (
          <>
            <button
              className='customButton'
              style={Styles.achRelationship.menuItem}
              onClick={() => approveHandle(record)}>
              <CheckCircle style={{ color: Colors.SUCCESS }} />
              <span style={{ color: Colors.SUCCESS_DARK, paddingLeft: 5 }}>APPROVE</span>
            </button>
            <button
              className='customButton'
              style={Styles.achRelationship.menuItem}
              onClick={() => rejectHandle(record)}>
              <Cancel style={{ color: Colors.DANGER }} />
              <span style={{ color: Colors.DANGER_DARK, paddingLeft: 5 }}>REJECT</span>
            </button>
            <button className='customButton' style={Styles.achRelationship.menuItem} onClick={() => editHandle(record)}>
              <Edit style={{ color: Colors.LIGHTER_MY_IPO_BLUE }} />
              <span style={{ color: Colors.MY_IPO_BLUE, paddingLeft: 5 }}>EDIT</span>
            </button>
          </>
        );

      case 'Approved':
      case 'Requested':
      case 'Pending':
      case 'PendingPrinting':
      case 'Postponed':
      case 'Pending':
        return (
          <>
            <button
              className='customButton'
              style={Styles.achRelationship.menuItem}
              onClick={() => cancelHandle(record)}>
              <Cancel style={{ color: Colors.DANGER }} />
              <span style={{ color: Colors.DANGER_DARK, paddingLeft: 5 }}>CANCEL</span>
            </button>
            <button className='customButton' style={Styles.achRelationship.menuItem} onClick={() => editHandle(record)}>
              <Edit style={{ color: Colors.LIGHTER_MY_IPO_BLUE }} />
              <span style={{ color: Colors.MY_IPO_BLUE, paddingLeft: 5 }}>EDIT</span>
            </button>
          </>
        );

      case 'Cancelled':
      case 'Canceled':
        return (
          <div>
            <button className='customButton' style={Styles.achRelationship.menuItem}>
              <Cancel style={{ color: Colors.DANGER }} />
              <span style={{ color: Colors.DANGER_DARK, paddingLeft: 5, opacity: 0.6 }}>CANCELED</span>
            </button>
          </div>
        );

      case 'FundsPosted':
      case 'FundsHeld':
      case 'SentToBank':
      case 'Returned':
      case 'StopPayment':
      case 'CreateRejected':
      case 'Rejected':
      case 'Complete':
      default:
        return (
          <>
            <button className='customButton' style={Styles.achRelationship.menuItem} onClick={() => editHandle(record)}>
              <Edit style={{ color: Colors.LIGHTER_MY_IPO_BLUE }} />
              <span style={{ color: Colors.MY_IPO_BLUE, paddingLeft: 5 }}>EDIT</span>
            </button>
          </>
        );
    }
  };

  if (record) {
    return (
      <>
        <div style={{ ...Styles.defaults.alignCenter, ...bodyStyle }}>{getItems(record.status)}</div>
        <ActionModalsContent />
      </>
    );
  } else return <></>;
};
export default Actions;
