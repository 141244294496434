import ZoomInIcon from '@mui/icons-material/ZoomIn';

import ConfigCreate from './ConfigCreate';
import ConfigEdit from './ConfigEdit';
import ConfigList from './ConfigList';
import ConfigShow from './ConfigShow';

const Config = {
  list: ConfigList,
  show: ConfigShow,
  edit: ConfigEdit,
  create: ConfigCreate,
  icon: ZoomInIcon,
};

export default Config;
