import { AddressDto } from './addresses.dtos';

enum AccountHolderMaritalStatusEnum {
  divorced = 'divorced',
  married = 'married',
  single = 'single',
  widowed = 'widowed',
}

export enum AccountTypeDto {
  individual = 'individual',
}

export interface AccountHolderPhysicalAddressDto extends AddressDto {}

export interface AccountHolderMailingAddressDto extends AccountHolderPhysicalAddressDto {
  sameAsPhysical: boolean | null;
}

export enum AccountStatusDto {
  Pending = 'Pending',
  New = 'New',
  InvestigationSubmitted = 'InvestigationSubmitted',
  ReadyForBackOffice = 'ReadyForBackOffice',
  BackOffice = 'BackOffice',
  AccountSetup = 'AccountSetup',
  Complete = 'Complete',
  ActionRequired = 'ActionRequired',
  Rejected = 'Rejected',
  Suspended = 'Suspended',
  Error = 'Error',
  Canceled = 'Canceled',
  PendingSubmit = 'PendingSubmit',
}

export enum StatementDelivery {
  Electronic = 'Electronic',
  Paper = 'Paper',
}

export interface DisclosureDto {
  userId: string;
  statementDelivery: StatementDelivery;
  stakeholder: false | { tickerSymbol: string };
  industryEmployed: false | { firmName: string };
}

export enum AnnualIncome {
  lessThan25K = 'LessThan25K',
  between25KAnd50K = 'Between25KAnd50K',
  between50KAnd100K = 'Between50KAnd100K',
  between100KAnd200K = 'Between100KAnd200K',
  between200KAnd300K = 'Between200KAnd300K',
  between300KAnd500K = 'Between300KAnd500K',
  between500KAnd1Point2M = 'Between500KAnd1Point2M',
  moreThan1Point2M = 'MoreThan1Point2M',
}

export enum TotalNetWorth {
  lessThan50K = 'LessThan50K',
  between50KAnd100K = 'Between50KAnd100K',
  between100KAnd200K = 'Between100KAnd200K',
  between200KAnd500K = 'Between200KAnd500K',
  between500KAnd1M = 'Between500KAnd1M',
  between1MAnd5M = 'Between1MAnd5M',
  boreThan5M = 'MoreThan5M',
}

export enum LiquidNetWorth {
  lessThan50K = 'LessThan50K',
  between50KAnd100K = 'Between50KAnd100K',
  between100KAnd200K = 'Between100KAnd200K',
  between200KAnd500K = 'Between200KAnd500K',
  between500KAnd1M = 'Between500KAnd1M',
  between1MAnd5M = 'Between1MAnd5M',
  moreThan5M = 'MoreThan5M',
}

export enum TaxBracket {
  bottom = 'Bottom',
  middle = 'Middle',
  top = 'Top',
}

export enum EmploymentStatus {
  employed = 'Employed',
  retired = 'Retired',
  student = 'Student',
  unemployed = 'Unemployed',
}

export enum YearsEmployed {
  betweenZeroAndOne = 'BetweenZeroAndOne',
  betweenTwoAndFive = 'BetweenTwoAndFive',
  betweenFiveAndTen = 'BetweenFiveAndTen',
  betweenTenAndTwenty = 'BetweenTenAndTwenty',
  moreThanTwenty = 'MoreThanTwenty',
}

export interface FinancialInformationDto {
  annualIncome?: AnnualIncome;
  totalNetWorth?: TotalNetWorth;
  liquidNetWorth?: LiquidNetWorth;
  taxBracket?: TaxBracket;
  employmentStatus: EmploymentStatus;
  employerName?: string;
  jobTitle?: string;
  yearsEmployed?: YearsEmployed;
  employerAddress?: AddressDto;
}

export enum TradesPerYears {
  OneToFive = 'OneToFive',
  SixToTen = 'SixToTen',
  ElevenToTwenty = 'ElevenToTwenty',
  MoreThanTwenty = 'MoreThanTwenty',
}

export enum YearsOfExperience {
  One = 'One',
  TwoToThree = 'TwoToThree',
  FourToNine = 'FourToNine',
  TenOrMore = 'TenOrMore',
}

export enum LiquidityNeeds {
  notImportant = 'NotImportant',
  somewhatImportant = 'SomewhatImportant',
  veryImportant = 'VeryImportant',
}

export enum InvestmentObjectives {
  capitalPreservation = 'CapitalPreservation',
  income = 'Income',
  growth = 'Growth',
  growthAndIncome = 'GrowthAndIncome',
  speculation = 'Speculation',
}

export enum RiskTolerance {
  low = 'Low',
  medium = 'Medium',
  high = 'High',
}

export enum TimeHorizon {
  short = 'Short',
  average = 'Average',
  long = 'Long',
}

export interface FinancialInvestmentExperienceType {
  yearsOfExperience: YearsOfExperience;
  tradesPerYears: TradesPerYears;
}

export interface FIEFieldType {
  stocks?: FinancialInvestmentExperienceType;
  options?: FinancialInvestmentExperienceType;
  commodities?: FinancialInvestmentExperienceType;
  bonds?: FinancialInvestmentExperienceType;
  margins?: FinancialInvestmentExperienceType;
}
export interface SuitabilityInformationDto {
  liquidityNeeds: LiquidityNeeds;
  investmentObjectives: InvestmentObjectives;
  riskTolerance: RiskTolerance;
  timeHorizon: TimeHorizon;
  financialInvestmentExperience?: FIEFieldType;
}

export enum Phone_type {
  Home = 'Home',
  Work = 'Work',
  Mobile = 'Mobile',
}

export type phonePayload = {
  phoneNumber: string;
  type: Phone_type;
};
export interface TrustedContactDto extends AddressDto {
  firstName: string;
  lastName: string;
  phones: Array<phonePayload>;
}

export interface AccountHolderDto {
  id: string;
  ssid?: string;
  maskedSsid?: string;
  firstName: string;
  lastName: string;
  dateOfBirth?: string | null;
  maritalStatus?: AccountHolderMaritalStatusEnum | null;
  numberOfDependents?: number | null;
  birthCountry?: string | null;
  citizenshipCountry?: string | null;
  physicalAddress?: AccountHolderPhysicalAddressDto | null;
  mailingAddress?: AccountHolderMailingAddressDto | null;
  suitabilityInformation?: SuitabilityInformationDto | null;
  trustedContact?: TrustedContactDto | null;
  financialInformation?: FinancialInformationDto | null;
  disclosure?: DisclosureDto | null;
}

export interface AccountDto {
  id: string;
  accountId?: number;
  accountNumber?: string;
  status?: AccountStatusDto;
  type: AccountTypeDto;
  primaryAccountHolder: AccountHolderDto;
  createdAt: string;
  updatedAt?: string;
}

export interface GetAccountParamsDto {
  accountIdentifier: string;
}

export interface GetAccountDto {
  params: GetAccountParamsDto;
}
