export enum OfferDocumentTypeDto {
  OFFERING_DOCUMENT = 'offering_document',
  RISK_FACTORS = 'risk_factors',
  OFFERING_SUMMARY = 'offering_summary',
  INVESTOR_PRESENTATION = 'investor_presentation',
  SUBSCRIPTION_AGREEMENT = 'subscription_agreement',
  CIRCULAR_OFFERING_DOCUMENT = 'circular_offering_document',
}

export interface OfferDocumentDto {
  id: string;
  offerId: string;
  type: OfferDocumentTypeDto;
  url: string;
  createdAt: string;
  updatedAt?: string;
}

export interface BaseOfferDocumentParamsDto {
  id: string;
}

export interface CreateOfferDocumentBodyDto {
  offerId: string;
  type: OfferDocumentTypeDto;
  url: string;
}

export interface CreateOfferDocumentDto {
  body: CreateOfferDocumentBodyDto;
}

export interface GetOfferDocumentListQueryParamsDto {
  offerId: string;
}

export interface GetOfferDocumentListDto {
  queryParams?: GetOfferDocumentListQueryParamsDto;
}

export interface GetOfferDocumentDto {
  params: BaseOfferDocumentParamsDto;
}

export interface PatchOfferDocumentBodyDto extends Partial<Omit<CreateOfferDocumentBodyDto, 'offerId'>> {}

export interface PatchOfferDocumentDto {
  params: BaseOfferDocumentParamsDto;
  body: PatchOfferDocumentBodyDto;
}

export interface DeleteOfferDocumentDto {
  params: BaseOfferDocumentParamsDto;
}

export interface CreateOfferDocumentUploadLinkBodyDto {
  filename: string;
}

export interface CreateOfferDocumentUploadLinkDto {
  body: CreateOfferDocumentUploadLinkBodyDto;
}

export interface UploadOfferDocumentBodyDto {
  filename: string;
  rawFile: File;
}

export interface UploadOfferDocumentDto {
  body: UploadOfferDocumentBodyDto;
}
