import { AutocompleteInput, Filter, ReferenceInput, SelectArrayInput } from 'react-admin';
import { OfferOrderStatusDto } from 'src/dtos';

import { OfferOrderStatusDtoTag } from '../Fields/OfferOrderStatusDtoTag';

const statusOptions = Object.keys(OfferOrderStatusDto)?.map((key: string) => ({ id: key, name: key }));

export const OfferOrderListFilters = (props: any) => (
  <Filter {...props}>
    <ReferenceInput source='offerId' reference='offers' sort={{ field: 'name', order: 'ASC' }} alwaysOn>
      <AutocompleteInput
        label='Offer'
        optionText='name'
        fullWidth
        filterToQuery={searchText => ({ name: searchText })}
      />
    </ReferenceInput>
    <SelectArrayInput
      label='Status'
      source='status'
      fullWidth
      choices={statusOptions}
      alwaysOn
      optionText={record => <OfferOrderStatusDtoTag value={record.name} />}
    />
  </Filter>
);
