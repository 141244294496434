import React, { Fragment, useState } from 'react';

import { Grid } from '@mui/material';
import { get } from 'lodash';
import {
  ArrayInput,
  BooleanInput,
  FormDataConsumer,
  FunctionField,
  NumberField,
  NumberInput,
  SimpleFormIterator,
  TextField,
} from 'react-admin';

import Styles from '../../../../constants/styles';
import { OfferOrderStatusDto } from '../../../../dtos/offerOrders.dtos';
import { OfferOrderStatusDtoTag } from '../../../Fields/OfferOrderStatusDtoTag';

interface AccumulatedInvestmentInputProps {
  record: any;
  isEditOrCreate?: boolean;
}

const AccumulatedInvestmentInput = ({ record, isEditOrCreate = true }: AccumulatedInvestmentInputProps) => {
  const [summationOfFunnel, setSummationOfFunnel] = useState<number>(0);

  React.useEffect(() => {
    if (record) {
      if (record._ordersSummation?.custom) return setSummationOfFunnel(Number(record._ordersSummation.custom));

      return calculateFunnelSummation(record);
    }
  }, [record]);

  const calculateFunnelSummation = (formData: any) => {
    setSummationOfFunnel(
      formData._ordersSummation
        ?.filter((item: any) => item?._includeInSummation)
        ?.reduce((acc: number, item: any) => acc + (item?.sumRaisedInvestment || 0), 0),
    );
  };

  const resetSelectedStatus = (formData: any) => {
    formData._ordersSummation = Object.keys(OfferOrderStatusDto).map(key => {
      return { status: key, totalOfferOrders: 0, sumRaisedInvestment: 0, _includeInSummation: false };
    });
  };

  const onInputChange = (value: boolean, formData: any) => {
    setSummationOfFunnel(formData?._ordersSummation?.custom ? Number(formData._ordersSummation.custom) : 0);
    if (value) return resetSelectedStatus(formData);

    return calculateFunnelSummation(formData);
  };

  const onCustomValueChange = (value: string) => {
    setSummationOfFunnel(Number(value));
  };

  return (
    <Fragment>
      <Grid item xs={12}>
        <h3>Accumulated Investments</h3>
      </Grid>
      <Grid item xs={12} style={Styles.offer.closePannelWrapper}>
        {isEditOrCreate && (
          <Fragment>
            <ArrayInput source='_ordersSummation' label={false}>
              <SimpleFormIterator
                inline
                disableRemove
                disableReordering
                disableAdd
                fullWidth
                sx={{
                  '& .RaSimpleFormIterator-list': {
                    listStyleType: 'none',
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    '.RaSimpleFormIterator-line': {
                      flex: '0 0 100%',
                      alignItems: 'flex-end',
                      '.RaSimpleFormIterator-inline': {
                        alignItems: 'center',
                        '> *': {
                          flex: '1 1 0',
                          width: '0',
                        },
                        '> .MuiFormGroup-root': {
                          '> p': {
                            display: 'none',
                          },
                        },
                        '> p': {
                          display: 'none',
                        },
                      },
                    },
                  },
                }}>
                <FormDataConsumer>
                  {({ scopedFormData, getSource }) => {
                    const source = getSource?.('status');

                    return (
                      scopedFormData && (
                        <FunctionField
                          render={(record: any) => {
                            if (!source) {
                              return '';
                            }
                            const status = get(record, source);

                            if (!status) {
                              return '';
                            }

                            return <OfferOrderStatusDtoTag value={status} />;
                          }}
                        />
                      )
                    );
                  }}
                </FormDataConsumer>

                <TextField source='totalOfferOrders'></TextField>
                <NumberField
                  label='Sum of Investments'
                  source='sumRaisedInvestment'
                  fullWidth
                  options={{ style: 'currency', currency: 'USD' }}
                />
                <FormDataConsumer>
                  {({ formData, scopedFormData, getSource, ...rest }) =>
                    scopedFormData && (
                      <BooleanInput
                        {...rest}
                        source={getSource?.('_includeInSummation') || ''}
                        label='Include In Summation'
                        fullWidth
                        onInput={() => calculateFunnelSummation(formData)}
                        disabled={formData._ordersSummation.useCustom}
                      />
                    )
                  }
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={9}>
                <FormDataConsumer>
                  {({ formData, ...rest }) => (
                    <NumberInput
                      {...rest}
                      source='_ordersSummation.custom'
                      style={{ width: '100%' }}
                      label='Custom Accumulated Value'
                      options={{ style: 'currency', currency: 'USD' }}
                      disabled={
                        !formData._ordersSummation ||
                        !(formData._ordersSummation && formData._ordersSummation.useCustom)
                      }
                      onInput={(event: any) => onCustomValueChange(event?.target?.value || '0')}
                    />
                  )}
                </FormDataConsumer>
              </Grid>
              <Grid item xs={3}>
                <FormDataConsumer>
                  {({ formData, ...rest }) => (
                    <BooleanInput
                      {...rest}
                      source='_ordersSummation.useCustom'
                      label='Override with Custom Accumulated Value'
                      onInput={(event: any) => onInputChange(event?.target?.checked || false, formData)}
                    />
                  )}
                </FormDataConsumer>
              </Grid>
              <Grid item xs={6}>
                <h4>Summation that will be displayed in Funnel:</h4>
              </Grid>
              <Grid item xs={6}>
                <h4>{summationOfFunnel?.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</h4>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  );
};

export default AccumulatedInvestmentInput;
