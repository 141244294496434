import { AccountBalanceDetailsDto, AccountBalanceDto } from '../dtos/accountBalances.dtos';
import { AccountBalance, AccountBalanceDetails } from '../models/accountBalances.models';

export const findCashAvailable = (dto: AccountBalanceDto | AccountBalanceDetailsDto): number =>
  Number((dto.availableFunds - dto.pendingOfferOrdersTotal + dto.pendingExternalOfferOrdersTotal).toFixed(2));

export const mapAccountBalanceDetailsDtoToModel = (dto: AccountBalanceDetailsDto): AccountBalanceDetails => ({
  ...dto,
  cashAvailable: findCashAvailable(dto),
  cashAvailableForWithdrawal: Number(
    (dto.availableFundsForWithdrawal - dto.pendingOfferOrdersTotal + dto.pendingExternalOfferOrdersTotal).toFixed(2),
  ),
  id: dto.accountId.toString(),
});

export const mapAccountBalanceDtoToModel = (dto: AccountBalanceDto): AccountBalance => ({
  ...dto,
  cashAvailable: findCashAvailable(dto),
  id: dto.accountId.toString(),
});
