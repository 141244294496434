export const annualIncomeChoices = [
  { id: 'lessThan25K', name: '$0 to $25,000' },
  { id: 'between25KAnd50K', name: '$25,001 to $50,000' },
  { id: 'between50KAnd100K', name: '$50,000 to 100,000' },
  { id: 'between100KAnd200K', name: '$100,000 to $200,000' },
  { id: 'between200KAnd300K', name: '$200,000 to $300,000' },
  { id: 'between300KAnd500K', name: '$300,000 to $500,000' },
  { id: 'between500KAnd1Point2M', name: '$500,000 to $1,000,000' },
  { id: 'moreThan1Point2M', name: 'Greater than $1,200,000' },
];

export const totalNetWorthChoices = [
  { id: 'lessThan50K', name: '$0 to $50,000' },
  { id: 'between50KAnd100K', name: '$50,000 to $100,000' },
  { id: 'between100KAnd200K', name: '$100,000 to $200,000' },
  { id: 'between200KAnd500K', name: '$200,000 to $500,000' },
  { id: 'between500KAnd1M', name: '$500,000 to $1,000,000' },
  { id: 'between1MAnd5M', name: '$1,000,000 to $5,000,00' },
  { id: 'moreThan5M', name: 'Greater than $5.000,000' },
];

export const liquidityNetWorthChoices = [
  { id: 'lessThan50K', name: '$0 to $50,000' },
  { id: 'between50KAnd100K', name: '$50,000 to $100,000' },
  { id: 'between100KAnd200K', name: '$100,000 to $200,000' },
  { id: 'between200KAnd500K', name: '$200,000 to $500,000' },
  { id: 'between500KAnd1M', name: '$500,000 to $1,000,000' },
  { id: 'between1MAnd5M', name: '$1,000,000 to $5,000,000' },
  { id: 'moreThan5M', name: 'Greater than $5.000,000' },
];

export const taxBracketChoices = [
  { id: 'bottom', name: 'Bottom' },
  { id: 'middle', name: 'Middle' },
  { id: 'top', name: 'Top' },
];

export const employmentStatusChoices = [
  { id: 'employed', name: 'Employed' },
  { id: 'retired', name: 'Retired' },
  { id: 'student', name: 'Student' },
  { id: 'unemployed', name: 'Unemployed' },
];

export const yearsEmployedChoices = [
  { id: 'betweenZeroAndOne', name: 'Less than 1 year' },
  { id: 'betweenTwoAndFive', name: '2 to 5 years' },
  { id: 'betweenFiveAndTen', name: '5 to 10 years' },
  { id: 'betweenTenAndTwenty', name: '10 to 20 years' },
  { id: 'moreThanTwenty', name: 'More than 20 years' },
];
