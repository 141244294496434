import { Grid } from '@mui/material';
import { SimpleForm, TextInput, required, NumberInput } from 'react-admin';

interface PrivateQuoteCreateEditFormProps {
  isEditMode?: boolean;
}

export const PrivateQuoteCreateEditForm = ({ isEditMode = false }: PrivateQuoteCreateEditFormProps) => {
  return (
    <SimpleForm>
      <Grid container direction='column' spacing={2} justifyContent='space-between'>
        <Grid item xs={12}>
          <h2>{isEditMode ? 'Edit' : 'Add'} Private Quote</h2>
        </Grid>
        <Grid item xs={10} sm={5}>
          <TextInput label='Symbol/Ticker' source='symbol' validate={[required()]} fullWidth />
        </Grid>
        <Grid item xs={10} sm={5}>
          <TextInput label='Name' source='name' validate={[required()]} fullWidth />
        </Grid>
        <Grid item xs={6} sm={2}>
          <NumberInput
            label='Purchase Price'
            source='purchasePrice'
            validate={[required()]}
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <NumberInput
            label='Current Price'
            source='currentPrice'
            validate={[required()]}
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  );
};
