import { ChangeEvent, useCallback, useEffect, useState } from 'react';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Dialog,
  TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import jsonExport from 'jsonexport/dist';
import _ from 'lodash';
import { Button, downloadCSV } from 'react-admin';
import { Combine, DraggableLocation, DropResult } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';

import DragAndDropArea from '../DragAndDropArea/DragAndDropArea';
import { DragDropElementGroup, DragDropElementTree } from './models/export.models';

interface ExportModalProps<T> {
  isOpen: boolean;
  onClose: () => void;
  modelFields: DragDropElementTree[];
  onExport: (pageNumber: number, pageSize: number) => Promise<T[]>;
  isLoading?: boolean;
  total?: number;
  filename: string;
}

export function ExportModal<T extends object>({
  isOpen,
  onClose,
  modelFields,
  onExport,
  total = 0,
  isLoading = false,
  filename = 'Export',
}: ExportModalProps<T>) {
  const [sortedFields, updateSortedFields] = useState<DragDropElementTree[]>(JSON.parse(JSON.stringify(modelFields)));
  const [areAllFieldsSelected, setAreAllFieldsSelected] = useState(false);
  const [inputs, setInputs] = useState({
    pageNumber: 1,
    pageSize: total,
    rowsPerPage: 0,
    allRows: true,
    isDisabledMaxRowsPerPage: true,
  });
  const [errors, setErrors] = useState<{ pageNumber?: string; pageSize?: string; rowsPerPage?: string }>({});

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.source || (!result.destination && !result.combine)) return;
    const { source, destination, combine, draggableId } = result;
    const fields = Array.from(sortedFields);

    if (draggableId && combine) combineElements(fields, draggableId, combine);
    else if (source.droppableId === destination?.droppableId) switchElementsOrder(fields, source, destination);

    updateSortedFields(fields);
  };

  const combineElements = (fields: DragDropElementTree[], draggableId: string, combine: Combine) => {
    let parentDraggedItem: DragDropElementTree[] | null = findSelectedParent(fields, draggableId);
    let parentCombineItem: DragDropElementTree[] | null = findSelectedParent(fields, combine.draggableId);
    if (!parentDraggedItem || !parentCombineItem) return;

    let draggedItemIndex = parentDraggedItem?.findIndex(item => item.id === draggableId);
    const [draggedItem] = parentDraggedItem.splice(draggedItemIndex, 1);

    let combineItemIndex = parentCombineItem?.findIndex(item => item.id === combine.draggableId);
    const [combineItem] = parentCombineItem.splice(combineItemIndex, 1);

    let newCombineItem: DragDropElementTree = { id: uuid() };
    if (combineItem.children)
      newCombineItem = {
        ...newCombineItem,
        children: [...combineItem.children, { ...draggedItem }],
      };
    else
      newCombineItem = {
        ...newCombineItem,
        children: [{ ...combineItem }, { ...draggedItem }],
      };

    parentCombineItem.splice(combineItemIndex || 0, 0, newCombineItem);
  };

  const switchElementsOrder = (
    fields: DragDropElementTree[],
    source: DraggableLocation,
    destination: DraggableLocation,
  ) => {
    const sourcePath = source.droppableId.split('.');
    sourcePath.shift();
    const destiantionPath = destination.droppableId.split('.');
    destiantionPath.shift();

    const selectedGroup = findSelectedGroup(fields, sourcePath);
    const [reorderedItem] = selectedGroup.splice(source.index, 1);
    selectedGroup.splice(destination?.index || 0, 0, reorderedItem);
  };

  const findSelectedGroup = (fields: DragDropElementTree[], path: string[]): DragDropElementTree[] => {
    if (path.length === 0) return fields;
    const selectedPath = path.shift();
    const selectedGroup: DragDropElementTree | undefined = fields.find(
      (item: DragDropElementTree) => item.id === selectedPath,
    );

    return findSelectedGroup(selectedGroup?.children || fields, path);
  };

  const findSelectedParent = (fields: DragDropElementTree[], id: string): DragDropElementTree[] | null => {
    let output: DragDropElementTree[] | null = null;

    fields.some((field: DragDropElementTree) => {
      if (field.id === id) {
        output = fields;

        return true;
      } else if (field?.children) {
        const foundValue = findSelectedParent(field.children, id);
        output = foundValue;
        if (foundValue) return true;
      }

      return false;
    });

    return output;
  };

  const findSelectedField = (fields: DragDropElementTree[], id: string): DragDropElementTree | null => {
    let result: DragDropElementTree | null = null;

    for (const field of fields) {
      if (result) {
        break;
      }

      if (field.id === id) {
        result = field;
        break;
      }

      if (field.children) {
        result = findSelectedField(field.children, id);
      }
    }

    return result;
  };

  const findAreAllFieldsSelected = (fields: DragDropElementTree[]): boolean => {
    return fields.every((field: DragDropElementTree) => {
      if (field?.children) return findAreAllFieldsSelected(field.children);
      if (field.isSelected) return true;

      return false;
    });
  };

  const selectAll = (fields: DragDropElementTree[], isChecked: boolean) => {
    fields.forEach(field => {
      field.isSelected = isChecked;
      if (field.children) selectAll(field.children, isChecked);
    });
  };

  const onChangeValue = useCallback(
    (itemId: string) => {
      const fields = Array.from(sortedFields);
      const item = findSelectedField(fields, itemId);
      if (!item) return;
      item.isSelected = !item?.isSelected;
      updateSortedFields(fields);
    },
    [sortedFields, updateSortedFields],
  );

  const getSelectedFields = useCallback(() => {
    const selectedFields: { id: string; groupId?: string }[] = [];

    const getSelectedFieldsRecursive = (fields: DragDropElementTree[], groupId?: string) => {
      fields.forEach(field => {
        if (field.isSelected) selectedFields.push({ id: field.id, groupId });
        if (field.children) getSelectedFieldsRecursive(field.children, groupId || field.id);
      });
    };

    getSelectedFieldsRecursive(sortedFields);

    return selectedFields;
  }, [sortedFields]);

  const onCheckAll = useCallback(() => {
    const fields = Array.from(sortedFields);
    selectAll(fields, !areAllFieldsSelected);
    updateSortedFields(fields);
  }, [updateSortedFields, sortedFields, areAllFieldsSelected]);

  const validatePageNumber = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;
    const pageNumber = Number(value);
    if (value === '')
      return setErrors(previousErrors => ({ ...previousErrors, pageNumber: 'The page number is required' }));
    if (isNaN(pageNumber))
      return setErrors(previousErrors => ({ ...previousErrors, pageNumber: 'The page number must be a number' }));
    if (pageNumber <= 0)
      return setErrors(previousErrors => ({ ...previousErrors, pageNumber: 'The page number must be greater than 0' }));

    const maxNumberOfPages = total / inputs.pageSize + (total % inputs.pageSize > 0 ? 1 : 0);
    if (pageNumber > parseInt(maxNumberOfPages.toString(), 10))
      return setErrors(previousErrors => ({
        ...previousErrors,
        pageNumber: `The maximum number of pages is ${parseInt(maxNumberOfPages.toString(), 10)}`,
      }));

    return setErrors(previousErrors => ({
      ...previousErrors,
      pageNumber: undefined,
      rowsPerPage: undefined,
      pageSize: undefined,
    }));
  };

  const validatePageSize = (event: any) => {
    const { value } = event.target;
    const pageSize = Number(value);

    if (value === '')
      return setErrors(previousErrors => ({ ...previousErrors, pageSize: 'The rows per page is required' }));
    if (isNaN(pageSize))
      return setErrors(previousErrors => ({ ...previousErrors, pageSize: 'The rows per page must be a number' }));
    if (pageSize <= 0)
      return setErrors(previousErrors => ({ ...previousErrors, pageSize: 'The rows per page must be greater than 0' }));
    if (pageSize > total)
      return setErrors(previousErrors => ({
        ...previousErrors,
        pageSize: `The maximum rows per page value cannot be greater than the total number of entries ${total}`,
      }));

    return setErrors(previousErrors => ({
      ...previousErrors,
      pageNumber: undefined,
      rowsPerPage: undefined,
      pageSize: undefined,
    }));
  };

  const onPageNumberChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageNumber = Number(event.target.value);

    if (!isNaN(pageNumber)) {
      const availableTotal = total - (pageNumber - 1) * inputs.pageSize;
      const rowsPerPage = availableTotal > inputs.pageSize ? inputs.pageSize : availableTotal;
      setInputs(previousInput => ({ ...previousInput, pageNumber, rowsPerPage }));
    }

    validatePageNumber(event);
  };

  const onPageSizeChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const pageSize = Number(event.target.value);

    if (!isNaN(pageSize)) {
      const availableTotal = total - (inputs.pageNumber - 1) * pageSize;
      const rowsPerPage = availableTotal > pageSize ? pageSize : availableTotal;
      setInputs(previousInputs => ({ ...previousInputs, pageSize, rowsPerPage }));
    }
    validatePageSize(event);
  };

  const onMaxRowsPerPageToggle = () => {
    return setInputs(previousInputs => ({
      ...previousInputs,
      isDisabledMaxRowsPerPage: !inputs.isDisabledMaxRowsPerPage,
    }));
  };

  const onFetchAllToggle = () => {
    return setInputs(previousInputs => ({
      ...previousInputs,
      allRows: !previousInputs.allRows,
      pageSize: previousInputs.allRows ? 100 : total,
      pageNumber: 1,
      rowsPerPage: previousInputs.allRows ? 100 : 0,
      isDisabledMaxRowsPerPage: true,
    }));
  };

  const groupDataByFields = (exportData: T[], fields: DragDropElementGroup[]) => {
    const exportDataFieldsWithGroup: { [key: string]: T[keyof T] | string }[] = exportData.map((item: T) => {
      const newItem: { [key: string]: T[keyof T] | string } = {};
      Object.keys(item).forEach(key => {
        const { id, groupId } = fields.find(f => f.id === key) || {};

        if (groupId) {
          const groupFields = fields.filter(f => f.groupId === groupId);
          const groupValues = groupFields.map(gf => item[gf.id as keyof typeof item]);
          const groupedKey = groupFields.map(gf => gf.id).join(' + ');
          newItem[groupedKey] = groupValues.join(' ');
        } else if (id) {
          newItem[key] = item[key as keyof typeof item];
        }
      });

      return newItem;
    });

    const groupFields: DragDropElementGroup[][] = _.chain(fields)
      .groupBy((item: DragDropElementGroup) => item.groupId || item.id)
      .sortBy((group: DragDropElementGroup[]) => fields.indexOf(group[0]))
      .value();

    const selectedGroupFields = groupFields
      .map((group: DragDropElementGroup[]) => {
        const hasAnyGroupId = group.some(item => Boolean(item.groupId));
        let item: string;

        if (hasAnyGroupId) {
          item = group
            .filter((item: any) => Boolean(item.groupId))
            .map((item: any) => item.id)
            .join(' + ');
        } else {
          item = group.map((item: any) => item.id).join('');
        }

        return item;
      })
      .flat();

    const reorderedExportData: { [key: string]: T[keyof T] | string }[] = exportDataFieldsWithGroup.map(
      (item: { [key: string]: T[keyof T] | string }) => {
        const ordered: { [key: string]: T[keyof T] | string } = {};
        selectedGroupFields.forEach((key: string) => (ordered[key] = item[key as keyof typeof item]));

        return ordered;
      },
    );

    return reorderedExportData;
  };

  const _onExport = useCallback(
    async (fields: { id: string; groupId?: string }[], pageNumber: number, pageSize: number) => {
      const data = await onExport(pageNumber, pageSize);
      const exportDataFieldsWithGroup = groupDataByFields(data, fields);

      jsonExport(exportDataFieldsWithGroup, (err, csv) => {
        if (err) alert(err);
        downloadCSV(csv, filename);
      });
    },
    [onExport],
  );

  const reset = () => {
    updateSortedFields(JSON.parse(JSON.stringify(modelFields)));
  };

  const _onClose = () => {
    onClose();
    reset();
  };

  useEffect(() => {
    setAreAllFieldsSelected(findAreAllFieldsSelected(sortedFields));
  }, [sortedFields]);

  return (
    <Dialog open={isOpen} maxWidth='md' fullWidth onClose={_onClose}>
      <DialogTitle id='alert-dialog-title'>{'Export Options'}</DialogTitle>
      <DialogContent dividers>
        <Grid container direction='column' alignItems='center' justifyContent='space-between'>
          <Grid item container direction='row' justifyContent='space-between' alignItems='center'>
            <Grid item xs='auto'>
              <Typography>Select the fields that you want to display on the exported file</Typography>
            </Grid>
            <Grid item xs='auto'>
              <Button label={areAllFieldsSelected ? 'Uncheck All' : 'Check All'} onClick={onCheckAll} />
            </Grid>
          </Grid>
          <DragAndDropArea items={sortedFields} handleOnDragEnd={handleOnDragEnd} onChangeValue={onChangeValue} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container direction='column'>
          <Grid
            item
            container
            direction='row'
            justifyContent='space-between'
            alignItems='top'
            spacing={2}
            columnSpacing={12}>
            <Grid item xs={5} ml={'1rem'}>
              <TextField
                size='small'
                autoFocus
                name='pageNumberTextBox'
                id='pageNumberTextBox'
                defaultValue='1'
                label='Page Number'
                variant='standard'
                required
                margin='dense'
                fullWidth
                type='number'
                disabled={isLoading || inputs.allRows || total <= inputs.pageSize || !inputs.isDisabledMaxRowsPerPage}
                onChange={onPageNumberChange}
                helperText={errors.pageNumber}
                error={Boolean(errors.pageNumber)}
              />
            </Grid>
            <Grid item xs={5} mr={'1rem'}>
              <TextField
                size='small'
                autoFocus
                defaultValue={total}
                name='maxRowsPerPageTextBox'
                id='maxRowsPerPageTextBox'
                label='Max Rows Per Page'
                variant='standard'
                required
                margin='dense'
                fullWidth
                type='number'
                disabled={isLoading || inputs.allRows || inputs.isDisabledMaxRowsPerPage}
                onChange={onPageSizeChange}
                helperText={errors.pageSize}
                error={Boolean(errors.pageSize)}
              />
            </Grid>
            <Grid item xs={5} ml={'1rem'}>
              <TextField
                autoFocus
                size='small'
                value={inputs.rowsPerPage}
                label='Rows x Page'
                name='rowsPerPageTextBox'
                id='rowsPerPageTextBox'
                variant='standard'
                required
                margin='dense'
                fullWidth
                type='number'
                disabled
              />
            </Grid>
            <Grid item xs={5} mr={'1rem'}>
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    defaultChecked
                    name='unlockMaxRowsPerPage'
                    onChange={onMaxRowsPerPageToggle}
                    disabled={isLoading}
                  />
                }
                label={inputs.isDisabledMaxRowsPerPage ? 'Unlock Value' : 'Lock Value'}
              />
            </Grid>
            <Grid item xs={6} ml={'1rem'}>
              <FormControlLabel
                control={
                  <Checkbox
                    size='small'
                    defaultChecked
                    name='fetchAllData'
                    onChange={onFetchAllToggle}
                    disabled={isLoading}
                  />
                }
                label='Fetch All Data'
              />
            </Grid>
          </Grid>
          <Grid container direction='row'>
            <Grid item xs={6} container direction='row' justifyContent='flex-start' alignItems='center' spacing={2}>
              {isLoading && (
                <>
                  <Grid item xs='auto' ml={'1rem'}>
                    <CircularProgress size={'1rem'} />
                  </Grid>
                  <Grid item xs='auto'>
                    <Typography variant='caption' color='textSecondary'>
                      Processing Data...
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={6} container direction='row' justifyContent='flex-end' alignItems='center' spacing={2}>
              <Grid item xs='auto'>
                <Button variant='contained' label='Cancel' color='secondary' size='medium' onClick={_onClose} />
              </Grid>
              <Grid item xs='auto' mr={'1rem'}>
                <Button
                  variant='contained'
                  color='primary'
                  label='Export'
                  size='medium'
                  disabled={isLoading || !getSelectedFields().length}
                  onClick={() => _onExport(getSelectedFields(), inputs.pageNumber, inputs.pageSize)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
export default ExportModal;
