import StyleIcon from '@mui/icons-material/Style';

import OfferOptionCreate from './OfferOptionCreate';
import OfferOptionEdit from './OfferOptionEdit';
import OfferOptionList from './OfferOptionList';

const OfferOptions = {
  list: OfferOptionList,
  edit: OfferOptionEdit,
  create: OfferOptionCreate,
  icon: StyleIcon,
};

export default OfferOptions;
