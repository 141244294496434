import * as React from 'react';

import { Datagrid, List, SelectField, FunctionField } from 'react-admin';

import { configOptions } from '../../constants/choices/config.choices';

const defaultSort = { field: 'configType', order: 'ASC' };

const ConfigList = () => (
  <List title='List of Configurations' sort={defaultSort}>
    <Datagrid rowClick='show' bulkActionButtons={false}>
      <SelectField source='configType' choices={configOptions} label='Configuration Type' />
      <FunctionField
        source='appConfig'
        label='Application Configuration '
        render={(item: any) => JSON.stringify(item.appConfig, null, 2)}
        sx={{ fontFamily: 'monospace', background: '#eee' }}
      />
    </Datagrid>
  </List>
);

export default ConfigList;
