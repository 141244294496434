import * as React from 'react';

import { Create } from 'react-admin';

import SimpleCreateEditForm from './SimpleCreateEditForm';

export const OfferDocumentCreate = () => (
  <Create redirect='list'>
    <SimpleCreateEditForm />
  </Create>
);

export default OfferDocumentCreate;
