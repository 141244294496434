import PeopleIcon from '@mui/icons-material/PeopleOutline';

import List from './List';

const PreExistingUser = {
  List,
  Icon: PeopleIcon,
};

export default PreExistingUser;
