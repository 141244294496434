export namespace QueryParamConstant {
  export const SKIP_PAGINATION = -1;
  export const SKIP_SORT = 'skip';
}

export namespace DateConstant {
  export const DEFAULT_FORMAT = 'yyyy-MM-dd';
  export const US_FORMAT = 'MM/dd/yyyy';
}

export enum DataProviderResource {
  AccountAgreements = 'AccountAgreements',
  AccountSyncStatusList = 'AccountSyncStatusList',
  NewOfferCardPaymentAccountLink = 'NewOfferCardPaymentAccountLink',
  OfferOrders = 'offer-orders',
  OfferOrdersAggregate = 'OfferOrdersAggregate',
  OfferOrdersStatus = 'OfferOrdersStatus',
  OfferOrderCardPayments = 'OfferOrderCardPayments',
  OfferOrderRefunds = 'OfferOrderRefunds',
  Transfers = 'Transfers',
  Orders = 'Orders',
}

export namespace ConfigConstant {
  export const AUTH_TOKEN_KEY = 'authToken';
}
