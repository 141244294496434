import { useEffect, useState } from 'react';

import _ from 'lodash';

export interface UsePaginationProps<T> {
  page?: number;
  perPage?: number;
  data?: T[];
}

export interface UsePaginationReturnType<T> {
  dataPage: T[];
  setData: (data: T[]) => void;
  page: number;
  setPage: (page: number) => void;
  perPage: number;
  setPerPage: (perPage: number) => void;
}

const PER_PAGE_DEFAULT = 10;
const SELECTED_PAGE_DEFAULT = 1;

export function usePagination<T>({
  perPage = PER_PAGE_DEFAULT,
  page = SELECTED_PAGE_DEFAULT,
  data = [],
}: UsePaginationProps<T>): UsePaginationReturnType<T> {
  const [_perPage, _setPerPage] = useState<number>(perPage);
  const [_selectedPage, _setSelectedPage] = useState<number>(page);
  const [_data, _setData] = useState<T[]>(data);
  const [_paginatedData, _setPaginatedData] = useState<T[]>([]);

  const setPage = (page: number) => {
    _setSelectedPage(page);
  };

  const setPerPage = (value: number) => {
    _setPerPage(value);
  };

  const setData = (data: T[]) => {
    _setData(data);
  };

  useEffect(() => {
    const dataPage = _.chunk(_data, _perPage)[_selectedPage - 1];
    _setPaginatedData(dataPage);
  }, [_selectedPage, _perPage, _data]);

  return {
    dataPage: _paginatedData,
    setData,
    page: _selectedPage,
    setPage,
    perPage: _perPage,
    setPerPage,
  };
}
