import { SnapQuoteUrlConstant } from 'src/constants';
import { GetSnapQuoteListDto, GetSnapQuoteListResponseDto } from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class SnapQuotesApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async list({ params }: GetSnapQuoteListDto): Promise<GetSnapQuoteListResponseDto> {
    const url = replacePlaceholders(SnapQuoteUrlConstant.LIST, params);

    return httpRequest<GetSnapQuoteListResponseDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }
}
