import * as React from 'react';

import { useRecordContext } from 'react-admin';

import Styles from '../../constants/styles';

const getStatus = (status: string, style: any = {}) => {
  return (
    <div className={'status'} style={style}>
      {status.replace(/([a-z])([A-Z])/g, '$1 $2').toUpperCase()}
    </div>
  );
};

//props are not necessary thus props interface can vary
export const StatusField = (props: any = {}) => {
  const record = useRecordContext(props);
  const status: string = record ? record?.[props?.source] ?? props?.status : null;

  const successCases: string[] = props.success;
  const failCases: string[] = props.fail;
  const warningCases: string[] = props.warning;

  const nullOption = props.nullOption;
  const nullOptionType = props.nullOptionType;

  if (!status) {
    return <></>;
  }

  if (successCases?.includes(status)) return getStatus(status, Styles.user.approvedStatus);
  else if (failCases?.includes(status)) return getStatus(status, Styles.user.rejectedStatus);
  else if (warningCases?.includes(status)) return getStatus(status, Styles.user.pendingStatus);
  else {
    if (nullOption && nullOptionType) {
      if (nullOptionType === 'success') return getStatus(nullOption, Styles.user.approvedStatus);
      else if (nullOptionType === 'fail') return getStatus(nullOption, Styles.user.rejectedStatus);
      else if (nullOptionType === 'warning') return getStatus(nullOption, Styles.user.pendingStatus);
    }

    return getStatus(status, Styles.user.pendingStatus);
  }
};
export default StatusField;
