import { Dispatch, SetStateAction } from 'react';

import { DecisionTypes } from 'src/constants/choices/ach.transfers.choices';
import { SearchTransferItem } from 'src/models';

import ACHTransfer from '.';

export interface ActionModalProps {
  curElement: SearchTransferItem;
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  type: DecisionTypes;
}

const ActionModals = (props: ActionModalProps) => {
  const { curElement, modalOpen, setModalOpen, type } = props;

  return (
    <>
      <ACHTransfer.DecideTransfer
        element={curElement}
        type={type}
        openModal={modalOpen}
        modalClosed={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};

export default ActionModals;
