import { decodeToken } from 'react-jwt';
import { ConfigConstant } from 'src/constants';
import { getClientApi } from 'src/data-communication';
import { LoginUserBodyDto } from 'src/dtos';

import { ADMIN_LEVELS } from '../constants/adminLevels';

const clientApi = getClientApi();

const setAuthToken = (token: string) => {
  localStorage.setItem(ConfigConstant.AUTH_TOKEN_KEY, token);

  return Promise.resolve();
};

export const getAuthToken = () => localStorage.getItem(ConfigConstant.AUTH_TOKEN_KEY);

const checkError = (error: any): Promise<any> => {
  const { status } = error;

  if (status === 401 || status === 403) {
    localStorage.removeItem(ConfigConstant.AUTH_TOKEN_KEY);

    return Promise.reject();
  }

  return Promise.resolve();
};

const checkAuth = (): Promise<any> =>
  localStorage.getItem(ConfigConstant.AUTH_TOKEN_KEY) ? Promise.resolve() : Promise.reject({ message: false });

const getIdentity = (): Promise<any> => Promise.resolve();

const getPermissions = (): Promise<any> => Promise.resolve();

const login = async ({ email, password }: LoginUserBodyDto): Promise<any> => {
  const response = await clientApi.users.login({ body: { email, password } });

  const { accessToken, idToken, tbToken } = response;
  const newToken = accessToken + '; idToken ' + idToken + '; tbToken ' + tbToken;
  const decodedToken: any = decodeToken(accessToken);

  if (!decodedToken) return false;

  const group: string[] = decodedToken['cognito:groups'] ?? [];

  if (group.length === 0 || !group.find(item => Object.keys(ADMIN_LEVELS).includes(item))) {
    return Promise.reject({ message: 'User not an admin!' });
  }

  return setAuthToken(newToken);
};

const logout = (): Promise<any> => {
  localStorage.removeItem(ConfigConstant.AUTH_TOKEN_KEY);

  return Promise.resolve();
};

const AuthProvider = {
  /** Authentication */
  login,
  checkError,
  checkAuth,
  logout,
  getIdentity,
  /** Authorization * */
  getPermissions,
};
export default AuthProvider;
