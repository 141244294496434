import axios, { AxiosResponse, AxiosRequestConfig, ResponseType, isAxiosError } from 'axios';
import { HttpError } from 'react-admin';

import { stringifyUrlQueryParams } from './stringifyUrlQueryParams';

export type HttpMethod = 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'GET';

export interface HttpRequestArgs {
  method: HttpMethod;
  url: string;
  body?: any;
  authToken?: string | null;
  queryParams?: Record<any, any>;
  headers?: Record<string, string>;
  responseType?: ResponseType;
}

interface HttpRequestConfig {
  method: HttpMethod;
  url: string;
  headers: {
    contentType: string;
  };
  data?: Record<string, unknown>;
  queryParams?: Record<any, any>;
}

const instance = axios.create();

export async function httpRequest<T>({
  method,
  url,
  body,
  authToken,
  queryParams,
  headers,
  responseType = 'json',
}: HttpRequestArgs): Promise<T> {
  if (url.search(/\{\w+\}/) >= 0) {
    throw new TypeError(`HTTP Request invalid argument: url=${url} contains a placeholder`);
  }

  try {
    const reqHeaders = {
      'Content-Type': headers?.contentType ?? 'application/json',
      Authorization: authToken ? `Bearer ${authToken}` : undefined,
    };

    const response = await instance.request<T, AxiosResponse<T, AxiosRequestConfig<HttpRequestConfig>>>({
      method,
      url,
      headers: reqHeaders,
      data: body || undefined,
      params: queryParams ?? undefined,
      paramsSerializer: params => stringifyUrlQueryParams(params),
      responseType,
    });

    return response.data;
  } catch (error: any) {
    if (isAxiosError(error)) {
      throw new HttpError(
        `Error fetching ${url}. Received: ${JSON.stringify(error.response?.data)}`,
        error.response?.status,
      );
    }
    throw error;
  }
}
