import { isEmpty } from 'lodash';
import {
  GetListParams,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { getClientApi } from 'src/data-communication';
import {
  SortDirectionTypeDto,
  GetUserListFilterByQueryParamDto,
  GetUserListQueryParamsDto,
  GetUserListSortByQueryParamTypeDto,
  UserDto,
} from 'src/dtos';
import { mapUserDtoToModel } from 'src/mappers';
import { User } from 'src/models';
import { Maybe } from 'src/utils';

const clientApi = getClientApi();

export const mapGetListParamsFilterToFilterByQueryParamDto = (filter: any): Maybe<GetUserListFilterByQueryParamDto> => {
  if (isEmpty(filter) || !filter) {
    return;
  }

  let response: GetUserListFilterByQueryParamDto = {};

  if (filter.id) {
    response.ids = [filter.id];
  }

  if (filter.accountId) {
    response.accountId = filter.accountId;
  }

  if (filter.accountNumber) {
    response.accountNumber = filter.accountNumber;
  }

  if (filter.firstName) {
    response.firstName = filter.firstName;
  }

  if (filter.lastName) {
    response.lastName = filter.lastName;
  }

  if (filter.email) {
    response.email = filter.email;
  }

  if (filter.phoneNumber) {
    response.phoneNumber = filter.phoneNumber;
  }

  if (filter.accountStatus) {
    response.accountStatus = filter.accountStatus;
  }

  if (isEmpty(response)) {
    return;
  }

  return response;
};

export const mapGetListParamsSortToSortByQueryParamTypeDto = (sort: any): GetUserListSortByQueryParamTypeDto => {
  if (sort.field === 'account.accountNumber') {
    return GetUserListSortByQueryParamTypeDto.AccountNumber;
  }

  if (sort.field === 'account.accountId') {
    return GetUserListSortByQueryParamTypeDto.AccountId;
  }

  if (sort.field === 'createdAt') {
    return GetUserListSortByQueryParamTypeDto.UserCreatedAt;
  }

  return sort.field
    ? (sort.field as GetUserListSortByQueryParamTypeDto)
    : GetUserListSortByQueryParamTypeDto.UserCreatedAt;
};

export const mapGetListSortOrderToSortDirectionQueryParamTypeDto = (sort: any): SortDirectionTypeDto => {
  return sort?.order ? sort.order.toLowerCase() : SortDirectionTypeDto.Asc;
};

export const mapGetListParamsToQueryParamsDto = (params: GetListParams): GetUserListQueryParamsDto => {
  const { pagination, sort, filter } = params;

  const filterBy = mapGetListParamsFilterToFilterByQueryParamDto(filter);
  const sortBy = mapGetListParamsSortToSortByQueryParamTypeDto(sort);
  const sortDirection = mapGetListSortOrderToSortDirectionQueryParamTypeDto(sort);

  return {
    skip: pagination.perPage && pagination.perPage * (pagination.page - 1),
    take: pagination.perPage,
    filterBy,
    sortBy,
    sortDirection,
  };
};

export const getUserList = async (params: GetListParams): Promise<GetListResult<User>> => {
  const queryParams = mapGetListParamsToQueryParamsDto(params);
  const { data, total } = await clientApi.users.list({
    queryParams,
  });

  return {
    data: data.map(mapUserDtoToModel),
    total,
  };
};

export const getManyUsers = async (params: GetManyParams): Promise<GetManyResult<UserDto>> => {
  const { ids, meta } = params;
  let queryParams: GetUserListQueryParamsDto = {};
  if (ids) queryParams = { filterBy: { ids: ids.map(anId => anId.toString()) }, take: ids?.length || 20 };
  if (meta?.unmaskTaxId) queryParams.unmaskTaxId = meta?.unmaskTaxId;

  const { data } = await clientApi.users.list({
    queryParams: isEmpty(queryParams) ? undefined : queryParams,
  });

  return {
    data,
  };
};

export const getUser = async (params: GetOneParams<User>): Promise<GetOneResult<User>> => {
  const { id } = params;
  const data = await clientApi.users.retrieve({
    params: { id },
  });

  return {
    data: mapUserDtoToModel(data),
  };
};

export const lockUser = async (params: UpdateParams): Promise<UpdateResult<{ id: string }>> => {
  const { id } = params;

  await clientApi.users.lock({ params: { id: id.toString() } });

  return {
    data: { id: id.toString() },
  };
};

export const unlockUser = async (params: UpdateParams): Promise<UpdateResult<{ id: string }>> => {
  const { id } = params;

  await clientApi.users.unlock({ params: { id: id.toString() } });

  return {
    data: { id: id.toString() },
  };
};

export const closeUser = async (params: UpdateParams): Promise<UpdateResult<{ id: string }>> => {
  const { id } = params;

  await clientApi.users.close({ params: { id: id.toString() } });

  return {
    data: { id: id.toString() },
  };
};

export const approveUser = async (params: UpdateParams): Promise<UpdateResult<{ id: string }>> => {
  const { id } = params;

  await clientApi.users.approve({ params: { id: id.toString() } });

  return {
    data: { id: id.toString() },
  };
};

export const userPostOfferCloseInvest = async (params: UpdateParams): Promise<UpdateResult<{ id: string }>> => {
  const { id, data } = params;
  const { offerIds } = data;

  await clientApi.users.postOfferCloseInvest({
    params: {
      id: id.toString(),
    },
    body: {
      offerIds,
    },
  });

  return {
    data: { id: id.toString() },
  };
};

export const enableUserMfa = async (params: UpdateParams): Promise<UpdateResult<{ id: string }>> => {
  const { id } = params;

  await clientApi.users.enableMfa({ queryParams: { id: id.toString() } });

  return {
    data: { id: id.toString() },
  };
};

export const disableUserMfa = async (params: UpdateParams): Promise<UpdateResult<{ id: string }>> => {
  const { id } = params;

  await clientApi.users.disableMfa({ queryParams: { id: id.toString() } });

  return {
    data: { id: id.toString() },
  };
};
