import { OfferDocumentTypeDto } from 'src/dtos';

export const offerDocumentsType = [
  { id: OfferDocumentTypeDto.OFFERING_DOCUMENT, name: 'Offering Document' },
  { id: OfferDocumentTypeDto.RISK_FACTORS, name: 'Risk Factors' },
  { id: OfferDocumentTypeDto.OFFERING_SUMMARY, name: 'Offering Summary' },
  { id: OfferDocumentTypeDto.INVESTOR_PRESENTATION, name: 'Investor Presentation' },
  { id: OfferDocumentTypeDto.SUBSCRIPTION_AGREEMENT, name: 'Subscription Agreement' },
  { id: OfferDocumentTypeDto.CIRCULAR_OFFERING_DOCUMENT, name: 'Circular Offering Document' },
];
