import * as React from 'react';

import { useNotify, useRefresh, useRedirect, Create } from 'react-admin';

import TabbedCreateEditForm from './TabbedCreateEditForm';

export const OfferCreate = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onError = (error: any) => {
    notify(`${error.message}`, { type: 'error' });
    redirect('/offers');
    refresh();
  };

  const onSuccess = () => {
    notify('Offer was successfully created.', {
      type: 'success',
    });
    redirect('/offers');
    refresh();
  };

  return (
    <Create redirect='list' mutationOptions={{ onError, onSuccess }}>
      <TabbedCreateEditForm />
    </Create>
  );
};

export default OfferCreate;
