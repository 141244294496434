import * as React from 'react';

import { Grid } from '@mui/material';
import { ArrayField, Datagrid, ImageField, TextField, WithRecord } from 'react-admin';

export const AssetsShow = () => (
  <Grid container direction='row' spacing={2}>
    <Grid item xs={12}>
      <h2>Add Assets Information</h2>
    </Grid>
    <Grid item xs={12}>
      <WithRecord
        render={record => {
          record.assets =
            record?.assets?.map((asset: any) => ({
              type: asset.type,
              url: asset.url || '',
              previewImageUrl:
                asset?.previewImageUrl && asset.previewImageUrl !== '' ? asset?.previewImageUrl : asset.url,
            })) || [];

          return (
            <ArrayField source='assets' label={false}>
              <Datagrid isRowSelectable={() => false}>
                <ImageField
                  source='previewImageUrl'
                  title='title'
                  sx={{ '& img': { maxWidth: '4rem', maxHeight: '4rem', objectFit: 'contain', margin: '0.25rem' } }}
                />
                <TextField source='type' />
                <TextField source='url' />
              </Datagrid>
            </ArrayField>
          );
        }}
      />
    </Grid>
  </Grid>
);
export default AssetsShow;
