import { isEmpty } from 'lodash';
import { type FieldValues } from 'react-hook-form';
import { OfferExternalSecurityDeliveryOptionDto, OfferOrderStatusUpdateEmailFieldDto } from 'src/dtos';
import { areCheckInstructionsEmpty } from 'src/utils';

export const validateOfferOrderStatusUpdateEmailFields = (value: FieldValues): FieldValues => {
  let result: FieldValues[] = [];

  if (!isEmpty(value.orderStatusUpdateEmailFields)) {
    result = value.orderStatusUpdateEmailFields.reduce(
      (errors: FieldValues[], anOption: OfferOrderStatusUpdateEmailFieldDto) => {
        const error: FieldValues = {};

        if (!anOption.status) {
          error.status = 'Offer Order Status Update Email Field "status" is required';
        }

        if (!anOption.line) {
          error.line = 'Offer Order Status Update Email Field "line" is required';
        }

        errors.push(error);

        return errors;
      },
      [],
    );
  }

  if (result.every(isEmpty)) {
    return [];
  }

  return { orderStatusUpdateEmailFields: result };
};

const validateWireInstructions = (values: FieldValues): FieldValues => {
  let result: FieldValues = {};

  if (!values.externalDetails.wireInstructions?.bankName) {
    result.bankName = 'Wire Instructions "bank name" is required';
  }

  if (!values.externalDetails.wireInstructions?.abaNumber) {
    result.abaNumber = 'Wire Instructions "ABA number"  is required';
  }

  if (!values.externalDetails.wireInstructions?.accountNumber) {
    result.accountNumber = 'Wire Instructions "account number" is required';
  }

  if (!values.externalDetails.wireInstructions?.accountName) {
    result.accountName = 'Wire Instructions "account name" is required';
  }

  if (!values.externalDetails.wireInstructions?.attentionLine) {
    result.attentionLine = 'Wire Instructions "attention Line" is required';
  }

  return result;
};

const validateCheckInstructions = (values: FieldValues): FieldValues => {
  let result: FieldValues = {};
  let address: FieldValues = {};

  const areInstructionsEmpty = areCheckInstructionsEmpty(values.externalDetails.checkInstructions);

  if (areInstructionsEmpty) {
    return result;
  }

  if (!values.externalDetails.checkInstructions.payableTo) {
    result.payableTo = 'Check Instructions "payable to" is required';
  }

  if (!values.externalDetails.checkInstructions.bankName) {
    result.bankName = 'Check Instructions "bank name"  is required';
  }

  if (!values.externalDetails.checkInstructions.address.address1) {
    address.address1 = 'Check Instructions "address line 1" is required';
  }

  if (!values.externalDetails.checkInstructions.address.city) {
    address.city = 'Check Instructions "city" is required';
  }

  if (!values.externalDetails.checkInstructions.address.city) {
    address.city = 'Check Instructions "city" is required';
  }

  if (!values.externalDetails.checkInstructions.address.state) {
    address.state = 'Check Instructions "state" is required';
  }

  if (!values.externalDetails.checkInstructions.address.postalCode) {
    address.postalCode = 'Check Instructions "postalCode" is required';
  }

  if (!values.externalDetails.checkInstructions.address.country) {
    address.country = 'Check Instructions "country" is required';
  }

  if (!isEmpty(address)) {
    result.address = address;
  }

  return result;
};

const validateSecurityDeliveryOptions = (value: FieldValues): FieldValues => {
  let result: FieldValues[] = [];

  if (!isEmpty(value.externalDetails.securityDeliveryOptions)) {
    result = value.externalDetails.securityDeliveryOptions.reduce(
      (errors: FieldValues[], anOption: OfferExternalSecurityDeliveryOptionDto) => {
        const error: FieldValues = {};

        if (!anOption.label) {
          error.label = 'Security Delivery Option "label" is required';
        }

        if (anOption.hasTooltip && !anOption.tooltip) {
          error.tooltip = 'Security Delivery Option "tooltip" is required';
        }

        errors.push(error);

        return errors;
      },
      [],
    );
  }

  if (result.every(isEmpty)) {
    return [];
  }

  return result;
};

export const validateOfferExternalDetails = (values: FieldValues): FieldValues => {
  let result: FieldValues = {
    externalDetails: {},
  };

  if (!values.isExternalOffer) {
    return result;
  }

  if (isEmpty(values.externalDetails)) {
    return result;
  }

  const wireInstructionsErrors = validateWireInstructions(values);

  if (!isEmpty(wireInstructionsErrors)) {
    result.externalDetails.wireInstructions = wireInstructionsErrors;
  }
  const checkInstructionsErrors = validateCheckInstructions(values);

  if (!isEmpty(checkInstructionsErrors)) {
    result.externalDetails.checkInstructions = checkInstructionsErrors;
  }

  const securityDeliveryOptionsErrors = validateSecurityDeliveryOptions(values);

  if (!isEmpty(securityDeliveryOptionsErrors)) {
    result.externalDetails.securityDeliveryOptions = securityDeliveryOptionsErrors;
  }

  return result;
};
