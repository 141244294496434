import { css } from '@emotion/css';

export const mainDiv = css`
  width: 100%;
  margin: 0 auto;
  height: 100vh;
`;

export const childDiv = css`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 10%;
`;
