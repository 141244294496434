import { AccountHolderUrlConstant } from 'src/constants';
import {
  GetAccountHolderUpdateRequestListDto,
  ApproveAccountHolderUpdateRequestDto,
  RejectAccountHolderUpdateRequestDto,
  AccountHolderUpdateRequestDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class AccountHoldersApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async listUpdateRequests({ params }: GetAccountHolderUpdateRequestListDto): Promise<AccountHolderUpdateRequestDto[]> {
    const url = replacePlaceholders(AccountHolderUrlConstant.LIST_UPDATE_REQUESTS, params);

    return httpRequest<AccountHolderUpdateRequestDto[]>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async approveUpdateRequest({ params }: ApproveAccountHolderUpdateRequestDto): Promise<void> {
    const url = replacePlaceholders(AccountHolderUrlConstant.APPROVE_UPDATE_REQUEST, params);

    return httpRequest<void>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  async rejectUpdateRequest({ params }: RejectAccountHolderUpdateRequestDto): Promise<void> {
    const url = replacePlaceholders(AccountHolderUrlConstant.REJECT_UPDDATE_REQUEST, params);

    return httpRequest<void>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }
}
