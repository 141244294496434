import _ from 'lodash';
import { GetListParams } from 'react-admin';

import { QueryParamConstant } from '../constants/common.constants';
import { BaseQueryParamsDto } from '../dtos/common.dtos';

export const mapListParamsToBaseQueryParamsDto = ({
  filter,
  pagination,
  sort,
  meta,
}: GetListParams): BaseQueryParamsDto => {
  let result: BaseQueryParamsDto = {};

  if (pagination && pagination.perPage !== QueryParamConstant.SKIP_PAGINATION) {
    result.take = pagination.perPage;
    result.skip = pagination.perPage * (pagination.page - 1);
  }

  if (sort && sort.field !== QueryParamConstant.SKIP_SORT) {
    result.sortBy = sort.field;
    result.sortDirection = sort.order.toLowerCase();
  }

  if (!_.isEmpty(filter)) {
    result.filterBy = JSON.stringify(filter);
  }

  if (!_.isEmpty(meta)) {
    result = { ...result, ...meta };
  }

  return result;
};
