import { TextField } from '@mui/material';
import { withStyles } from '@mui/styles';

import Colors from '../../constants/colors';

export default withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: Colors.LIGHT_GREY,
      },
      '&:hover fieldset': {
        borderColor: Colors.MY_IPO_BLUE,
      },
    },
  },
})(TextField);
