import * as React from 'react';

import { Edit } from 'react-admin';

import ConfigCreateEditForm from './CreateEditForm';

export const ConfigEdit = () => (
  <Edit redirect='list'>
    <ConfigCreateEditForm isEditMode />
  </Edit>
);

export default ConfigEdit;
