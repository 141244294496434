import AssessmentIcon from '@mui/icons-material/Assessment';

import IndicationOfInterestShow from './IndicationOfInterestShow';
import IndicationOfInterestsList from './IndicationOfInterestsList';

const IndicationOfInterests = {
  list: IndicationOfInterestsList,
  show: IndicationOfInterestShow,
  icon: AssessmentIcon,
};

export default IndicationOfInterests;
