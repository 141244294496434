import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

import Actions from './actions';
import DecideTransfer from './DecideTransfer';
import List from './List';
import Show from './Show';
import Toolbar from './toolbar';

const ACHTransfers = {
  Actions,
  Toolbar,
  DecideTransfer,

  Icon: CompareArrowsIcon,
  List,
  Show,
};

export default ACHTransfers;
