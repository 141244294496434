import React from 'react';

import { Checkbox, FormControlLabel, Paper, styled } from '@mui/material';

import { DragDropElementTree } from '../ExportModal/models/export.models';
import DraggableArea from './DraggableArea';

interface DraggableElementProps {
  item: DragDropElementTree;
  indexedElement: number;
  onChangeValue: (itemId: string) => void;
}

export const DraggableElement = ({ item, indexedElement, onChangeValue }: DraggableElementProps) => {
  const BootstrapPaperItem = styled(Paper)(() => ({
    lineHeight: '30px',
    width: '250px',
    fontSize: '18px',
    paddingLeft: '8px',
  }));

  return (
    <DraggableArea item={item} indexedElement={indexedElement}>
      <BootstrapPaperItem elevation={8}>
        <FormControlLabel
          control={<Checkbox checked={item.isSelected} onChange={() => onChangeValue(item.id)} />}
          label={item.name}
        />
      </BootstrapPaperItem>
    </DraggableArea>
  );
};
export default DraggableElement;
