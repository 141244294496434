import { AccountUrlConstant } from 'src/constants';
import { AccountDto, GetAccountDto } from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class AccountsApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async retrieve({ params }: GetAccountDto): Promise<AccountDto> {
    const url = replacePlaceholders(AccountUrlConstant.RETRIEVE, params);

    return httpRequest<AccountDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async downloadAgreement(id: string): Promise<Blob> {
    const url = replacePlaceholders(AccountUrlConstant.DOWNLOAD_AGREEMENT, { id });

    return httpRequest<Blob>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
      responseType: 'blob',
    });
  }

  async syncStatusList(ids?: string[]): Promise<void> {
    return httpRequest<void>({
      method: 'PUT',
      url: AccountUrlConstant.SYNC_STATUS_LIST,
      authToken: this._configService.authToken,
      queryParams: { ids },
    });
  }
}
