import { GetListParams, UpdateParams } from 'react-admin';
import { getClientApi } from 'src/data-communication';

const clientApi = getClientApi();

export const listAccountHolderUpdateRequests = async (params: GetListParams) => {
  const { filter } = params;
  const { accountIdentifier, accountHolderId } = filter;

  const data = await clientApi.accountHolders.listUpdateRequests({
    params: {
      accountIdentifier,
      accountHolderId,
    },
  });

  return {
    data,
    total: data.length,
  };
};

export const approveAccountHolderUpdateRequest = async (params: UpdateParams) => {
  const id = params.id;
  const { accountIdentifier, accountHolderId, note } = params.data;
  await clientApi.accountHolders.approveUpdateRequest({
    params: {
      id: id.toString(),
      accountIdentifier,
      accountHolderId,
    },
    body: {
      note,
    },
  });

  return {
    data: { id: id.toString() },
  };
};

export const rejectAccountHolderUpdateRequest = async (params: any) => {
  const id = params.id;
  const { accountIdentifier, accountHolderId, note } = params.data;
  await clientApi.accountHolders.rejectUpdateRequest({
    params: {
      id: id.toString(),
      accountIdentifier,
      accountHolderId,
    },
    body: {
      note,
    },
  });

  return {
    data: { id: id.toString() },
  };
};
