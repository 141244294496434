import {
  CreateParams,
  CreateResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { QueryParamConstant } from 'src/constants';
import { getClientApi } from 'src/data-communication';
import { ConfigDto, GetConfigListSortByQueryParamTypeDto, SortDirectionTypeDto } from 'src/dtos';

const clientApi = getClientApi();

export const getConfigList = async (params: GetListParams) => {
  const { data, total } = await clientApi.configs.list({
    queryParams: {
      skip:
        params.pagination.perPage === QueryParamConstant.SKIP_PAGINATION
          ? undefined
          : params.pagination.perPage && params.pagination.perPage * (params.pagination.page - 1),
      take: params.pagination.perPage === QueryParamConstant.SKIP_PAGINATION ? undefined : params.pagination.perPage,
      sortDirection: params.sort.order.toLowerCase() as SortDirectionTypeDto,
      sortBy:
        params.sort.field === QueryParamConstant.SKIP_SORT
          ? undefined
          : (params.sort.field as GetConfigListSortByQueryParamTypeDto),
    },
  });

  return {
    data,
    total: total || data.length,
  };
};

export const getConfig = async (params: GetOneParams<ConfigDto>): Promise<GetOneResult<ConfigDto>> => {
  const response = await clientApi.configs.retrieve({ params });

  return {
    data: response,
  };
};

export const createConfig = async (params: CreateParams): Promise<CreateResult<ConfigDto>> => {
  const { configType, appConfig } = params?.data;
  const response = await clientApi.configs.create({
    body: {
      appConfig,
      configType,
    },
  });

  return {
    data: response,
  };
};

export const updateConfig = async (params: UpdateParams<ConfigDto>): Promise<UpdateResult<ConfigDto>> => {
  const { appConfig } = params?.data;

  if (!appConfig) {
    throw new Error('appConfig is required');
  }

  const result = await clientApi.configs.patch({
    params: { id: params.id.toString() },
    body: { appConfig },
  });

  return {
    data: result,
  };
};

export const deleteConfig = async (params: DeleteParams): Promise<DeleteResult<{ id: string }>> => {
  await clientApi.configs.delete({ params });

  return {
    data: { id: params.id },
  };
};
