import { FilterButton, TopToolbar, useListContext } from 'react-admin';

import { SyncAccountsStatusButton } from './components/SyncAcountsStatusButton/SyncAccountsStatusButton';

export const UserListActions = () => {
  const { data } = useListContext();

  return (
    <TopToolbar>
      <FilterButton />
      <SyncAccountsStatusButton ids={data?.map(aRecord => aRecord.id)} />
    </TopToolbar>
  );
};
