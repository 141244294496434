import { BaseListResponseDto } from './common.dtos';

export enum AccuntHolderUpdateRequestTypeDto {
  PhysicalAddress = 'PhysicalAddress',
  MailingAddress = 'MailingAddress',
  SuitabilityInformation = 'SuitabilityInformation',
  TrustedContact = 'TrustedContact',
  FinancialInformationAsset = 'FinancialInformationAsset',
  FinancialinformationEmployment = 'FinancialinformationEmployment',
  Disclosure = 'Disclosure',
  Email = 'Email',
  PhoneNumber = 'PhoneNumber',
}

export enum AccountHolderUpdateRequestStatusDto {
  PendingFirmReview = 'PendingFirmReview',
  PendingConfirm = 'PendingConfirm',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Complete = 'Complete',
}

export interface AccountHolderUpdateRequestDto {
  id: string;
  type: AccuntHolderUpdateRequestTypeDto;
  status: AccountHolderUpdateRequestStatusDto;
  original:
    | Record<string, string | number | boolean>
    | (Record<string, string | number | boolean> | string | number | boolean)[];
  updated:
    | Record<string, string | number | boolean>
    | (Record<string, string | number | boolean> | string | number | boolean)[];
}

export interface GetAccountHolderUpdateRequestListParamsDto {
  accountIdentifier: string;
  accountHolderId: string;
}

export interface GetAccountHolderUpdateRequestListDto {
  params: GetAccountHolderUpdateRequestListParamsDto;
}

export interface GetAccountHolderUpdateRequestListResponseDto
  extends BaseListResponseDto<AccountHolderUpdateRequestDto[]> {}

export interface GetAccountHolderUpdateRequestParamsDto {
  id: string;
}

export interface GetAccountHolderUpdateRequestDto {
  params: GetAccountHolderUpdateRequestParamsDto;
}

export interface ApproveAccountHolderUpdateRequestParamsDto {
  accountIdentifier: string;
  accountHolderId: string;
  id: string;
}

export interface ApproveAccountHolderUpdateRequestBodyDto {
  note?: string;
}

export interface RejectAccountHolderUpdateRequestParamsDto extends ApproveAccountHolderUpdateRequestParamsDto {}

export interface ApproveAccountHolderUpdateRequestDto {
  params: ApproveAccountHolderUpdateRequestParamsDto;
  body: ApproveAccountHolderUpdateRequestBodyDto;
}

export interface RejectAccountHolderUpdateRequestBodyDto extends ApproveAccountHolderUpdateRequestBodyDto {}

export interface RejectAccountHolderUpdateRequestDto {
  params: RejectAccountHolderUpdateRequestParamsDto;
  body: RejectAccountHolderUpdateRequestBodyDto;
}
