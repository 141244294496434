import { GetManyParams, GetOneParams } from 'react-admin';
import { getClientApi } from 'src/data-communication';
import { mapAccountBalanceDetailsDtoToModel, mapAccountBalanceDtoToModel } from 'src/mappers';

export const getAccountBalance = async (params: GetOneParams) => {
  const { id } = params;

  const response = await getClientApi().balances.retrieve({ params: { accountId: id } });

  return {
    data: mapAccountBalanceDetailsDtoToModel(response),
  };
};

export const getAccountBalanceList = async (params: GetManyParams) => {
  const { ids } = params;

  const response = await getClientApi().balances.list({
    body: { accountIds: ids.map(anId => Number(anId)) },
  });

  return {
    data: response.data.map(anAccountDto => mapAccountBalanceDtoToModel(anAccountDto)),
    total: response.total,
  };
};
