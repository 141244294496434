import { AccountHolderDto, AccountStatusDto, AccountTypeDto } from 'src/dtos/accounts.dtos';

export enum AccountStatusLabel {
  Pending = 'PENDING',
  New = 'NEW',
  InvestigationSubmitted = 'INVESTIGATION SUBMITTED',
  ReadyForBackOffice = 'READY FOR BACKOFFICE',
  BackOffice = 'BACKOFFICE',
  AccountSetup = 'ACCOUNT SETUP',
  Complete = 'COMPLETE',
  ActionRequired = 'ACTION REQUIRED',
  Rejected = 'REJETCTED',
  Suspended = 'SUSPENDED',
  Error = 'ERROR',
  Canceled = 'CANCELED',
  PendingSubmit = 'PENDING SUBMIT',
  NotSubmitted = 'NOT SUBMITTED',
}

export class AccountStatus {
  private _value: AccountStatusDto | 'NotSubmitted';
  private _label: AccountStatusLabel;

  constructor(value?: AccountStatusDto) {
    this._value = value ?? 'NotSubmitted';
    this._label = this.findLabel();
  }

  private findLabel(): AccountStatusLabel {
    switch (this._value) {
      case AccountStatusDto.Pending:
        return AccountStatusLabel.Pending;
      case AccountStatusDto.New:
        return AccountStatusLabel.New;
      case AccountStatusDto.InvestigationSubmitted:
        return AccountStatusLabel.InvestigationSubmitted;
      case AccountStatusDto.ReadyForBackOffice:
        return AccountStatusLabel.ReadyForBackOffice;
      case AccountStatusDto.BackOffice:
        return AccountStatusLabel.BackOffice;
      case AccountStatusDto.AccountSetup:
        return AccountStatusLabel.AccountSetup;
      case AccountStatusDto.Complete:
        return AccountStatusLabel.Complete;
      case AccountStatusDto.ActionRequired:
        return AccountStatusLabel.ActionRequired;
      case AccountStatusDto.Rejected:
        return AccountStatusLabel.Rejected;
      case AccountStatusDto.Suspended:
        return AccountStatusLabel.Suspended;
      case AccountStatusDto.Error:
        return AccountStatusLabel.Error;
      case AccountStatusDto.Canceled:
        return AccountStatusLabel.Canceled;
      case AccountStatusDto.PendingSubmit:
        return AccountStatusLabel.PendingSubmit;
      default:
        return AccountStatusLabel.NotSubmitted;
    }
  }

  get value(): AccountStatusDto | 'NotSubmitted' {
    return this._value;
  }

  get label(): AccountStatusLabel {
    return this._label;
  }
}

export interface AccountHolder extends AccountHolderDto {}

export interface Account {
  id: string;
  accountId?: number;
  accountNumber?: string;
  primaryAccountHolder: AccountHolder;
  status: AccountStatus;
  type: AccountTypeDto;
  createdAt: string;
  updatedAt?: string;
}

export interface DownloadAccountAgrementResponse {
  id: string;
  file: {
    url: string;
    type: 'pdf';
  };
}
