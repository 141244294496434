import * as React from 'react';

import { Grid } from '@mui/material';
import { ArrayField, Datagrid, SelectField, SimpleShowLayout, UrlField } from 'react-admin';

import { offerDocumentsType } from '../../../../constants/choices/offer.documents.choices';

export const DocumentsShow = () => (
  <Grid container direction='row' spacing={2}>
    <Grid item xs={12}>
      <SimpleShowLayout>
        <h2>Documents Information</h2>
      </SimpleShowLayout>
    </Grid>
    <Grid item xs={12}>
      <ArrayField source='documents' label={false}>
        <Datagrid isRowSelectable={() => false} bulkActionButtons={false}>
          <SelectField source='type' choices={offerDocumentsType} fullWidth />
          <UrlField label='URL' source='url' target='_blank' fullWidth />
        </Datagrid>
      </ArrayField>
    </Grid>
  </Grid>
);
export default DocumentsShow;
