import * as React from 'react';

import { List, Datagrid, TextField, DateField, EmailField } from 'react-admin';
/**NOTE: Filters and Sorting will be added on next PR's */

const PreExistingUserList = () => (
  <List title='Pre-existing users'>
    <Datagrid rowClick='show' bulkActionButtons={false}>
      <EmailField source='email' />
      <TextField source='firstName' />
      <TextField source='middleName' />
      <TextField source='lastName' />
      <TextField source='role' />
      <DateField source='createdAt' />
      <DateField source='updatedAt' />
    </Datagrid>
  </List>
);

export default PreExistingUserList;
