import { BooleanField, Labeled } from 'react-admin';
import { Row, Col } from 'react-grid-system';

import {
  TradingBlockInvestigationInvestigationCipCategory,
  TradingBlockInvestigationApplicationCipCategory,
} from '../../../../../dtos/investigations.dtos';
import StatusField from '../../../../Fields/StatusField';

export interface CipCategoryListProps {
  categories: TradingBlockInvestigationApplicationCipCategory[] | TradingBlockInvestigationInvestigationCipCategory[];
}

export const isTradingBlockInvestigationInvestigationCipCategory = (
  value: TradingBlockInvestigationApplicationCipCategory | TradingBlockInvestigationInvestigationCipCategory,
): value is TradingBlockInvestigationInvestigationCipCategory =>
  Boolean((value as TradingBlockInvestigationInvestigationCipCategory).state);

export const CipCategoryList = ({ categories }: CipCategoryListProps) => (
  <>
    {categories.map(
      (
        category: TradingBlockInvestigationApplicationCipCategory | TradingBlockInvestigationInvestigationCipCategory,
      ) => (
        <Row key={'category' + category.name} style={{ padding: 0 }}>
          <Col md={4}>
            <Labeled label={category.name}>
              {isTradingBlockInvestigationInvestigationCipCategory(category) ? (
                <StatusField
                  status={category.state}
                  success={['Accepted', 'Appealed']}
                  warning={['Pending', 'New', 'Indeterminate']}
                  fail={['Rejected', 'Error', 'Canceled']}
                />
              ) : (
                <></>
              )}
            </Labeled>
          </Col>
          <Col md={4}>
            <>
              <p>Requested Documents </p>
              {category.requestedDocuments?.join(', ') ?? 'NA'}
            </>
          </Col>
          <Col md={4}>
            <Labeled label='Is Resolved'>
              <BooleanField source='isResolved' record={category} />
            </Labeled>
          </Col>
        </Row>
      ),
    )}
  </>
);
