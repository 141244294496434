import * as React from 'react';

import { useRecordContext } from 'react-admin';

import Styles from '../../constants/styles';

const ActiveField = (props: any = {}) => {
  const { source } = props;
  const record = useRecordContext(props);
  const activeState = !!record?.[source] ? 'Active' : 'Canceled';

  return (
    <div style={!!record?.[source] ? Styles.fields.activeFields.active : Styles.fields.activeFields.cancel}>
      {activeState.toUpperCase()}
    </div>
  );
};
export default ActiveField;
