import * as React from 'react';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  TextInput,
  ImageField,
  BooleanField,
  SelectInput,
  ReferenceInput,
} from 'react-admin';

const defaultSort = { field: 'name', order: 'ASC' };
const offerFilters = [
  <TextInput key='name' label='Name' source='name' alwaysOn />,
  <ReferenceInput key='statusId' source='statusId' reference='offer-options' filter={{ type: 'offerStatus' }}>
    <SelectInput label='Offer Status' optionText='name' />
  </ReferenceInput>,
  <ReferenceInput key='offerTypeId' source='offerTypeId' reference='offer-options' filter={{ type: 'offerType' }}>
    <SelectInput label='Offer Type' optionText='name' />
  </ReferenceInput>,
  <ReferenceInput key='industryId' source='industryId' reference='offer-options' filter={{ type: 'offerIndustry' }}>
    <SelectInput label='Industry' optionText='name' />
  </ReferenceInput>,
  <ReferenceInput key='categoryId' source='categoryId' reference='offer-options' filter={{ type: 'offerCategory' }}>
    <SelectInput label='Category' optionText='name' />
  </ReferenceInput>,
  <ReferenceInput
    key='securityTypeId'
    source='securityTypeId'
    reference='offer-options'
    filter={{ type: 'offerSecurityType' }}>
    <SelectInput label='Security type' optionText='name' />
  </ReferenceInput>,
];

const OfferList = () => (
  <List title='Offers' filters={offerFilters} sort={defaultSort}>
    <Datagrid rowClick='show' bulkActionButtons={false}>
      <ImageField
        label='Logo'
        source='logoUrl'
        sx={{ '& img': { maxHeight: '4rem', objectFit: 'contain', margin: '0.25rem' } }}
      />
      <TextField source='name' />
      <TextField source='status' label='Status' />
      <TextField source='type' label='Type' />
      <TextField source='industry' label='Industry' />
      <TextField source='category' label='Category' />
      <TextField source='security' label='Security' />
      <BooleanField source='visibleToEndUsers' label='Visible' />
      <BooleanField source='new' />
      <BooleanField source='featured' />
      <BooleanField source='isExternalOffer' label='Is external' />
      <BooleanField source='recurring' />
      <DateField source='startDate' />
      <TextField source='closeDate' />
    </Datagrid>
  </List>
);

export default OfferList;
