import * as React from 'react';

import { Grid } from '@mui/material';
import { Show, SimpleShowLayout, SelectField, FunctionField } from 'react-admin';
import { ConfigTypeDto } from 'src/dtos';

import { configOptions } from '../../constants/choices/config.choices';
import BankLinkConfigShow from './components/BankLink/BankLinkShow';
import BankTransferConfigShow from './components/BankTransfer/BankTransferShow';

export const ConfigShow = () => (
  <Show emptyWhileLoading title={'Application Configuration'}>
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>Application Configuration</h2>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <SelectField label='Configuration Type' source='configType' choices={configOptions} />
        </SimpleShowLayout>
      </Grid>
    </Grid>
    <FunctionField render={(record: any) => record.configType === ConfigTypeDto.BANK_LINK && <BankLinkConfigShow />} />
    <FunctionField
      render={(record: any) => record.configType === ConfigTypeDto.BANK_TRANSFER && <BankTransferConfigShow />}
    />
  </Show>
);

export default ConfigShow;
