import { Grid } from '@mui/material';
import {
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  required,
  SelectInput,
  FileInput,
  FileField,
} from 'react-admin';

import { offerDocumentsType } from '../../constants/choices/offer.documents.choices';

interface SimpleCreateEditFormProps {
  isEditMode?: boolean;
}

export const SimpleCreateEditForm = ({ isEditMode = false }: SimpleCreateEditFormProps) => (
  <SimpleForm>
    <Grid container direction='row' justifyContent='space-between' spacing={2}>
      <Grid item xs={12}>
        <h2>{isEditMode ? 'Edit' : 'Create'} offer document</h2>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReferenceInput source='offerId' reference='offers' sort={{ field: 'name', order: 'ASC' }}>
          <AutocompleteInput
            label='Offer'
            optionText='name'
            validate={[required()]}
            fullWidth
            filterToQuery={searchText => ({ name: searchText })}
            disabled={isEditMode}
          />
        </ReferenceInput>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectInput source='type' validate={[required()]} choices={offerDocumentsType} fullWidth />
      </Grid>
      <Grid item xs={12}>
        <FileInput source='_files' label='Related files' validate={required()} accept='application/pdf'>
          <FileField
            source='src'
            title='title'
            target='_blank'
            sx={{ '& img': { maxWidth: '4rem', maxHeight: '4rem', objectFit: 'contain', margin: '0.25rem' } }}
          />
        </FileInput>
      </Grid>
    </Grid>
  </SimpleForm>
);

export default SimpleCreateEditForm;
