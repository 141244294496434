import { OrderConstant } from '../constants/orders.constants';
import {
  OrderDto,
  OrderPositionDto,
  TradingBlockOrderStatusIntDto,
  TradingBlockOrderTypeNumberEnum,
} from '../dtos/orders.dtos';
import { PrivateQuoteDto, PrivateQuoteSnapDto } from '../dtos/privateQuotes.dtos';
import { Order, OrderAction, OrderPosition, OrderStatus, OrderType } from '../models/order.model';
import { SnapQuote } from '../models/quoteMedia.model';

const incompleteMarketOrderPrice = (order: OrderDto, lastValue: number = 0): number => {
  let price = lastValue;

  if (
    order.OrderStatus === TradingBlockOrderStatusIntDto.Cancelled ||
    order.OrderStatus === TradingBlockOrderStatusIntDto.Rejected ||
    order.OrderStatus === TradingBlockOrderStatusIntDto.Expired
  ) {
    price = 0; // NOTE: TB market order price is not saved for unfilled orders;
  }

  return price;
};

const calculatePrice = (order: OrderDto, lastValue: number = 0): number => {
  if (order.AverageFillPrice !== undefined && order.AverageFillPrice > 0) {
    return order.AverageFillPrice;
  }

  if (order.OrderStatus === TradingBlockOrderStatusIntDto.Filled) {
    return order.AverageFillPrice ?? 0;
  }

  try {
    switch (order.OrderType) {
      case TradingBlockOrderTypeNumberEnum.Undefined: {
        return order.AverageFillPrice ?? 0;
      }

      case TradingBlockOrderTypeNumberEnum.Market: {
        return incompleteMarketOrderPrice(order, lastValue);
      }

      case TradingBlockOrderTypeNumberEnum.Limit: {
        return order.Price ?? 0;
      }

      case TradingBlockOrderTypeNumberEnum.Stop_Market: {
        return order.Stop ?? 0;
      }

      case TradingBlockOrderTypeNumberEnum.Stop_Limit: {
        return order.Price ?? 0;
      }

      case TradingBlockOrderTypeNumberEnum.Market_On_Close: {
        return incompleteMarketOrderPrice(order, lastValue);
      }

      default: {
        throw new Error(`Expected order type "TradingBlockOrderTypeNumberEnum". Received: "${order?.OrderType}"`);
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    return 0;
  }
};

const isPartial = (dto: OrderDto): boolean =>
  Boolean(dto.FillQuantity && dto.FillQuantity < dto.Quantity && dto.FillQuantity > 0);

const addTotalCostToPartialOrder = (order: Order): Order => {
  let totalCost = order.filledQuantity * order.price;

  if (order.action?.isBuy) {
    totalCost = -(totalCost + order.commission);
  }

  if (order.action?.isSell) {
    totalCost = totalCost - order.commission;
  }

  return { ...order, totalCost };
};

const addTotalCostToCompletedOrder = (order: Order): Order => {
  let totalCost = order.quantity * order.price;

  if (order.action?.isBuy) {
    totalCost = -(totalCost + order.commission);
  }

  if (order.action?.isSell) {
    totalCost = totalCost - order.commission;
  }

  return { ...order, totalCost };
};

const addTotalCostToIncompleteOrder = (order: Order): Order => {
  let totalCost = order.quantity * order.price;

  if (order.isPartial) {
    return addTotalCostToPartialOrder(order);
  }

  if (order.action?.isBuy) {
    totalCost = -(totalCost + OrderConstant.ESTIMATED_SERVICE_FEE);
  }

  if (order.action?.isSell) {
    totalCost = totalCost - OrderConstant.ESTIMATED_SERVICE_FEE;
  }

  return { ...order, totalCost };
};

const addTotalCost = (order: Order): Order => {
  let totalCost = order.quantity * order.price;

  if (totalCost === 0) {
    return order;
  }

  return order.status.isCompleted ? addTotalCostToCompletedOrder(order) : addTotalCostToIncompleteOrder(order);
};

const addCommission = (order: Order): Order => {
  if (order.status.isCompleted || order.isPartial) {
    return order;
  }

  return { ...order, commission: OrderConstant.ESTIMATED_SERVICE_FEE };
};

export const mapOrderDtoToModel = (dto: OrderDto, quote?: SnapQuote): Order => {
  let result: Order = {
    id: dto.OrderId.toString(),
    updatedAt: dto.Date,
    quantity: dto.Quantity,
    filledQuantity: dto.FillQuantity ?? 0,
    isPartial: isPartial(dto),
    price: calculatePrice(dto, quote?.last),
    totalCost: 0,
    type: new OrderType(dto.OrderType),
    symbol: dto.UnderlyingSymbol ?? '',
    description: quote?.description ?? dto.UnderlyingSymbol ?? '',
    action: new OrderAction(dto.Legs?.[0]?.Action),
    status: new OrderStatus(dto.OrderStatus),
    commission: dto.CommissionAssessed,
    limit: dto.Price,
    stop: dto.Stop,
  };
  result = addTotalCost(result);
  result = addCommission(result);

  return result;
};

export const mapGetOrdersListResponseDtoToModel = (dtos: OrderDto[], quotes: SnapQuote[]): Order[] =>
  dtos.map(orderDto =>
    mapOrderDtoToModel(
      orderDto,
      quotes.find(aQuote => aQuote.symbol === orderDto.UnderlyingSymbol),
    ),
  );

const enrichNonTradableOrderPosition = (model: OrderPosition, privateQuote: PrivateQuoteDto): OrderPosition => {
  let result: OrderPosition = {
    ...model,
    isTradable: false,
    value: privateQuote.currentPrice,
    totalValue: model.quantity * privateQuote.currentPrice,
  };

  if (result.purchasePrice === 0) {
    result.purchasePrice = privateQuote.purchasePrice;
  }

  return result;
};

const addNonTradableOrderPositionSnapIfFound = (model: OrderPosition, snap: PrivateQuoteSnapDto[]): OrderPosition => {
  const privateQuoteSnap = snap?.find(aPrivateQuoteSnap => aPrivateQuoteSnap.symbol === model.symbol);

  if (!privateQuoteSnap) {
    return model;
  }
  let result = { ...model };

  if (!isNaN(privateQuoteSnap.lastPrice) && privateQuoteSnap.lastPrice !== 0) {
    result.value = privateQuoteSnap.lastPrice;
    result.totalValue = result.quantity * privateQuoteSnap.lastPrice;
  }

  return result;
};

const makeNonTradableOrderPositionIfFound = (
  model: OrderPosition,
  privateQuotes: PrivateQuoteDto[],
  snap: PrivateQuoteSnapDto[],
): OrderPosition => {
  const privateQuote = privateQuotes.find(aPrivateQuote => aPrivateQuote.symbol === model.symbol);

  if (!privateQuote) {
    return model;
  }
  let result = enrichNonTradableOrderPosition(model, privateQuote);
  result = addNonTradableOrderPositionSnapIfFound(result, snap);

  return result;
};

export const mapOrderPositionDtoToModel = (
  dto: OrderPositionDto,
  snap: SnapQuote[],
  privateQuotes: PrivateQuoteDto[],
  privateSnap: PrivateQuoteSnapDto[],
): OrderPosition => {
  let result: OrderPosition = {
    id: dto.OrderId.toString(),
    symbol: dto.Symbol,
    name: dto.Description,
    quantity: dto.OpenQuantity,
    purchasePrice: dto.OpenPrice,
    isTradable: true,
    totalCost: dto.CostBasis,
  };
  const positionSnap = snap.find(aSnap => aSnap.symbol === dto.Symbol);

  if (positionSnap) {
    result.value = positionSnap.last;
    result.totalValue = positionSnap.last * result.quantity;
    result.change = positionSnap.change / 100;
  }

  if (positionSnap?.description) {
    result.name = positionSnap.description;
  }

  result = makeNonTradableOrderPositionIfFound(result, privateQuotes, privateSnap);

  return result;
};

export const mapGetPositionsListResponseDtoToModel = (
  dtos: OrderPositionDto[],
  snap: SnapQuote[],
  privateQuotes: PrivateQuoteDto[],
  privateSnap: PrivateQuoteSnapDto[] = [],
): OrderPosition[] => dtos.map(dto => mapOrderPositionDtoToModel(dto, snap, privateQuotes, privateSnap));
