import _ from 'lodash';
import { AccountBalanceUrlConstant } from 'src/constants';
import {
  AccountBalanceDetailsDto,
  GetAccountBalanceDto,
  GetAccountBalanceListDto,
  GetAccountBalanceListResponseDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class BalancesApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async retrieve({ params }: GetAccountBalanceDto): Promise<AccountBalanceDetailsDto> {
    const url = replacePlaceholders(AccountBalanceUrlConstant.RETRIEVE, params);

    return httpRequest<AccountBalanceDetailsDto>({ method: 'GET', url, authToken: this._configService.authToken });
  }

  async list({ body }: GetAccountBalanceListDto): Promise<GetAccountBalanceListResponseDto> {
    return httpRequest<GetAccountBalanceListResponseDto>({
      method: 'POST',
      url: AccountBalanceUrlConstant.LIST,
      body: { accountIds: _.uniq(body.accountIds) },
      authToken: this._configService.authToken,
    });
  }
}
