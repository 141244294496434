import * as React from 'react';

import { Create } from 'react-admin';

import ConfigCreateEditForm from './CreateEditForm';

export const ConfigCreate = () => (
  <Create redirect='list'>
    <ConfigCreateEditForm />
  </Create>
);

export default ConfigCreate;
