import LocalOffer from '@mui/icons-material/LocalOffer';

import OfferCreate from './OfferCreate';
import OfferEdit from './OfferEdit';
import OfferList from './OfferList';
import OfferShow from './OfferShow';

const Offers = {
  list: OfferList,
  show: OfferShow,
  edit: OfferEdit,
  create: OfferCreate,
  icon: LocalOffer,
};

export default Offers;
