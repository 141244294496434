import * as React from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Chip, Grid, IconButton, Tooltip } from '@mui/material';
import {
  BooleanField,
  FunctionField,
  Labeled,
  SimpleShowLayout,
  TextField,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  WithRecord,
} from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { OfferDetailsDto } from 'src/dtos';
import { GridBreak } from 'src/lib';
import { getCardPaymentAccountStatusColor } from 'src/utils';

export const PaymentsShow = () => {
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext<OfferDetailsDto>();
  const dataProvider = useDataProvider();
  const [isCopyToClipboardButtonDisabled, setIsCopyToClipboardButtonDisabled] = React.useState<boolean>(false);
  const [isNewOfferCardPaymentAccountLinkDisabled, setIsNewOfferCardPaymentAccountLinkDisabled] =
    React.useState<boolean>(false);

  const onCopyToClipboard = () => {
    setIsCopyToClipboardButtonDisabled(true);
    navigator.clipboard.writeText(record.cardPaymentAccount?.linkUrl ?? '');
    setIsCopyToClipboardButtonDisabled(false);
    notify('Card payment account link is copied', { type: 'success' });
  };

  const onNewOfferCardPaymentAccountLink = async () => {
    setIsNewOfferCardPaymentAccountLinkDisabled(true);

    try {
      await dataProvider.update<OfferDetailsDto>(DataProviderResource.NewOfferCardPaymentAccountLink, {
        id: record.id,
        data: {},
        previousData: record,
      });

      refresh();
      setIsNewOfferCardPaymentAccountLinkDisabled(false);
      notify('New card payment account link was successfully created', { type: 'success' });
    } catch (error) {
      notify(`Error creating new card payment account link. Received: ${JSON.stringify(error)}`, {
        type: 'error',
      });
    }
  };

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>Offer Payments</h2>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <SimpleShowLayout>
          <BooleanField label='Allow ACH Transfers' source='allowAchTransfers' fullWidth />
          <BooleanField label='Allow Wire Transfers' source='allowWireTransfers' fullWidth />
          <BooleanField label='Allow Check Transfers' source='allowCheckTransfers' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <GridBreak />
      <Grid item xs={12}>
        <SimpleShowLayout>
          <BooleanField label='Allow Card Payments' source='allowCardPayments' fullWidth />
          <TextField label='Account Type' source='cardPaymentAccount.type' record={record} />
        </SimpleShowLayout>
      </Grid>
      <GridBreak />
      <Grid item xs={6}>
        <SimpleShowLayout>
          <WithRecord
            render={record => {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Labeled
                    label={
                      <Tooltip
                        title='The url can only be used once to onboard a Stripe Connect account and it also has an expiration period. Please use the "Refresh" button to create a new card payment account link url.'
                        placement='top-start'>
                        <span style={{ display: 'flex', alignItems: 'center' }}>
                          <span>Card Payment Account Link Url</span>
                          <InfoOutlinedIcon fontSize='small' />
                        </span>
                      </Tooltip>
                    }>
                    <TextField source='cardPaymentAccount.linkUrl' record={record} />
                  </Labeled>
                  <Tooltip title='Copy card payment account link url'>
                    <IconButton onClick={onCopyToClipboard} disabled={isCopyToClipboardButtonDisabled}>
                      <ContentCopyIcon color={isCopyToClipboardButtonDisabled ? 'disabled' : 'primary'} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title='Create a new card payment account link url'>
                    <IconButton
                      onClick={onNewOfferCardPaymentAccountLink}
                      disabled={isNewOfferCardPaymentAccountLinkDisabled}>
                      <RefreshIcon color={isNewOfferCardPaymentAccountLinkDisabled ? 'disabled' : 'primary'} />
                    </IconButton>
                  </Tooltip>
                </div>
              );
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6}>
        <SimpleShowLayout>
          <FunctionField
            label='Account Status'
            render={(record: OfferDetailsDto) => (
              <Chip
                size='small'
                label={record.cardPaymentAccount?.status ?? 'NA'}
                color={getCardPaymentAccountStatusColor(record.cardPaymentAccount?.status)}
              />
            )}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6}>
        <SimpleShowLayout>
          <TextField label='Account Id' source='cardPaymentAccount.externalId' />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6}>
        <SimpleShowLayout>
          <TextField label='Account Email' source='cardPaymentAccount.email' />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};
