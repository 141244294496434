import {
  AccountStatusDto,
  OfferCardPaymentAccountStatusDto,
  OfferOrderRefundStatusDto,
  OfferOrderStatusDto,
} from 'src/dtos';
import { OrderStatusLabel, OfferOrderStatusLabel, TransferStatusLabel, OfferOrderPaymentStatusType } from 'src/models';
import { ColorConstant } from 'src/styles';

export type ColorType = 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';

export const getTransferStatusTagColor = (value: TransferStatusLabel): { background: string; color: string } => {
  let background;
  let color;

  switch (value) {
    case TransferStatusLabel.PENDING:
      background = ColorConstant.GRAY3;
      color = ColorConstant.GRAY7;
      break;

    case TransferStatusLabel.REJECTED:
      background = ColorConstant.RED2;
      color = ColorConstant.RED7;
      break;

    case TransferStatusLabel.COMPLETED:
      background = ColorConstant.GREEN2;
      color = ColorConstant.GREEN7;
      break;

    case TransferStatusLabel.CANCELLED:
      background = ColorConstant.ORANGE2;
      color = ColorConstant.ORANGE7;
      break;

    default:
      background = ColorConstant.BRAND2;
      color = ColorConstant.BRAND7;
  }

  return { background, color };
};

export const getOrderStatusTagColor = (value: OrderStatusLabel): { background: string; color: string } => {
  let background;
  let color;

  switch (value) {
    case OrderStatusLabel.PENDING:
      background = ColorConstant.GRAY3;
      color = ColorConstant.GRAY7;
      break;

    case OrderStatusLabel.REJECTED:
      background = ColorConstant.RED2;
      color = ColorConstant.RED7;
      break;

    case OrderStatusLabel.COMPLETED:
      background = ColorConstant.GREEN2;
      color = ColorConstant.GREEN7;
      break;

    case OrderStatusLabel.CANCELLED:
      background = ColorConstant.ORANGE2;
      color = ColorConstant.ORANGE7;
      break;

    default:
      background = ColorConstant.BRAND2;
      color = ColorConstant.BRAND7;
  }

  return { background, color };
};

export const getOfferOrderStatusTagColor = (value: OfferOrderStatusLabel): { background: string; color: string } => {
  switch (value) {
    case OfferOrderStatusLabel.Approved:
      return { background: ColorConstant.BRAND2, color: ColorConstant.BRAND7 };
    case OfferOrderStatusLabel.Rejected:
      return { background: ColorConstant.RED2, color: ColorConstant.RED7 };
    case OfferOrderStatusLabel.Complete:
      return { background: ColorConstant.GREEN2, color: ColorConstant.GREEN7 };
    case OfferOrderStatusLabel.Cancelled:
      return { background: ColorConstant.ORANGE2, color: ColorConstant.ORANGE7 };
    case OfferOrderStatusLabel.PendingFirmCancellation:
      return {
        background: ColorConstant.ORANGE1,
        color: ColorConstant.ORANGE7,
      };
    default:
      return {
        background: ColorConstant.GRAY3,
        color: ColorConstant.GRAY7,
      };
  }
};

export const getOfferOrderPaymentStatusColor = (status: OfferOrderPaymentStatusType): ColorType => {
  if (status === 'Succeeded') {
    return 'success';
  }

  if (status === 'Canceled') {
    return 'warning';
  }

  return 'default';
};

export const getRefundStatusColor = (status?: OfferOrderRefundStatusDto | 'NA'): ColorType => {
  if (status === OfferOrderRefundStatusDto.Succeeded) {
    return 'success';
  }

  if (status === OfferOrderRefundStatusDto.Canceled) {
    return 'warning';
  }

  if (status === OfferOrderRefundStatusDto.Failed) {
    return 'error';
  }

  return 'default';
};

export const getCardPaymentAccountStatusColor = (status?: OfferCardPaymentAccountStatusDto): ColorType => {
  if (status === OfferCardPaymentAccountStatusDto.Complete || status === OfferCardPaymentAccountStatusDto.Enabled) {
    return 'success';
  }

  if (status === OfferCardPaymentAccountStatusDto.Restricted || status === OfferCardPaymentAccountStatusDto.Rejected) {
    return 'error';
  }

  return 'default';
};

export const getOfferOrderStatusDtoTagStyle = (type: OfferOrderStatusDto): any => {
  let backgroundColor;
  let color;

  switch (type) {
    case OfferOrderStatusDto.PendingFunds:
      backgroundColor = ColorConstant.GRAY3;
      color = ColorConstant.GRAY7;
      break;
    case OfferOrderStatusDto.PendingOfferClose:
      backgroundColor = ColorConstant.ORANGE1;
      color = ColorConstant.GRAY8;
      break;
    case OfferOrderStatusDto.PendingAction:
      backgroundColor = ColorConstant.BRAND1;
      color = ColorConstant.BRAND7;
      break;
    case OfferOrderStatusDto.Approved:
      backgroundColor = ColorConstant.BRAND2;
      color = ColorConstant.BRAND7;
      break;
    case OfferOrderStatusDto.Complete:
      backgroundColor = ColorConstant.GREEN2;
      color = ColorConstant.GREEN7;
      break;
    case OfferOrderStatusDto.PendingFirmCancellation:
      backgroundColor = ColorConstant.ORANGE1;
      color = ColorConstant.ORANGE7;
      break;
    case OfferOrderStatusDto.Cancelled:
      backgroundColor = ColorConstant.ORANGE2;
      color = ColorConstant.ORANGE7;
      break;
    case OfferOrderStatusDto.Rejected:
      backgroundColor = ColorConstant.RED2;
      color = ColorConstant.RED7;
      break;
    case OfferOrderStatusDto.Deleted:
      backgroundColor = ColorConstant.RED2;
      color = ColorConstant.RED8;
      break;
  }

  return {
    borderRadius: '0.5rem',
    backgroundColor,
    color,
    fontWeight: '600',
    fontSize: '0.75rem',
    lineHeight: '0.875rem',
    letterSpacing: '0.05em',
    padding: '0.25rem 0.5rem',
    border: `0.0625rem solid ${backgroundColor}`,
    textAlign: 'center',
  };
};

export const getAccountStatusTagPalette = (
  status: AccountStatusDto | 'NotSubmitted',
): { background: string; color: string } => {
  switch (status) {
    case AccountStatusDto.Complete:
      return { background: ColorConstant.GREEN2, color: ColorConstant.GREEN7 };
    case AccountStatusDto.Rejected:
    case AccountStatusDto.Suspended:
    case AccountStatusDto.Error:
    case AccountStatusDto.Canceled:
    case 'NotSubmitted':
      return { background: ColorConstant.RED2, color: ColorConstant.RED7 };
    default:
      return {
        background: ColorConstant.ORANGE1,
        color: ColorConstant.ORANGE7,
      };
  }
};
