import { AchRelationshipUrlConstant } from 'src/constants';
import {
  AchRelationshipDetailsDto,
  AchRelationshipDto,
  ApproveAchRelationshipDto,
  CancelAchRelationshipDto,
  CreateAchRelationshipDto,
  GetAchRelationshipDto,
  GetAchRelationshipListDto,
  GetAchRelationshipListResponseDto,
  RejectAchRelationshipDto,
  SearchAchRelationshipListDto,
  SearchAchRelationshipListResponseDto,
  UnformattedGetAchRelationshipListResponseDto,
  UpdateAchRelationshipDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class AchRelationshipsApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async create({ params, body }: CreateAchRelationshipDto): Promise<AchRelationshipDto> {
    const url = replacePlaceholders(AchRelationshipUrlConstant.CREATE, params);

    return await httpRequest<AchRelationshipDto>({
      method: 'POST',
      url,
      authToken: this._configService.authToken,
      body,
    });
  }

  async search({ queryParams }: SearchAchRelationshipListDto): Promise<SearchAchRelationshipListResponseDto> {
    return await httpRequest<SearchAchRelationshipListResponseDto>({
      method: 'GET',
      url: AchRelationshipUrlConstant.SEARCH,
      authToken: this._configService.authToken,
      queryParams,
    });
  }

  async list({ params, queryParams }: GetAchRelationshipListDto): Promise<GetAchRelationshipListResponseDto> {
    const url = replacePlaceholders(AchRelationshipUrlConstant.LIST, params);

    const response = await httpRequest<UnformattedGetAchRelationshipListResponseDto>({
      method: 'GET',
      url,
      queryParams,
      authToken: this._configService.authToken,
    });

    return {
      data: response,
      total: response.length,
    };
  }

  async retrieve({ params }: GetAchRelationshipDto): Promise<AchRelationshipDetailsDto> {
    const url = replacePlaceholders(AchRelationshipUrlConstant.RETRIEVE, params);

    return await httpRequest<AchRelationshipDetailsDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async approve({ params, queryParams, body }: ApproveAchRelationshipDto): Promise<AchRelationshipDto> {
    const url = replacePlaceholders(AchRelationshipUrlConstant.APPROVE, params);

    return await httpRequest<AchRelationshipDto>({
      method: 'PUT',
      url,
      queryParams,
      body,
      authToken: this._configService.authToken,
    });
  }

  async cancel({ params, queryParams, body }: CancelAchRelationshipDto): Promise<AchRelationshipDto> {
    const url = replacePlaceholders(AchRelationshipUrlConstant.CANCEL, params);

    return await httpRequest<AchRelationshipDto>({
      method: 'PUT',
      url,
      queryParams,
      body,
      authToken: this._configService.authToken,
    });
  }

  async reject({ params, queryParams, body }: RejectAchRelationshipDto): Promise<AchRelationshipDto> {
    const url = replacePlaceholders(AchRelationshipUrlConstant.REJECT, params);

    return await httpRequest<AchRelationshipDto>({
      method: 'PUT',
      url,
      queryParams,
      body,
      authToken: this._configService.authToken,
    });
  }

  async update({ params, queryParams, body }: UpdateAchRelationshipDto): Promise<AchRelationshipDto> {
    const url = replacePlaceholders(AchRelationshipUrlConstant.UPDATE, params);

    return await httpRequest<AchRelationshipDto>({
      method: 'PUT',
      url,
      queryParams,
      body,
      authToken: this._configService.authToken,
    });
  }
}
