import * as React from 'react';

import { Grid } from '@mui/material';
import { RichTextField, SimpleShowLayout } from 'react-admin';

export const DisclaimerShow = () => (
  <Grid container direction='row' spacing={2}>
    <Grid item xs={12}>
      <SimpleShowLayout>
        <h2>Disclaimer Information</h2>
      </SimpleShowLayout>
    </Grid>
    <Grid item xs={12}>
      <SimpleShowLayout>
        <RichTextField source='disclaimer' label={false} fullWidth />
      </SimpleShowLayout>
    </Grid>
  </Grid>
);
export default DisclaimerShow;
