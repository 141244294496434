const Colors = {
  MY_IPO_BLUE: '#0255A3',
  LIGHTER_MY_IPO_BLUE: '#025CB1',
  LIGHTEST_MY_IPO_BLUE: '#e4f2ff',
  LIGHT_GREY: '#DADDE0',
  GREY: '#9C9FA1',
  WHITE: '#FFFFFF',
  SUCCESS: '#4caf50',
  SUCCESS_LIGHT: '#D9F7BE',
  SUCCESS_DARK: '#237804',
  DANGER: '#ef5350',
  DANGER_LIGHT: '#FFF1F0',
  DANGER_DARK: '#A8071A',
  WARNING: '#ff9800',
  WARNING_DARK: '#AD4E00',
  WARNING_LIGHT: '#FFE7BA',
  INFO: '#03a9f4',
  INFO_DARK: '#01579b',
};

export namespace ColorConstant {
  export const BRAND1 = '#CCDDED';
  export const BRAND2 = '#E6EEF6';
  export const BRAND3 = '#B3CCE3';
  export const BRAND4 = '#81AAD1';
  export const BRAND5 = '#3877B0';
  export const BRAND6 = '#0255A3';
  export const BRAND7 = '#013B72';
  export const BRAND8 = '#012B52';
  export const BRAND9 = '#011E39';
  export const BRAND10 = '#000D18';
  export const GRAY1 = '#FFFFFF';
  export const GRAY2 = '#F9FBFC';
  export const GRAY3 = '#F2F5F7';
  export const GRAY4 = '#DADDE0';
  export const GRAY5 = '#BDC2C7';
  export const GRAY6 = '#989EA6';
  export const GRAY7 = '#5C6572';
  export const GRAY8 = '#353D4A';
  export const GRAY9 = '#1B2028';
  export const GRAY10 = '#101217';
  export const GREEN1 = '#F6FFED';
  export const GREEN2 = '#D9F7BE';
  export const GREEN3 = '#B7EB8F';
  export const GREEN4 = '#95DE64';
  export const GREEN5 = '#73D13D';
  export const GREEN6 = '#52C41A';
  export const GREEN7 = '#389E0D';
  export const GREEN8 = '#237804';
  export const GREEN9 = '#135200';
  export const GREEN10 = '#092B00';
  export const RED1 = '#FFF1F0';
  export const RED2 = '#FFCCC7';
  export const RED3 = '#FFA39E';
  export const RED4 = '#FF7875';
  export const RED5 = '#FF4D4F';
  export const RED6 = '#F5222D';
  export const RED7 = '#CF1322';
  export const RED8 = '#A8071A';
  export const RED9 = '#820014';
  export const RED10 = '#5C0011';
  export const ORANGE1 = '#FFF7E6';
  export const ORANGE2 = '#FFE7BA';
  export const ORANGE3 = '#FFD591';
  export const ORANGE4 = '#FFC069';
  export const ORANGE5 = '#FFA940';
  export const ORANGE6 = '#FA8C16';
  export const ORANGE7 = '#D46B08';
  export const ORANGE8 = '#AD4E00';
  export const ORANGE9 = '#873800';
  export const ORANGE10 = '#612500';
}

export default Colors;
