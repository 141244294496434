import * as React from 'react';

import { Edit, ShowButton, TopToolbar } from 'react-admin';
import { useLocation } from 'react-router-dom';

import TabbedCreateEditForm from './TabbedCreateEditForm';

const Actions = () => {
  const location = useLocation();
  const [tabNumber, setTabNumber] = React.useState<number>(0);

  React.useEffect(() => {
    const split = location?.pathname?.split('/') || [];
    let tabNumber = split.length > 3 ? Number.parseInt(split.pop() || '', 10) : NaN;
    if (tabNumber > 6) tabNumber = 0;
    setTabNumber(tabNumber);
  }, [location]);

  return (
    <TopToolbar>
      <ShowButton
        key={`${tabNumber}`}
        to={`./show${!Number.isNaN(tabNumber) && tabNumber !== 0 ? `/${tabNumber}` : ''}`}
      />
    </TopToolbar>
  );
};

export const OfferEdit = () => (
  <Edit redirect='list' actions={<Actions />}>
    <TabbedCreateEditForm isEditMode />
  </Edit>
);

export default OfferEdit;
