import React, { useState } from 'react';

import { Notification, useLogin, useNotify } from 'react-admin';

import Logo from '../../assets/Logo.svg';
import { PrimaryButton } from '../../lib/Buttons';
import Input from '../../lib/Inputs';
import { mainDiv, childDiv } from './Styles';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const login = useLogin();
  const notify = useNotify();

  const onSubmit = () => {
    login({ email, password }).catch(() => notify('Invalid email or password', { type: 'error' }));
  };

  return (
    <div className={mainDiv}>
      <div className={childDiv}>
        <img src={Logo} alt='Logo' />
        <br />
        <form>
          <Input
            id='outlined-basic'
            label='Email'
            variant='outlined'
            defaultValue={email}
            onChange={(event: any) => setEmail(event.target.value)}
            style={{ marginTop: 15, minWidth: 250 }}
          />
          <br />
          <Input
            type={'password'}
            id='outlined-basic-password'
            label='Password'
            variant='outlined'
            defaultValue={password}
            onChange={(event: any) => setPassword(event.target.value)}
            style={{ marginTop: 15, minWidth: 250 }}
          />
          <br />
          <PrimaryButton style={{ marginTop: 15, minWidth: 250 }} onClick={onSubmit}>
            Log In
          </PrimaryButton>
          <Notification />
        </form>
      </div>
    </div>
  );
};

export default Login;
