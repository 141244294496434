import React, { useState } from 'react';

import { CheckCircle } from '@mui/icons-material';
import { Confirm, useNotify, useRefresh, useUpdate } from 'react-admin';

import Colors from '../../../../../constants/colors';
import Styles from '../../../../../constants/styles';

const ContinueApplication = ({ accountId }: { accountId?: number }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const [update] = useUpdate();

  const handleContinueApplication = () => {
    setConfirmOpen(true);
  };

  const confirmAction = () => {
    setBtnDisabled(true);
    update(
      'application.CONTINUE',
      { data: { accountId } },
      {
        onError: (error: any) => {
          notify(error.message ?? 'An uncaught error occurred!', { type: 'error' });
        },
        onSuccess: () => {
          notify('Application continued successfully', { type: 'success' });
          refresh();
        },
        onSettled: () => {
          setBtnDisabled(false);
          setConfirmOpen(false);
        },
      },
    );
  };

  return (
    <>
      <button
        className='customButton'
        style={Styles.achRelationship.menuItem}
        onClick={() => handleContinueApplication()}>
        <CheckCircle style={{ color: Colors.SUCCESS }} />
        <span style={{ color: Colors.SUCCESS_DARK, paddingLeft: 5 }}>CONTINUE APPLICATION</span>
      </button>
      <Confirm
        isOpen={confirmOpen}
        loading={btnDisabled}
        title={'Continue Application'}
        content={'Are you sure you want to continue this application ?'}
        onConfirm={confirmAction}
        onClose={() => setConfirmOpen(false)}
      />
    </>
  );
};
export default ContinueApplication;
