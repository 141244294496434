import * as React from 'react';

import { Grid } from '@mui/material';
import { FunctionField, Labeled, SelectField, SimpleShowLayout, TextField } from 'react-admin';
import { Row, Col } from 'react-grid-system';
import { StatusTag } from 'src/lib';
import { FileViewerModal } from 'src/lib/FileViewerModal/FileViewerModal';
import { User } from 'src/models';
import { getAccountStatusTagPalette } from 'src/utils';

import { accountType } from '../../../../constants/choices/user.choices';
import { AccountDto } from '../../../../dtos/accounts.dtos';
import { SyncAccountsStatusButton } from '../SyncAcountsStatusButton/SyncAccountsStatusButton';
import { AccountAgreement } from './AccountAgreement';

export const AccountShow = (record: AccountDto) => {
  const { id } = record;
  const [viewDocument, setViewDocument] = React.useState<{ url: string; type: 'pdf' } | null>(null);

  const onView = (document: { url: string; type: 'pdf' }) => {
    setViewDocument(document);
  };

  const onFileViewerModalClose = () => {
    setViewDocument(null);
  };

  return (
    <div className='showContent'>
      <Row>
        <Col sm={12}>
          <h2 className='title'>Account Information</h2>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Labeled label='Account Id'>
            <TextField source='accountId' record={record} />
          </Labeled>
        </Col>

        <Col md={4}>
          <Labeled label='Account Number'>
            <TextField source='accountNumber' record={record} />
          </Labeled>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <Labeled label='Account Type'>
            <SelectField source='type' record={record} choices={accountType} />
          </Labeled>
        </Col>
        <Grid item xs={2}>
          <SimpleShowLayout>
            <FunctionField
              label='Account Status'
              render={(record: User) => {
                const { account } = record;

                return (
                  <>
                    <StatusTag
                      value={account?.status.label ?? 'Not Submitted'}
                      style={getAccountStatusTagPalette(account?.status.value ?? 'NotSubmitted')}
                    />
                    {Boolean(account?.accountId) && <SyncAccountsStatusButton ids={[id]} label={'Account Status'} />}
                  </>
                );
              }}
            />
          </SimpleShowLayout>
        </Grid>
      </Row>
      <Col md={12} className='hoverItem'>
        <Row>
          <AccountAgreement id={id} onView={onView} />
        </Row>
      </Col>
      {viewDocument && <FileViewerModal onClose={onFileViewerModalClose} file={viewDocument} />}
    </div>
  );
};
