import { PrivateQuoteUrlConstant } from 'src/constants';
import {
  CreatePrivateQuoteDto,
  DeletePrivateQuoteDto,
  GetPrivateQuoteDto,
  GetPrivateQuoteListDto,
  GetPrivateQuoteListResponseDto,
  PatchPrivateQuoteDto,
  PrivateQuoteDto,
  SnapPrivateQuoteListDto,
  SnapPrivateQuoteListResponseDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class PrivateQuotesApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async create({ body }: CreatePrivateQuoteDto): Promise<PrivateQuoteDto> {
    return httpRequest<PrivateQuoteDto>({
      method: 'POST',
      url: PrivateQuoteUrlConstant.LIST,
      body,
      authToken: this._configService.authToken,
    });
  }

  async list({ queryParams }: GetPrivateQuoteListDto): Promise<GetPrivateQuoteListResponseDto> {
    return httpRequest<GetPrivateQuoteListResponseDto>({
      method: 'GET',
      url: PrivateQuoteUrlConstant.LIST,
      queryParams,
      authToken: this._configService.authToken,
    });
  }

  async retrieve({ params }: GetPrivateQuoteDto): Promise<PrivateQuoteDto> {
    const url = replacePlaceholders(PrivateQuoteUrlConstant.RETRIEVE, params);

    return httpRequest<PrivateQuoteDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async patch({ params, body }: PatchPrivateQuoteDto): Promise<PrivateQuoteDto> {
    const url = replacePlaceholders(PrivateQuoteUrlConstant.PATCH, params);

    return httpRequest<PrivateQuoteDto>({
      method: 'PATCH',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }

  async delete({ params }: DeletePrivateQuoteDto): Promise<void> {
    const url = replacePlaceholders(PrivateQuoteUrlConstant.RETRIEVE, params);

    await httpRequest<PrivateQuoteDto>({
      method: 'DELETE',
      url,
      authToken: this._configService.authToken,
    });
  }

  async snap({ queryParams }: SnapPrivateQuoteListDto): Promise<SnapPrivateQuoteListResponseDto> {
    return await httpRequest<SnapPrivateQuoteListResponseDto>({
      method: 'GET',
      url: PrivateQuoteUrlConstant.SNAP,
      queryParams,
      authToken: this._configService.authToken,
    });
  }
}
