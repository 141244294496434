import { Stack } from '@mui/material';
import { AutocompleteArrayInput, FilterButton, FilterForm, useListContext } from 'react-admin';

import { states } from '../../constants/choices/ach.transfers.choices';

const Toolbar = ({ filter = {}, setFilter = function () {} }: any) => {
  const transactionFilters = [<AutocompleteArrayInput key='state' source='state' choices={states} />];
  const { displayedFilters, hideFilter } = useListContext();

  return (
    <Stack direction='row' justifyContent='space-between'>
      <FilterForm
        hideFilter={(data: any) => {
          let t_filters: any = { ...filter };
          delete t_filters[data];
          setFilter(t_filters);
          hideFilter(data);
        }}
        displayedFilters={displayedFilters}
        filterValues={filter}
        setFilters={(data: any) => {
          setFilter(data);
        }}
        filters={transactionFilters}
      />
      <div>
        <FilterButton filters={transactionFilters} />
      </div>
    </Stack>
  );
};
export default Toolbar;
