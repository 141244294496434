import {
  TradingBlockOrderActionNumberEnum,
  TradingBlockOrderStatusDto,
  TradingBlockOrderStatusIntDto,
  TradingBlockOrderTypeNumberEnum,
} from '../dtos/orders.dtos';

export enum OrderStatusLabel {
  PROCESSING = 'PROCESSING',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

const TradingBlockOrderStatusDtoMapper = new Map<TradingBlockOrderStatusIntDto, TradingBlockOrderStatusDto>([
  [TradingBlockOrderStatusIntDto.Undefined, TradingBlockOrderStatusDto.NA],
  [TradingBlockOrderStatusIntDto.New, TradingBlockOrderStatusDto.New],
  [TradingBlockOrderStatusIntDto.PartiallyFilled, TradingBlockOrderStatusDto.PartiallyFilled],
  [TradingBlockOrderStatusIntDto.Filled, TradingBlockOrderStatusDto.Filled],
  [TradingBlockOrderStatusIntDto.DoneForDay, TradingBlockOrderStatusDto.DoneForDay],
  [TradingBlockOrderStatusIntDto.Cancelled, TradingBlockOrderStatusDto.Cancelled],
  [TradingBlockOrderStatusIntDto.Replaced, TradingBlockOrderStatusDto.Replaced],
  [TradingBlockOrderStatusIntDto.PendingCancel, TradingBlockOrderStatusDto.PendingCancel],
  [TradingBlockOrderStatusIntDto.Stopped, TradingBlockOrderStatusDto.Stopped],
  [TradingBlockOrderStatusIntDto.Rejected, TradingBlockOrderStatusDto.Rejected],
  [TradingBlockOrderStatusIntDto.Suspended, TradingBlockOrderStatusDto.Suspended],
  [TradingBlockOrderStatusIntDto.PendingNew, TradingBlockOrderStatusDto.PendingNew],
  [TradingBlockOrderStatusIntDto.Calculated, TradingBlockOrderStatusDto.Calculated],
  [TradingBlockOrderStatusIntDto.Expired, TradingBlockOrderStatusDto.Expired],
  [TradingBlockOrderStatusIntDto.PendingReplace, TradingBlockOrderStatusDto.PendingReplace],
  [TradingBlockOrderStatusIntDto.Saved, TradingBlockOrderStatusDto.Saved],
  [TradingBlockOrderStatusIntDto.LiveUntriggered, TradingBlockOrderStatusDto.LiveUntriggered],
  [TradingBlockOrderStatusIntDto.Scheduled, TradingBlockOrderStatusDto.Scheduled],
  [TradingBlockOrderStatusIntDto.OCO_Untriggered, TradingBlockOrderStatusDto.OCO_Untriggered],
  [TradingBlockOrderStatusIntDto.CancelledUntriggered, TradingBlockOrderStatusDto.CancelledUntriggered],
  [TradingBlockOrderStatusIntDto.Initiated, TradingBlockOrderStatusDto.Initiated],
  [TradingBlockOrderStatusIntDto.ReplaceInitiated, TradingBlockOrderStatusDto.ReplaceInitiated],
  [TradingBlockOrderStatusIntDto.CancelInitiated, TradingBlockOrderStatusDto.CancelInitiated],
  [TradingBlockOrderStatusIntDto.CancelRejected, TradingBlockOrderStatusDto.CancelRejected],
  [TradingBlockOrderStatusIntDto.ReplaceRejected, TradingBlockOrderStatusDto.ReplaceRejected],
  [TradingBlockOrderStatusIntDto.Busted, TradingBlockOrderStatusDto.Busted],
  [TradingBlockOrderStatusIntDto.PreAllocated, TradingBlockOrderStatusDto.PreAllocated],
  [TradingBlockOrderStatusIntDto.Acknowledged, TradingBlockOrderStatusDto.Acknowledged],
  [TradingBlockOrderStatusIntDto.LiveUntriggered, TradingBlockOrderStatusDto.LiveUntriggered],
  [TradingBlockOrderStatusIntDto.PartiallyCancelled, TradingBlockOrderStatusDto.PartiallyCancelled],
]);

export class OrderStatus {
  private _value: TradingBlockOrderStatusDto;
  private _label: OrderStatusLabel;

  constructor(status?: TradingBlockOrderStatusIntDto) {
    this._value = status
      ? TradingBlockOrderStatusDtoMapper.get(status) ?? TradingBlockOrderStatusDto.NA
      : TradingBlockOrderStatusDto.NA;
    this._label = this.findLabel();
  }

  private findLabel(): OrderStatusLabel {
    switch (this._value) {
      case TradingBlockOrderStatusDto.New:
      case TradingBlockOrderStatusDto.PartiallyFilled:
      case TradingBlockOrderStatusDto.Calculated:
      case TradingBlockOrderStatusDto.DoneForDay:
      case TradingBlockOrderStatusDto.Saved:
      case TradingBlockOrderStatusDto.Acknowledged:
        return OrderStatusLabel.PROCESSING;
      case TradingBlockOrderStatusDto.PendingNew:
      case TradingBlockOrderStatusDto.PendingCancel:
      case TradingBlockOrderStatusDto.Scheduled:
      case TradingBlockOrderStatusDto.CancelInitiated:
      case TradingBlockOrderStatusDto.Stopped:
        return OrderStatusLabel.PENDING;
      case TradingBlockOrderStatusDto.Rejected:
      case TradingBlockOrderStatusDto.ReplaceRejected:
      case TradingBlockOrderStatusDto.CancelRejected:
      case TradingBlockOrderStatusDto.Suspended:
      case TradingBlockOrderStatusDto.Expired:
        return OrderStatusLabel.REJECTED;
      case TradingBlockOrderStatusDto.Filled:
        return OrderStatusLabel.COMPLETED;
      case TradingBlockOrderStatusDto.Cancelled:
      case TradingBlockOrderStatusDto.PartiallyCancelled:
        return OrderStatusLabel.CANCELLED;
      default:
        return OrderStatusLabel.PROCESSING;
    }
  }

  get value(): TradingBlockOrderStatusDto {
    return this._value;
  }

  get label(): OrderStatusLabel {
    return this._label;
  }

  get isPending() {
    return this._label === OrderStatusLabel.PENDING;
  }

  get isCompleted() {
    return this._label === OrderStatusLabel.COMPLETED;
  }

  get isCancelled() {
    return this._label === OrderStatusLabel.CANCELLED;
  }

  get isPendingNew() {
    return this._value === TradingBlockOrderStatusDto.PendingNew;
  }

  get isRejected() {
    return this._value === TradingBlockOrderStatusDto.Rejected;
  }

  // TODO: introduce new order status Partially Cancelled
  get isPartiallyCancelled() {
    return this._value === TradingBlockOrderStatusDto.PartiallyCancelled;
  }

  isCancellable() {
    return CANCEL_ORDER_STATUS_LIST.includes(this._value);
  }
}

export class OrderType {
  private _value: TradingBlockOrderTypeNumberEnum;

  constructor(type: TradingBlockOrderTypeNumberEnum) {
    this._value = type;
  }

  get value(): TradingBlockOrderTypeNumberEnum {
    return this._value;
  }

  get label(): string {
    return OrderTypeNumberLabelMapper.get(this.value) ?? 'NA';
  }
}

export class OrderAction {
  private _value: TradingBlockOrderActionNumberEnum;

  constructor(action?: TradingBlockOrderActionNumberEnum) {
    this._value = action ?? TradingBlockOrderActionNumberEnum.Undefined;
  }

  get value(): TradingBlockOrderActionNumberEnum {
    return this._value;
  }

  get label(): string {
    return OrderActionNumberLabelMapper.get(this.value) ?? 'NA';
  }

  get isBuy(): boolean {
    return this._value === TradingBlockOrderActionNumberEnum.Buy;
  }

  get isSell(): boolean {
    return this._value === TradingBlockOrderActionNumberEnum.Sell;
  }
}

export interface OrderPosition {
  id: string;
  symbol: string;
  name?: string;
  quantity: number;
  purchasePrice: number;
  totalCost: number;
  isTradable: boolean;
  value?: number;
  totalValue?: number;
  change?: number;
}

export interface Order {
  id: string;
  symbol: string;
  description: string;
  updatedAt: string;
  quantity: number;
  filledQuantity: number;
  isPartial: boolean;
  price: number;
  totalCost: number;
  type: OrderType;
  action: OrderAction;
  status: OrderStatus;
  commission: number;
  limit?: number;
  stop?: number;
}

// TODO: confirm pending order statuses
export const CANCEL_ORDER_STATUS_LIST = [
  TradingBlockOrderStatusDto.New,
  TradingBlockOrderStatusDto.PendingNew,
  TradingBlockOrderStatusDto.PendingReplace,
  TradingBlockOrderStatusDto.Scheduled,
  TradingBlockOrderStatusDto.Initiated,
  TradingBlockOrderStatusDto.ReplaceInitiated,
];

export const OrderTypeNumberLabelMapper = new Map<TradingBlockOrderTypeNumberEnum, string>([
  [TradingBlockOrderTypeNumberEnum.Undefined, 'NA'],
  [TradingBlockOrderTypeNumberEnum.Market, 'Market Order'],
  [TradingBlockOrderTypeNumberEnum.Limit, 'Limit Order'],
  [TradingBlockOrderTypeNumberEnum.Stop_Market, 'Stop Market Order'],
  [TradingBlockOrderTypeNumberEnum.Stop_Limit, 'Stop Limit Order'],
  [TradingBlockOrderTypeNumberEnum.Market_On_Close, 'Market on Close Order'],
]);

export const OrderActionNumberLabelMapper = new Map<TradingBlockOrderActionNumberEnum, string>([
  [TradingBlockOrderActionNumberEnum.Undefined, 'NA'],
  [TradingBlockOrderActionNumberEnum.Buy, 'Buy'],
  [TradingBlockOrderActionNumberEnum.Sell, 'Sell'],
]);
