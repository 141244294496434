import * as React from 'react';

import { Grid } from '@mui/material';
import _ from 'lodash';
import { BooleanInput, FormDataConsumer, required, TextInput } from 'react-admin';

interface BankLinkCreateEditProps {
  isEditMode?: boolean;
}

export const BankLinkCreateEdit = ({ isEditMode = false }: BankLinkCreateEditProps) => (
  <Grid container direction='row' spacing={2}>
    <Grid item xs={12}>
      <h2>{isEditMode ? 'Edit' : 'Add'} Bank Link Configuration</h2>
    </Grid>
    <Grid item xs={12} md={6}>
      <BooleanInput
        label='Disable Bank Linkage in Funnel'
        source='appConfig.bankLink.disableInFunnel'
        validate={[required()]}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData?.appConfig?.bankLink?.disableInFunnel && (
            <TextInput
              {...rest}
              source='appConfig.bankLink.funnelDisclaimer'
              label='Funnel Alert Text'
              validate={value => (_.isEmpty(value) ? 'A disclaimer text needs to be provided' : null)}
              fullWidth
            />
          )
        }
      </FormDataConsumer>
    </Grid>
    <Grid item xs={12} md={6}>
      <BooleanInput
        label='Disable Bank Linkage in Mobile'
        source='appConfig.bankLink.disableInMobile'
        validate={[required()]}
        fullWidth
      />
    </Grid>
    <Grid item xs={12} md={6}>
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData?.appConfig?.bankLink?.disableInMobile && (
            <TextInput
              {...rest}
              source='appConfig.bankLink.mobileDisclaimer'
              label='Mobile Alert Text'
              validate={value => (_.isEmpty(value) ? 'A disclaimer text needs to be provided' : null)}
              fullWidth
            />
          )
        }
      </FormDataConsumer>
    </Grid>
  </Grid>
);
export default BankLinkCreateEdit;
