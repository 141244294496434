import { Grid } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';
import { ArrayInput, FormDataConsumer, required, SelectInput, SimpleFormIterator } from 'react-admin';
import { useWatch } from 'react-hook-form';
import { OfferOrderConstant } from 'src/constants';

interface InfoCreateEditProps {
  isEditMode?: boolean;
}

export const OfferEmailsCreateOrEdit = ({ isEditMode = false }: InfoCreateEditProps) => {
  const orderStatusUpdateEmailFields: { status: string; line: string; footer?: string }[] | undefined = useWatch({
    name: 'orderStatusUpdateEmailFields',
  });

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <h2>{isEditMode ? 'Edit' : 'Add'} Offer Emails</h2>
      </Grid>
      <Grid item xs={12} md={12}>
        <h2>Offer Order Status Update Email</h2>
      </Grid>
      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
        <ArrayInput source='orderStatusUpdateEmailFields' label='Offer Order Status Update Email Fields' fullWidth>
          <SimpleFormIterator fullWidth inline>
            <FormDataConsumer>
              {({ getSource, scopedFormData, ...rest }) => {
                return (
                  <SelectInput
                    source={getSource?.('status')}
                    choices={OfferOrderConstant.STATUS_OPTION_LIST.filter(
                      aStatus =>
                        !orderStatusUpdateEmailFields?.some(
                          aField => aField.status === aStatus.id && scopedFormData?.status !== aField.status,
                        ),
                    )}
                    {...rest}
                    validate={required()}
                  />
                );
              }}
            </FormDataConsumer>
            <RichTextInput source='line' label='Line' validate={required()} fullWidth />
            <RichTextInput source='footer' label='Footer' fullWidth />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
    </Grid>
  );
};
