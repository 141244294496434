import _ from 'lodash';
import { GetListParams, GetListResult } from 'react-admin';
import { getClientApi } from 'src/data-communication';
import { mapGetSnapQuotesListResponseDtoToModel } from 'src/mappers';
import { SnapQuote } from 'src/models';

const clientApi = getClientApi();

export const getSnapQuoteList = async (params: Partial<GetListParams>): Promise<GetListResult<SnapQuote>> => {
  const { symbols } = params.meta;

  const result = await clientApi.snapQuotes.list({ params: { symbols: _.uniq(symbols).join(',') } });

  return {
    data: mapGetSnapQuotesListResponseDtoToModel(result.quotedata),
    total: result.quotedata.length,
  };
};
