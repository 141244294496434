import { BaseListResponseDto, PaginationQueryParams, SortDirectionQueryParams } from './common.dtos';

export interface OfferWatchDto {
  id: string;
  offerId: string;
  offer: {
    name: string;
    statusId: string;
  };
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  stateCode: string;
  userId: string;
  investmentRange: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface BaseOfferWatchParamsDto {
  id: string;
}

export interface CreateOfferWatchBodyDto {
  offerId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  stateCode: string;
  userId: string;
  investmentRange: string;
}

export interface CreateOfferWatchDto {
  body: CreateOfferWatchBodyDto;
}

export enum GetAllOfferWatchListSortByQueryParamDto {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  STATE_CODE = 'stateCode',
  INVESTMENT_RANGE = 'investmentRange',
  CREATED_AT = 'createdAt',
}

export interface GetAllOfferWatchListQueryParamsDto
  extends Partial<SortDirectionQueryParams>,
    Partial<PaginationQueryParams> {
  offerId?: string;
  email?: string;
  active?: string;
  sortBy: GetAllOfferWatchListSortByQueryParamDto;
}

export interface GetAllOfferWatchListDto {
  queryParams?: GetAllOfferWatchListQueryParamsDto;
}

export interface GetAllOfferWatchListResponseDto extends BaseListResponseDto<OfferWatchDto[]> {}

export interface GetOfferWatchDto {
  params: BaseOfferWatchParamsDto;
}

export interface DeleteOfferWatchDto {
  params: BaseOfferWatchParamsDto;
}
