import {
  AchRelationshipDetailsDto,
  AchRelationshipDto,
  SearchAchRelationshipItemDto,
  UnformattedGetAchRelationshipListResponseDto,
} from 'src/dtos';
import { AchRelationship, AchRelationshipDetails, SearchAchRelationshipItem } from 'src/models';

export const mapAchRelationshipDtoToModel = (dto: AchRelationshipDto): AchRelationship => ({
  ...dto,
  id: dto.id.toString(),
});

export const mapAchRelationshipDetailsDtoToModel = (dto: AchRelationshipDetailsDto): AchRelationshipDetails => ({
  ...dto,
  id: dto.relationshipId.toString(),
});

export const mapSearchAchRelationshipListDtoToModel = (
  inbound: SearchAchRelationshipItemDto[],
): SearchAchRelationshipItem[] =>
  inbound.map(item => ({
    ...item,
    id: item.relationship.relationshipId,
    approvalMethod: item.relationship.approvalMethod,
    bankAccountOwnerNameSecret: item.relationship.bankAccountOwnerNameSecret,
    bankName: item.relationship.bankName,
    relationshipId: item.relationship.relationshipId,
    bankAccount: item.relationship.text,
  }));

export const mapGetAchRelationshipListResponseDtoToModel = (
  inbound: UnformattedGetAchRelationshipListResponseDto,
): AchRelationship[] =>
  inbound.map(relationship =>
    mapAchRelationshipDtoToModel({
      ...relationship.tradingBlockACHRelationship,
      relationshipId: relationship.tradingBlockACHRelationship.id,
      status: relationship.tradingBlockACHRelationship.state ?? '',
    }),
  );
