import { Grid } from '@mui/material';
import { NumberField, Show, SimpleShowLayout, TextField } from 'react-admin';

export const PrivateQuoteShow = () => (
  <Show>
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>Private Quote</h2>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <TextField label='Symbol/Ticker' source='symbol' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <TextField label='Name' source='name' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <NumberField
            label='Purchase Price'
            source='purchasePrice'
            options={{ style: 'currency', currency: 'USD' }}
            fullWidth
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <NumberField
            label='Current Price'
            source='currentPrice'
            options={{ style: 'currency', currency: 'USD' }}
            fullWidth
          />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  </Show>
);
