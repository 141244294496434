import { Chip } from '@mui/material';
import { Datagrid, DateField, FunctionField, List, TextField } from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { OfferOrderCardPayment } from 'src/models/offerOrders.models';
import { getOfferOrderPaymentStatusColor } from 'src/utils';
import { getCurrencyFormatter } from 'src/utils/getCurrenyFormatter';

export interface AccountOfferOrderCardPaymentListProps {
  accountId: number;
}

export const AccountOfferOrderCardPaymentList = ({ accountId }: AccountOfferOrderCardPaymentListProps) => {
  return (
    <List
      actions={false}
      exporter={false}
      disableSyncWithLocation
      resource={DataProviderResource.OfferOrderCardPayments}
      title=' '
      empty={false}
      filter={{ accountId }}>
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          label='Amount'
          sortable={false}
          render={(record: OfferOrderCardPayment) => {
            return (
              <div>
                <span style={{ marginRight: '4px' }}>{getCurrencyFormatter().format(record.amount)}</span>
                <Chip
                  size='small'
                  label={record.intentStatus.label ?? 'NA'}
                  color={getOfferOrderPaymentStatusColor(record.intentStatus.value)}
                />
              </div>
            );
          }}
        />
        <TextField source='intentId' label='Description' sortable={false} />
        <TextField source='cardholder' label='Customer' sortable={false} />
        <DateField source='createdAt' label='Date' sortable={false} />
      </Datagrid>
    </List>
  );
};
