import { Dispatch, SetStateAction } from 'react';

import { AchRelationshipActionType } from 'src/constants/choices/ach.relationships.choices';
import { AchRelationship, AchRelationshipDetails, SearchAchRelationshipItem } from 'src/models';

import ACHRelationship from '.';

export interface ActionModalProps {
  curElement: SearchAchRelationshipItem | AchRelationship | AchRelationshipDetails;
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  type: AchRelationshipActionType;
}

const ActionModals = (props: ActionModalProps) => {
  const { curElement, modalOpen, setModalOpen, type } = props;

  return (
    <>
      <ACHRelationship.DecideRelationship
        element={curElement}
        type={type}
        openModal={modalOpen}
        modalClosed={() => {
          setModalOpen(false);
        }}
      />
    </>
  );
};

export default ActionModals;
