import * as React from 'react';

import { Grid } from '@mui/material';
import { Edit, required, SelectInput, SimpleForm, TextInput, useStore } from 'react-admin';

import { offerOptions } from '../../constants/choices/offer.options.choices';

export const OfferOptionEdit = () => {
  const [offerOptionSelected] = useStore('offer-options.selected');

  return (
    <Edit redirect='list' queryOptions={{ meta: { type: offerOptionSelected.type } }}>
      <SimpleForm>
        <Grid container direction='row' justifyContent='space-between' spacing={2}>
          <Grid item xs={12}>
            <h2>Edit offer options</h2>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput source='type' choices={offerOptions} fullWidth disabled />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source='name' validate={[required()]} fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};

export default OfferOptionEdit;
