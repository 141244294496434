type LogLevel = 'debug' | 'info' | 'warn' | 'error';

export interface LogOptionsArgs {
  level?: LogLevel;
}

export class LogOptions {
  private _level: LogLevel;
  private LOG_LEVEL_COLOR: Record<LogLevel, string> = {
    debug: '\x1b[34m', // Blue
    info: '\x1b[32m', // Green
    warn: '\x1b[33m', // Yellow
    error: '\x1b[31m', // Red
  };
  private RESET_LOG_COLOR = '\x1b[0m'; // Reset color

  constructor({ level = 'info' }: LogOptionsArgs) {
    this._level = level;
  }

  get level(): LogLevel {
    return this._level;
  }

  color(type: LogLevel) {
    return this.LOG_LEVEL_COLOR[type] || this.RESET_LOG_COLOR;
  }

  get timestamp(): string {
    const now = new Date();

    return now.toISOString();
  }
}

let logOptions = new LogOptions({ level: process.env.NODE_ENV === 'production' ? 'info' : 'debug' });

const getLogOptions = () => logOptions;

export class Logger {
  private static log({ messages, type }: { messages: any[]; type: LogLevel }) {
    const logOptions = getLogOptions();
    // eslint-disable-next-line no-console
    console[type](`${logOptions.color(type)}[${logOptions.timestamp}] [${type.toUpperCase()}]`, ...messages);
  }

  static debug(...messages: any[]) {
    if (getLogOptions().level !== 'debug') {
      return;
    }
    Logger.log({ messages, type: 'debug' });
  }

  static info(...messages: any[]) {
    Logger.log({ messages, type: 'info' });
  }

  static warn(...messages: any[]) {
    Logger.log({ messages, type: 'warn' });
  }

  static error(...messages: any[]) {
    Logger.log({ messages, type: 'error' });
  }
}
