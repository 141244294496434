import { OfferOrderStatusDto } from 'src/dtos';
import { OfferOrderStatusLabel } from 'src/models';

export namespace OfferOrderConstant {
  export const STATUS_OPTION_LIST = [
    { id: OfferOrderStatusDto.PendingFunds, name: OfferOrderStatusLabel.PendingFunds },
    { id: OfferOrderStatusDto.PendingOfferClose, name: OfferOrderStatusLabel.PendingOfferClose },
    { id: OfferOrderStatusDto.PendingAction, name: OfferOrderStatusLabel.PendingAction },
    { id: OfferOrderStatusDto.Approved, name: OfferOrderStatusLabel.Approved },
    { id: OfferOrderStatusDto.Rejected, name: OfferOrderStatusLabel.Rejected },
    { id: OfferOrderStatusDto.Deleted, name: OfferOrderStatusLabel.Deleted },
    { id: OfferOrderStatusDto.Complete, name: OfferOrderStatusLabel.Complete },
    { id: OfferOrderStatusDto.PendingFirmCancellation, name: OfferOrderStatusLabel.PendingFirmCancellation },
    { id: OfferOrderStatusDto.Cancelled, name: OfferOrderStatusLabel.Cancelled },
  ];
}
