import * as React from 'react';

import {
  Datagrid,
  DateField,
  EmailField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';

import ActiveField from '../Fields/ActiveField';

const defaultSort = { field: 'createdAt', order: 'DESC' };

const IndicationOfInterestsFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput label='Offer' source='offerId' reference='offers' alwaysOn perPage={100} fullWidth>
      <SelectInput label='Offer' fullWidth optionText='name' />
    </ReferenceInput>
    <TextInput label='Email' source='email' alwaysOn />
    <SelectInput
      label='Active Status'
      source='active'
      alwaysOn
      choices={[
        { id: 'Active', name: 'Active' },
        { id: 'Canceled', name: 'Canceled' },
      ]}
    />
  </Filter>
);

const IndicationOfInterestsList = () => (
  <List title='Indication of Interests' sort={defaultSort} filters={<IndicationOfInterestsFilter />}>
    <Datagrid rowClick='show' bulkActionButtons={false}>
      <ReferenceField source='offerId' reference='offers' sortBy='name' sortable={false} link={'show'}>
        <TextField label='Offer' source='name' fullWidth sortBy='name' />
      </ReferenceField>
      <TextField source='firstName' />
      <TextField source='lastName' />
      <EmailField source='email' />
      <TextField source='phoneNumber' />
      <TextField source='stateCode' />
      <TextField source='investmentRange' />
      <ActiveField source='active' label='Active Status' />
      <DateField source='createdAt' label='Created At' showTime />
    </Datagrid>
  </List>
);

export default IndicationOfInterestsList;
