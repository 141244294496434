import React from 'react';

import { DateField, FunctionField, Labeled, SelectField, Show, TextField } from 'react-admin';
import { Row, Col } from 'react-grid-system';

import ACHTransfers from '.';
import { direction } from '../../constants/choices/ach.transfers.choices';
import StatusField from '../Fields/StatusField';

const ACHTransferShow = () => {
  const renderNotes = (record: any) => {
    return (
      record.adminNotes?.map((anAdminNote: { id: string; text: string; createDate: string; createBy: string }) => {
        return (
          <li key={anAdminNote.id}>
            {anAdminNote.text} (created on: {anAdminNote.createDate}, created by: {anAdminNote.createBy})
          </li>
        );
      }) ?? <li>NA</li>
    );
  };

  return (
    <>
      <Show>
        <div className='showContent'>
          <Row>
            <Col sm={12}>
              <h2 className='title'>ACH Transfer Details</h2>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='ID'>
                <TextField source='id' />
              </Labeled>
            </Col>

            <Col md={6}>
              <Labeled label='Account Number'>
                <TextField source='accountNumber' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Account Name'>
                <TextField source='accountName' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Account Title'>
                <TextField source='accountTitle' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Amount'>
                <TextField source='amount' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Direction'>
                <SelectField source='direction' choices={direction} />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Bank Name'>
                <TextField source='bankName' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Bank Account Owner Name Secret'>
                <TextField source='bankAccountOwnerNameSecret' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Mechanism'>
                <TextField source='mechanism' />
              </Labeled>
            </Col>

            <Col md={6}>
              <Labeled label='State'>
                <StatusField
                  source='state'
                  success={['Approved', 'FundsPosted', 'SentToBank', 'Complete']}
                  warning={[
                    'PendingRepCreateApproval',
                    'PendingFirmCreateApproval',
                    'Pending',
                    'FundsHeld',
                    'PendingPrinting',
                    'Postponed',
                    'Requested',
                  ]}
                  fail={[
                    'Unknown',
                    'Rejected',
                    'Canceled',
                    'Void',
                    'Returned',
                    'StopPayment',
                    'CreateRejected',
                    'FailedAtBank',
                  ]}
                />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Bank Account'>
                <TextField source='text' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Create Date'>
                <DateField showTime source='date' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6} style={{ paddingTop: 0 }}>
              <Labeled label='Notes'>
                <FunctionField render={(record: any) => <ul>{renderNotes(record)}</ul>} />
              </Labeled>
            </Col>
            <Col md={6} style={{ paddingTop: 0 }}>
              <Labeled label='Admin Notes'>
                <FunctionField render={(record: any) => <ul>{renderNotes(record)}</ul>} />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ACHTransfers.Actions />
            </Col>
          </Row>
        </div>
      </Show>
    </>
  );
};
export default ACHTransferShow;
