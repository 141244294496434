import React, { useEffect, useState } from 'react';

import { CheckCircle, Cancel, Edit } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { AchRelationshipActionType } from 'src/constants/choices/ach.relationships.choices';
import { AchRelationship, AchRelationshipDetails, SearchAchRelationshipItem } from 'src/models';

import Colors from '../../constants/colors';
import Styles from '../../constants/styles';
import ActionModals from './actionModals';

export interface ActionDropdownProps {
  element: SearchAchRelationshipItem | AchRelationship | AchRelationshipDetails;
  anchorElement: HTMLElement | null;
  onClose: () => void;
}

const Actions = ({ element, anchorElement = null, onClose }: ActionDropdownProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(anchorElement);

  useEffect(() => {
    setAnchorEl(anchorElement);
    setIsOpen(true);
  }, [anchorElement]);

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
    onClose();
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [decideModalOpen, setDecideModalOpen] = useState<boolean>(false);
  const [activeType, setActiveType] = useState<AchRelationshipActionType>(AchRelationshipActionType.APPROVE);

  const approveHandle = () => {
    setActiveType(AchRelationshipActionType.APPROVE);
    setDecideModalOpen(true);
    handleClose();
  };

  const rejectHandle = () => {
    setActiveType(AchRelationshipActionType.REJECT);
    setDecideModalOpen(true);
    handleClose();
  };

  const cancelHandle = () => {
    setActiveType(AchRelationshipActionType.CANCEL);
    setDecideModalOpen(true);
    handleClose();
  };

  const editHandle = () => {
    setActiveType(AchRelationshipActionType.EDIT);
    setDecideModalOpen(true);
    handleClose();
  };

  const ActionModalsContent = () => {
    return element ? (
      <ActionModals
        curElement={element}
        modalOpen={decideModalOpen}
        setModalOpen={setDecideModalOpen}
        type={activeType}
      />
    ) : (
      <></>
    );
  };

  const getItems = (status: string) => {
    switch (status) {
      case 'PendingRepCreateApproval':
      case 'PendingFirmCreateApproval':
      case 'PendingCreateApproval':
        return (
          <div>
            <MenuItem style={Styles.achRelationship.menuItem} onClick={() => approveHandle()}>
              <CheckCircle style={{ color: Colors.SUCCESS }} />
              <span style={{ color: Colors.SUCCESS_DARK, paddingLeft: 5 }}>APPROVE</span>
            </MenuItem>
            <MenuItem style={Styles.achRelationship.menuItem} onClick={() => rejectHandle()}>
              <Cancel style={{ color: Colors.DANGER }} />
              <span style={{ color: Colors.DANGER_DARK, paddingLeft: 5 }}>REJECT</span>
            </MenuItem>

            <MenuItem style={Styles.achRelationship.menuItem} onClick={() => editHandle()}>
              <Edit style={{ color: Colors.LIGHTER_MY_IPO_BLUE }} />
              <span style={{ color: Colors.MY_IPO_BLUE, paddingLeft: 5 }}>EDIT</span>
            </MenuItem>
          </div>
        );

      case 'Pending':
        return (
          <div>
            <MenuItem style={Styles.achRelationship.menuItem} onClick={() => cancelHandle()}>
              <Cancel style={{ color: Colors.DANGER }} />
              <span style={{ color: Colors.DANGER_DARK, paddingLeft: 5 }}>CANCEL</span>
            </MenuItem>

            <MenuItem style={Styles.achRelationship.menuItem} onClick={() => editHandle()}>
              <Edit style={{ color: Colors.LIGHTER_MY_IPO_BLUE }} />
              <span style={{ color: Colors.MY_IPO_BLUE, paddingLeft: 5 }}>EDIT</span>
            </MenuItem>
          </div>
        );
      case 'Cancelled':
      case 'Canceled':
        return (
          <div>
            <MenuItem style={{ ...Styles.achRelationship.menuItem, opacity: 0.6 }}>
              <Cancel style={{ color: Colors.DANGER }} />
              <span style={{ color: Colors.DANGER_DARK, paddingLeft: 5 }}>Cancelled</span>
            </MenuItem>
          </div>
        );

      default:
        return (
          <div>
            <MenuItem style={Styles.achRelationship.menuItem} onClick={() => editHandle()}>
              <Edit style={{ color: Colors.LIGHTER_MY_IPO_BLUE }} />
              <span style={{ color: Colors.MY_IPO_BLUE, paddingLeft: 5 }}>EDIT</span>
            </MenuItem>
          </div>
        );
    }
  };
  if (element) {
    return (
      <>
        {anchorEl && (
          <Menu
            id='lock-menu'
            anchorEl={anchorEl}
            open={isOpen}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'lock-button',
              role: 'listbox',
            }}>
            {getItems(element.status)}
          </Menu>
        )}
        <ActionModalsContent />
      </>
    );
  } else return <></>;
};
export default Actions;
