import { format } from 'date-fns';
import { v4 as uuid } from 'uuid';

import { AccountBalanceDto } from '../dtos/accountBalances.dtos';
import {
  OfferOrderAggregateDto,
  OfferOrderCardPaymentDetailsDto,
  OfferOrderCardPaymentDto,
  OfferOrderRefundDto,
  OfferOrderDetailsDto,
  OfferOrderDto,
  OfferOrderSecurityDeliveryOptionDto,
} from '../dtos/offerOrders.dtos';
import { OfferDto } from '../dtos/offers.dtos';
import { UserDto } from '../dtos/users.dtos';
import { AccountBalance } from '../models/accountBalances.models';
import {
  OfferOrder,
  OfferOrderAggregate,
  OfferOrderCardPayment,
  OfferOrderCardPaymentDetails,
  OfferOrderCardPaymentIntentStatus,
  OfferOrderRefund,
  OfferOrderRefundReason,
  OfferOrderRefundStatus,
  OfferOrderDetails,
  OfferOrderExportView,
  OfferOrderPaymentType,
  OfferOrderStatus,
  OfferOrderPaymentStatus,
} from '../models/offerOrders.models';
import { findCashAvailable } from './accountBalances.mappers';

export const replaceSecurityDeliveryOptionTooltipIfFound = (
  securityDeliveryOption?: OfferOrderSecurityDeliveryOptionDto,
): string => {
  if (!securityDeliveryOption) return '';
  if (!securityDeliveryOption.tooltip) return securityDeliveryOption.label;

  let result = securityDeliveryOption.label;
  result = result.replace(/<\/?tooltip>/g, '');

  return result;
};

export const mapOfferOrderDtoToOfferOrderExportView = (
  offerOrders: OfferOrder[],
  offers: OfferDto[],
  users: UserDto[],
  offerOptionsData: any[],
  balanceList: AccountBalance[],
): OfferOrderExportView[] => {
  return offerOrders?.map(item => {
    const offer: OfferDto | undefined = offers.find((offer: any) => offer.id === item.offerId);
    const user: UserDto | undefined = users.find((user: any) => user.id === item.userId);
    const offerType = offerOptionsData.find((offerOption: any) => offerOption.id === offer?.offerTypeId);
    const securityType = offerOptionsData.find((offerOption: any) => offerOption.id === offer?.securityTypeId);
    const offerCategory = offerOptionsData.find((offerOption: any) => offerOption.id === offer?.categoryId);
    const offerIndustry = offerOptionsData.find((offerOption: any) => offerOption.id === offer?.industryId);
    const offerStatus = offerOptionsData.find((offerOption: any) => offerOption.id === offer?.statusId);
    const balance = balanceList.find(aBalance => aBalance.accountId === item.accountId);

    return {
      id: item.id,
      accountNumber: user?.account?.accountNumber || '',
      createdAt: format(new Date(item.createdAt), 'MM/dd/yyyy'),
      offerId: item.offerId,
      offerName: item.offerName,
      price: item.price,
      quantity: item.quantity,
      totalCost: item.totalCost,
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      addressLine1: user?.account?.primaryAccountHolder?.physicalAddress?.address1 || '',
      addressLine2: user?.account?.primaryAccountHolder?.physicalAddress?.address2 || '',
      city: user?.account?.primaryAccountHolder?.physicalAddress?.city || '',
      postalCode: user?.account?.primaryAccountHolder?.physicalAddress?.postalCode || '',
      stateCode: user?.account?.primaryAccountHolder?.physicalAddress?.state || '',
      ssid: user?.account?.primaryAccountHolder?.ssid || '',
      accreditation: item.isAccreditedInvestor ? 'Accredited' : 'Not accredited',
      paymentType: item.paymentType.label,
      orderStatus: item.status.label || '',
      securityDeliveryOption: replaceSecurityDeliveryOptionTooltipIfFound(item.externalDetails?.securityDeliveryOption),
      offerType: offerType?.name || '',
      offerStatus: offerStatus?.name || '',
      offerIndustry: offerIndustry?.name || '',
      offerSector: offer?.sector || '',
      offerCategory: offerCategory?.name || '',
      securityType: securityType?.name || '',
      cashBalance: balance?.cashBalance?.toString() || '',
      cashAvailable: balance?.cashAvailable?.toString() || '',
    };
  });
};

export const mapOfferOrderRefundDtoListToModel = (list: OfferOrderRefundDto[]): OfferOrderRefund[] =>
  list.map(aDto => ({
    id: aDto.id,
    amount: aDto.amount,
    mechanism: aDto.mechanism,
    externalId: aDto.externalId,
    reason: new OfferOrderRefundReason(aDto.reason),
    status: new OfferOrderRefundStatus(aDto.status),
    description: aDto.description,
    failureReason: aDto.failureReason,
    bankName: aDto.bankName,
    adminNote: aDto.adminNote,
    createdAt: aDto.createdAt,
    updatedAt: aDto.updatedAt,
  }));

export const mapOfferOrderCardPaymentDtoToModel = (dto: OfferOrderCardPaymentDto): OfferOrderCardPayment => ({
  ...dto,
  intentStatus: new OfferOrderCardPaymentIntentStatus(dto.intentStatus),
});

export const mapOfferOrderCardPaymentListDtoToModel = (dtoList: OfferOrderCardPaymentDto[]): OfferOrderCardPayment[] =>
  dtoList.map(mapOfferOrderCardPaymentDtoToModel);

export const mapOfferOrderCardPaymentDetailsDtoToModel = (
  dto: OfferOrderCardPaymentDetailsDto,
): OfferOrderCardPaymentDetails => mapOfferOrderCardPaymentDtoToModel(dto);

export const mapOfferOrderDtoToModel = (dto: OfferOrderDto, balance?: AccountBalanceDto): OfferOrder => {
  const { cardPayment, ...baseDto } = dto;

  const result: OfferOrder = {
    ...baseDto,
    paymentType: new OfferOrderPaymentType(dto.paymentType),
    paymentStatus: new OfferOrderPaymentStatus(dto),
    status: new OfferOrderStatus(dto.status),
    cashBalance: balance?.cashBalance,
    cashAvailable: balance ? findCashAvailable(balance) : undefined,
    refunds: mapOfferOrderRefundDtoListToModel(dto.refunds),
  };

  if (cardPayment) {
    result.cardPayment = mapOfferOrderCardPaymentDtoToModel(cardPayment);
  }

  return result;
};

export const mapOfferOrderDetailsDtoToModel = (dto: OfferOrderDetailsDto): OfferOrderDetails => {
  const result: OfferOrderDetails = {
    ...mapOfferOrderDtoToModel(dto),
    paymentInstructions: dto.paymentInstructions,
  };

  return result;
};

export const mapOfferOrderAggregateDtoToModel = (dto: OfferOrderAggregateDto): OfferOrderAggregate => ({
  ...dto,
  id: uuid(),
});
