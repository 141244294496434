import { Grid } from '@mui/material';
import { ArrayInput, BooleanInput, required, SimpleFormIterator, TextInput } from 'react-admin';
import { useWatch } from 'react-hook-form';

import { AddDefaultSecurityDeliveryOptionsButton } from './AddDefaultSecurityDeliveryOptionsButton';

interface InfoCreateEditProps {
  isEditMode?: boolean;
}

export const ExternalOfferCreateOrEdit = ({ isEditMode = false }: InfoCreateEditProps) => {
  const isExternalOffer = useWatch({ name: 'isExternalOffer' });

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <h2>{isEditMode ? 'Edit' : 'Add'} External Details</h2>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <BooleanInput label='Is External' source='isExternalOffer' validate={required()} fullWidth />
      </Grid>
      {isExternalOffer && (
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          <TextInput source='externalDetails.disclaimer' label='Offer disclaimer' fullWidth multiline minRows={2} />
        </Grid>
      )}
      {isExternalOffer && (
        <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
          <TextInput
            source='externalDetails.completedOfferOrderDisclaimer'
            label='Completed offer order disclaimer'
            fullWidth
            multiline
            minRows={2}
          />
        </Grid>
      )}
      {isExternalOffer && (
        <Grid item xs={12} md={6}>
          <TextInput source='externalDetails.transferAgentName' label='Transfer Agent Name' fullWidth />
          <TextInput source='externalDetails.bankName' label='Bank Name' fullWidth />
          <TextInput source='externalDetails.finalCircularUrl' label='Final Circular Url' fullWidth />
        </Grid>
      )}
      {isExternalOffer && (
        <>
          <Grid item xs={12} md={12}>
            <h2>Wire Instructions</h2>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source='externalDetails.wireInstructions.bankName'
              validate={[required()]}
              label='Bank Name'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source='externalDetails.wireInstructions.abaNumber'
              validate={[required()]}
              label='ABA #'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source='externalDetails.wireInstructions.accountNumber'
              validate={[required()]}
              label='A/C #'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source='externalDetails.wireInstructions.accountName'
              validate={[required()]}
              label='A/C Name'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              source='externalDetails.wireInstructions.attentionLine'
              validate={[required()]}
              label='Attention Line'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <h2>Check Instructions</h2>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput source='externalDetails.checkInstructions.payableTo' label='Payable To' fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput source='externalDetails.checkInstructions.bankName' label='Bank Name' fullWidth />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput source='externalDetails.checkInstructions.address.address1' label='Address Line 1' fullWidth />
            <TextInput source='externalDetails.checkInstructions.address.address2' label='Address Line 2' fullWidth />
            <TextInput source='externalDetails.checkInstructions.address.city' label='City' fullWidth />
            <TextInput source='externalDetails.checkInstructions.address.state' label='State' fullWidth />
            <TextInput source='externalDetails.checkInstructions.address.postalCode' label='Postal Code' fullWidth />
            <TextInput source='externalDetails.checkInstructions.address.country' label='Country' fullWidth />
          </Grid>
        </>
      )}
      {isExternalOffer && (
        <>
          <Grid item xs={12} md={12}>
            <h2>Security Delivery Options</h2>
            <AddDefaultSecurityDeliveryOptionsButton />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <ArrayInput
              source='externalDetails.securityDeliveryOptions'
              label='Security Delivery Information Options'
              fullWidth>
              <SimpleFormIterator fullWidth>
                <TextInput source='label' label='Option Label' validate={[required()]} fullWidth />
                <BooleanInput source='isReadOnly' label='Is Read Only' fullWidth />
                <BooleanInput source='initialValue' label='Initial Value (Checked or Unchecked)' fullWidth />
                <BooleanInput source='hasTooltip' label='Has Tooltip' fullWidth />
                <TextInput source='tooltip' label='Tooltip text' fullWidth multiline minRows={2} />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </>
      )}
    </Grid>
  );
};
