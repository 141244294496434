import * as React from 'react';

import _ from 'lodash';
import { useRecordContext } from 'react-admin';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

//props are not necessary thus props interface can vary
export const PhoneField = (props: any = {}) => {
  const record = useRecordContext(props);

  if (record) {
    if (_.isEmpty(record.phoneNumber)) return <>N/A</>;

    if (_.isEmpty(record.type))
      return (
        <span className='phoneObject'>
          <span>{formatPhoneNumberIntl(record.phoneNumber)}</span> <br />
        </span>
      );

    return (
      <span className='phoneObject'>
        <span>{formatPhoneNumberIntl(record.phoneNumber) || record.phoneNumber}</span> | <b>{record.type}</b> <br />
      </span>
    );
  }

  return <span className='phoneObject'>N/A</span>;
};

export default PhoneField;
