import * as React from 'react';

import { Labeled, TextField, useRecordContext } from 'react-admin';
import { Row, Col } from 'react-grid-system';

import { AccountHolderMailingAddressDto } from '../../../../dtos/accounts.dtos';
import { CountryField, StateField } from '../../../Fields';

export const Subtitle = (props: any) => {
  const record = useRecordContext(props);

  return record.sameAsPhysical ? <small className='subtitle'>is same with Physical Address</small> : <></>;
};

export const MailingAddressShow = (record: AccountHolderMailingAddressDto) => (
  <div className='showContent'>
    <Row>
      <Col sm={6}>
        <h2 className='title'>Mailing Address</h2>
        <Subtitle record={record} />
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Labeled label='Street Address (Line 1)'>
          <TextField source='address1' record={record} />
        </Labeled>
      </Col>
      <Col md={6}>
        <Labeled label='Street Address (Line 2)'>
          <TextField source='address2' record={record} />
        </Labeled>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Labeled label='Country'>
          <CountryField record={record} source='country' />
        </Labeled>
      </Col>
      <Col md={6}>
        <Labeled label='State'>
          <StateField record={record} source='state' />
        </Labeled>
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Labeled label='City'>
          <TextField source='city' record={record} />
        </Labeled>
      </Col>
      <Col md={6}>
        <Labeled label='Postal Code'>
          <TextField source='postalCode' record={record} />
        </Labeled>
      </Col>
    </Row>
  </div>
);
