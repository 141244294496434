import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import {
  useNotify,
  useRefresh,
  Button,
  Form,
  useUpdateMany,
  SelectInput,
  required,
  BooleanInput,
  useUnselect,
} from 'react-admin';
import { DataProviderResource, OfferOrderConstant } from 'src/constants';
import { OfferOrderRefundMechanismDto, OfferOrderRefundReasonDto } from 'src/dtos';

export interface UpdateOfferOrderListStatusModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedIds?: string[];
}

export interface CreateOfferOrderRefundView {
  mechanism: OfferOrderRefundMechanismDto;
  reason?: OfferOrderRefundReasonDto;
  adminNote?: string;
  bankName?: string;
}

export const UpdateOfferOrderListStatusModal = ({
  selectedIds,
  isOpen,
  onClose,
}: UpdateOfferOrderListStatusModalProps) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const [updateMany, { isLoading }] = useUpdateMany();
  const unSelect = useUnselect(DataProviderResource.OfferOrders);

  const onUpdateStatus = (values: any) => {
    updateMany(
      DataProviderResource.OfferOrdersStatus,
      {
        ids: selectedIds ?? [],
        data: {
          status: values.status,
          useEmailNotifier: values.useEmailNotifier,
        },
      },
      {
        onSuccess: () => {
          notify(`Offer order list status was successfully updated.`, {
            type: 'success',
            undoable: true,
          });
          unSelect(selectedIds ?? []);
          onClose();
        },
        onSettled: () => {
          refresh();
        },
        onError: err => {
          notify(`Error updating offer order list status: ${err}`, {
            type: 'error',
          });
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth='lg'>
      <DialogTitle>Update Offer Order Status</DialogTitle>
      <DialogContent style={{ marginTop: '32px' }}>
        <Form
          onSubmit={onUpdateStatus}
          resource={DataProviderResource.OfferOrdersStatus}
          record={{ status: '', useEmailNotifier: true }}>
          <Grid container direction='column'>
            <SelectInput
              source='status'
              label='Status'
              choices={OfferOrderConstant.STATUS_OPTION_LIST}
              disabled={isLoading}
              validate={required()}
            />
            <BooleanInput source='useEmailNotifier' label='Send email on status change?' disabled={isLoading} />
            <DialogActions>
              <Button
                variant='contained'
                label='Cancel'
                disabled={isLoading}
                color='inherit'
                size='medium'
                onClick={onClose}
              />
              <Button
                variant='contained'
                type='submit'
                label='Update'
                disabled={isLoading}
                color='primary'
                size='medium'
              />
            </DialogActions>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
