import AssignmentIcon from '@mui/icons-material/Assignment';

import OfferDocumentCreate from './OfferDocumentCreate';
import OfferDocumentEdit from './OfferDocumentEdit';
import OfferDocumentList from './OfferDocumentList';

const OfferDocuments = {
  list: OfferDocumentList,
  create: OfferDocumentCreate,
  edit: OfferDocumentEdit,
  icon: AssignmentIcon,
};

export default OfferDocuments;
