import { useGetList } from 'react-admin';

import { OrderPosition } from '../../models/order.model';
import { AccountPositionList } from './AccountPositionList';

export interface TradableUserPositionsListProps {
  accountId: number;
}

export const AccountTradablePositionsList = ({ accountId }: TradableUserPositionsListProps) => {
  const { data, isLoading } = useGetList<OrderPosition>('orders.positions', {
    meta: {
      accountId,
    },
  });

  const tradablePositions = data?.filter(aPosition => aPosition.isTradable);
  const labels = {
    value: 'Last Price',
    totalValue: 'Current Value',
  };

  return <AccountPositionList items={tradablePositions ?? []} isLoading={isLoading} labels={labels} />;
};
