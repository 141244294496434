import React from 'react';

import { CircularProgress } from '@mui/material';
import { useGetList } from 'react-admin';
import { Row, Col } from 'react-grid-system';

import { AccountDto } from '../../../../dtos/accounts.dtos';
import StatusField from '../../../Fields/StatusField';
import Actions from './actions';

interface UpdateRequestsShowProps {
  record: AccountDto;
}

export const UpdateRequestsShow = ({ record }: UpdateRequestsShowProps) => {
  const { primaryAccountHolder, type } = record;

  const { data, isLoading, error } = useGetList('update-requests', {
    filter: { accountIdentifier: type, accountHolderId: primaryAccountHolder?.id },
  });

  if (isLoading)
    return (
      <div className='alignAllCenter'>
        <CircularProgress />
      </div>
    );
  if (error) return <div>An error occurred while loading this table !</div>;

  return (
    <div className='transactionsContent'>
      <Row>
        <Col sm={12}>
          <h2 className='title'>Account Update Requests</h2>
        </Col>
      </Row>
      {data?.length === 0 && <>No update request for this account is made!</>}
      {data?.map(element => (
        <Row key={element.id} className='updateRequestItem'>
          <Col sm={12}>
            <Row>
              <Col sm={12} className='sectionTitle'>
                {element.type}
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                {Object.keys(element.original).map(previousKey => (
                  <div className='keyContainer' key={previousKey + 'originalKey'}>
                    <div className='keyHeader'>{previousKey}</div>
                    <div>
                      <span className='description'>Original value: </span>
                      <span className='keyOriginalValue'>{element.original[previousKey]}</span>
                    </div>
                    <div>
                      <span className='description'>New value: </span>
                      <span className='keyNewValue'>{element.updated[previousKey]}</span>
                    </div>
                  </div>
                ))}
              </Col>
              <Col sm={6}>
                <Row>
                  <Col md={12} className={'alignAllCenter'}>
                    <StatusField
                      status={element.status}
                      success={['Complete', 'Approved']}
                      warning={['PendingConfirm', 'PendingFirmReview']}
                      fail={'Rejected'}
                    />
                  </Col>
                  <Col md={12} style={{ paddingTop: 20 }}>
                    <Actions
                      accountIdentifier={type}
                      accountHolderId={primaryAccountHolder?.id}
                      requestId={element.id}
                      status={element.status}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      ))}
    </div>
  );
};
