import {
  List,
  Datagrid,
  TextField,
  DateField,
  SelectField,
  ShowButton,
  TextInput,
  FunctionField,
  SelectInput,
} from 'react-admin';
import { SearchTransferItem } from 'src/models';

import ACHTransfers from '.';
import { direction, states } from '../../constants/choices/ach.transfers.choices';
import StatusField from '../Fields/StatusField';

const defaultSort = { field: 'Date', order: 'DESC' };
const transferFilters = [
  <TextInput label='Account Information' key='search' placeholder='Ac. Number | Title | Name' source='query' />,
  <SelectInput key='state' label='Transfer State' source='state' choices={states} />,
];

const ACHTransferList = () => {
  return (
    <>
      <List title='ACH Transfers' filters={transferFilters} sort={defaultSort}>
        <Datagrid expandSingle rowClick='expand' bulkActionButtons={false} expand={<ACHTransfers.Actions />}>
          <TextField source='id' sortable={false} />
          <TextField source='accountName' sortBy='AccountName' sortable={false} />
          <TextField source='accountNumber' sortBy='AccountNumber' />
          <TextField source='accountTitle' sortBy='AccountTitle' />
          <TextField source='bankName' sortable={false} />
          <TextField source='amount' sortable={false} />
          <SelectField source='direction' choices={direction} sortable={false} />
          <TextField source='mechanism' sortable={false} />
          <FunctionField
            label={'State'}
            render={(record: SearchTransferItem) => (
              <StatusField
                status={record.state}
                success={['Approved', 'FundsPosted', 'SentToBank', 'Complete']}
                warning={[
                  'PendingRepCreateApproval',
                  'PendingFirmCreateApproval',
                  'Pending',
                  'FundsHeld',
                  'PendingPrinting',
                  'Postponed',
                  'Requested',
                ]}
                fail={[
                  'Unknown',
                  'Rejected',
                  'Canceled',
                  'Void',
                  'Returned',
                  'StopPayment',
                  'CreateRejected',
                  'FailedAtBank',
                ]}
              />
            )}
            sortable={false}
          />
          <DateField source='date' sortBy='Date' />
          <ShowButton label='Show' />
        </Datagrid>
      </List>
    </>
  );
};

export default ACHTransferList;
