import * as React from 'react';

import { Grid } from '@mui/material';
import {
  SimpleShowLayout,
  NumberField,
  BooleanField,
  Loading,
  useGetOne,
  useRecordContext,
  useGetList,
} from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { OfferOptionTypeDto, OfferCategoryNameDto, OfferOrderStatusDto } from 'src/dtos';
import { OfferOrderAggregate } from 'src/models/offerOrders.models';

import { GridBreak } from '../../../../lib/GridBreak/GridBreak';

export const PriceShow = () => {
  const record = useRecordContext();
  const [curTotalRaiseCurrency, setCurTotalRaiseCurrency] = React.useState<string>();
  const { data: offerOrderAggregate, isLoading } = useGetList<OfferOrderAggregate>(
    DataProviderResource.OfferOrdersAggregate,
    {
      filter: {
        offerId: record.id,
        status: [
          OfferOrderStatusDto.PendingFunds,
          OfferOrderStatusDto.PendingOfferClose,
          OfferOrderStatusDto.PendingAction,
          OfferOrderStatusDto.Approved,
          OfferOrderStatusDto.Complete,
          OfferOrderStatusDto.PendingFirmCancellation,
        ],
      },
    },
  );

  const { data: category } = useGetOne('offer-options', {
    id: record.categoryId,
    meta: { type: OfferOptionTypeDto.CATEGORY },
  });

  React.useEffect(() => {
    if (!!offerOrderAggregate) {
      const curTotalRaiseCurrencyAmt = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(offerOrderAggregate?.[0]?.sumRaisedInvestment ?? 0);
      setCurTotalRaiseCurrency(curTotalRaiseCurrencyAmt);
    }
  }, [offerOrderAggregate]);

  if (isLoading) return <Loading />;

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>
            Price & Limits Information <h6>(Current Total Raise Currency {curTotalRaiseCurrency}) </h6>
          </h2>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField
            label='Minimum Raise Amount'
            source='minTotalRaiseCurrencyAmt'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField
            label='Maximum Raise Amount'
            source='maxTotalRaiseCurrencyAmt'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField
            label='Minimum Investment Currency'
            source='minimumInvestCurrencyAmt'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField
            label='Maximum Investment Currency'
            source='maximumInvestCurrencyAmt'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField
            label='Minimum Investment Increment'
            source='minimumInvestIncCurrencyAmt'
            fullWidth
            options={{ style: 'currency', currency: 'USD' }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField label='Minimum Invest Share Increment Qty' source='minimumInvestShareIncrQty' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField label='Minimum Invest Share Qty' source='minimumInvestShareQty' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <NumberField label='Maximum Invest Share Qty' source='maximumInvestShareQty' fullWidth />
        </SimpleShowLayout>
      </Grid>

      {category?.name !== OfferCategoryNameDto.Conditional && (
        <Grid item xs={12} sm={6}>
          <SimpleShowLayout>
            <NumberField
              label='Price Per Share'
              source='pricePerShare'
              fullWidth
              options={{ style: 'currency', currency: 'USD' }}
            />
          </SimpleShowLayout>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <SimpleShowLayout>
          <BooleanField label='Allow restricted persons' source='allowRestrictedPersons' fullWidth />
        </SimpleShowLayout>
      </Grid>
      {category?.name === OfferCategoryNameDto.Conditional && (
        <>
          <GridBreak />
          <Grid item xs={12}>
            <h3>Conditional Offer Details</h3>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SimpleShowLayout>
              <NumberField
                label='Price Per Share'
                source='pricePerShare'
                fullWidth
                options={{ style: 'currency', currency: 'USD' }}
              />
            </SimpleShowLayout>
          </Grid>
          <GridBreak />
          <Grid item xs={12} sm={6}>
            <SimpleShowLayout>
              <NumberField
                label='Minimum Share Price'
                source='minSharePrice'
                options={{ style: 'currency', currency: 'USD' }}
                fullWidth
              />
            </SimpleShowLayout>
          </Grid>
          <GridBreak />
          <Grid item xs={12} sm={6}>
            <SimpleShowLayout>
              <NumberField
                label='Maximum Share Price'
                source='maxSharePrice'
                options={{ style: 'currency', currency: 'USD' }}
                fullWidth
              />
            </SimpleShowLayout>
          </Grid>
        </>
      )}
    </Grid>
  );
};
export default PriceShow;
