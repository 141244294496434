import React from 'react';

import Colors from './colors';

type CSSProperties = {
  [key: string]: React.CSSProperties;
};

class StyleSheet {
  static create<Styles extends CSSProperties>(styles: Styles): Styles {
    return styles;
  }
}

const achRelationship = StyleSheet.create({
  pendingStatus: {
    backgroundColor: Colors.WARNING_LIGHT,
    color: Colors.WARNING_DARK,
    textAlign: 'center',
    padding: 5,
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 5,
  },
  rejectedStatus: {
    backgroundColor: Colors.DANGER_LIGHT,
    color: Colors.DANGER_DARK,
    textAlign: 'center',
    padding: 5,
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 5,
  },
  approvedStatus: {
    backgroundColor: Colors.SUCCESS_LIGHT,
    color: Colors.SUCCESS_DARK,
    textAlign: 'center',
    padding: 5,
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 5,
  },
  menuItem: {
    margin: 5,
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
  },
  moreButton: {
    position: 'absolute',
    right: '0px',
    width: '2px !important',
    marginTop: '-10px',
    marginRight: '-14px',
    color: Colors.LIGHTER_MY_IPO_BLUE,
  },
  modal: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: 3,
    p: 4,
  },
});

const defaults = StyleSheet.create({
  alignCenter: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const user = StyleSheet.create({
  pendingStatus: {
    backgroundColor: Colors.WARNING_LIGHT,
    padding: 3,
    display: 'flex',
    justifyContent: 'center',
    color: Colors.WARNING_DARK,
    borderRadius: 5,
    fontWeight: 600,
    textAlign: 'center',
  },
  rejectedStatus: {
    backgroundColor: Colors.DANGER_LIGHT,
    padding: 3,
    display: 'flex',
    justifyContent: 'center',
    color: Colors.DANGER_DARK,
    borderRadius: 5,
    fontWeight: 600,
    textAlign: 'center',
  },
  approvedStatus: {
    backgroundColor: Colors.SUCCESS_LIGHT,
    padding: 3,
    display: 'flex',
    justifyContent: 'center',
    color: Colors.SUCCESS_DARK,
    borderRadius: 5,
    fontWeight: 600,
    textAlign: 'center',
  },
  moreActionsButton: {
    padding: 5,
    paddingLeft: 15,
    fontWeight: 500,
    backgroundColor: Colors.LIGHTEST_MY_IPO_BLUE,
    color: Colors.LIGHTER_MY_IPO_BLUE,
  },
  menuItem: {
    marginBottom: 2,
    // padding: '10px 30px 10px 20px',
    fontWeight: 500,
    fontSize: 14,
  },
  modal: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: 3,
    p: 4,
  },
});

const offer = StyleSheet.create({
  warningMessage: {
    backgroundColor: Colors.WARNING_LIGHT,
    padding: 3,
    display: 'flex',
    justifyContent: 'center',
    color: Colors.WARNING_DARK,
    fontSize: '0.75rem',
    borderRadius: 5,
    fontWeight: 400,
  },
  completeButton: {
    padding: 5,
    paddingLeft: 15,
    fontWeight: 500,
    backgroundColor: Colors.SUCCESS_LIGHT,
    color: Colors.SUCCESS_DARK,
  },
  closePannelWrapper: {
    marginLeft: '5rem',
    marginRight: '5rem',
    fontSize: 14,
    border: '1px solid grey',
    borderRadius: 15,
  },
});

const investigation = StyleSheet.create({
  moreButton: {
    position: 'absolute',
    right: '35px',
    width: '2px !important',
    color: Colors.LIGHTER_MY_IPO_BLUE,
  },
});

const offerOrders = StyleSheet.create({
  actionButton: {
    padding: 5,
    paddingLeft: 15,
    fontWeight: 500,
    backgroundColor: Colors.LIGHTEST_MY_IPO_BLUE,
    color: Colors.LIGHTER_MY_IPO_BLUE,
  },
  menuItem: {
    marginBottom: 2,
    fontWeight: 500,
    fontSize: 14,
  },
});

const activeFields = StyleSheet.create({
  cancel: {
    backgroundColor: Colors.DANGER_LIGHT,
    color: Colors.DANGER_DARK,
    textAlign: 'center',
    padding: 5,
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 5,
  },
  active: {
    backgroundColor: Colors.SUCCESS_LIGHT,
    color: Colors.SUCCESS_DARK,
    textAlign: 'center',
    padding: 5,
    fontWeight: 600,
    fontSize: 14,
    borderRadius: 5,
  },
});

const Styles = {
  achRelationship,
  investigation,
  defaults,
  user,
  offer,
  offerOrders,
  fields: {
    activeFields,
  },
};

export default Styles;
