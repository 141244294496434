import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import UserActionsBase from './actions/UserActionsBase';
import { AccountShow } from './components/Account/AccountShow';
import { DisclosureShow } from './components/Disclosure/DisclosureShow';
import { FinancialInformationShow } from './components/FinancialInformation/FinancialInformationShow';
import { HistoryShow } from './components/History/HistoryShow';
import { InvestigationShow } from './components/Investigation/InvestigationShow';
import { MailingAddressShow } from './components/MailingAddress/MailingAddressShow';
import { PersonalInformationShow } from './components/PersonalInformation/PersonalInformationShow';
import { PhysicalAddressShow } from './components/PhysicalAddress/PhysicalAddressShow';
import { SuitabilityInformationShow } from './components/SuitabilityInformation/SuitabilityInformationShow';
import { SummaryShow } from './components/Summary/SummaryShow';
import { TrustedContactShow } from './components/TrustedContact/TrustedContactShow';
import { UpdateRequestsShow } from './components/UpdateRequests/UpdateRequestsShow';
import { UserList } from './UserList';
import { UserShow } from './UserShow';

const User = {
  List: UserList,
  Icon: PeopleAltIcon,
  Actions: UserActionsBase,
  Show: UserShow,
  PersonalInformation: PersonalInformationShow,
  Account: AccountShow,
  AccountUpdateRequests: UpdateRequestsShow,
  Investigation: InvestigationShow,
  PhysicalAddress: PhysicalAddressShow,
  MailingAddress: MailingAddressShow,
  TrustedContact: TrustedContactShow,
  SuitabilityInformation: SuitabilityInformationShow,
  FinancialInformation: FinancialInformationShow,
  Disclosure: DisclosureShow,
  Summary: SummaryShow,
  History: HistoryShow,
};

export default User;
