import { ConfigConstant } from 'src/constants';

export class ConfigService {
  get authToken() {
    return localStorage.getItem(ConfigConstant.AUTH_TOKEN_KEY);
  }
}

const configService = new ConfigService();

export const getConfigService = () => {
  return configService;
};
