import { useState } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import ReplayIcon from '@mui/icons-material/Replay';
import {
  useRedirect,
  ListButton,
  useShowController,
  useUpdate,
  useNotify,
  useRefresh,
  TopToolbar,
  EditButton,
  DeleteButton,
  Button,
} from 'react-admin';
import { DataProviderResource } from 'src/constants';
import { UpdateOfferOrderBodyDto } from 'src/dtos';

import { CreateOfferOrderRefundModal } from './components/CreateOfferOrderRefundModal';
import { UpdateOfferOrderStatusModal } from './components/UpdateOfferOrderStatusModal';

export const OfferOrderActions = () => {
  const { record } = useShowController();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [update] = useUpdate<UpdateOfferOrderBodyDto & { id: string }>();
  const [isUpdateStatusModalVisible, setIsUpdateStatusModalVisible] = useState<boolean>(false);
  const [isRefundModalVisible, setIsRefundModalVisible] = useState<boolean>(false);

  const onUpdateStatus = () => {
    setIsUpdateStatusModalVisible(true);
  };

  const onConfirmCardPayment = () => {
    update(
      DataProviderResource.OfferOrders,
      {
        id: record.id,
        data: {
          isCardPaymentConfirmed: true,
        },
      },
      {
        mutationMode: 'undoable',
        onSuccess: () => {
          notify(`Offer order card payment was successfully confirmed.`, {
            type: 'success',
            undoable: true,
          });
        },
        onSettled: () => {
          refresh();
        },
        onError: err => {
          notify(`Error confirming offer order card payment: ${err}`, {
            type: 'error',
          });
        },
      },
    );
  };

  const onRefundPayment = () => {
    setIsRefundModalVisible(true);
  };

  return (
    <TopToolbar>
      <Button label='Status' onClick={onUpdateStatus} startIcon={<EditIcon />} />
      <Button label='Refund' onClick={onRefundPayment} startIcon={<ReplayIcon />} />
      <DeleteButton
        label='Delete'
        mutationMode='pessimistic'
        confirmContent='Are you sure you want to delete the offer order?'
        confirmTitle='Delete Offer Order'
        mutationOptions={{
          onSuccess: () => {
            notify(`Offer order was successfully deleted.`, {
              type: 'success',
              undoable: true,
            });
          },
          onSettled: () => {
            redirect('/offer-orders');
          },
          onError: err => {
            notify(`Error deleting offer order: ${err}`, {
              type: 'error',
            });
          },
        }}
      />
      <EditButton label='Confirm card payment' onClick={onConfirmCardPayment} />
      <ListButton />
      <CreateOfferOrderRefundModal isOpen={isRefundModalVisible} onClose={() => setIsRefundModalVisible(false)} />
      <UpdateOfferOrderStatusModal
        isOpen={isUpdateStatusModalVisible}
        onClose={() => setIsUpdateStatusModalVisible(false)}
      />
    </TopToolbar>
  );
};
