import { OrderUrlConstant } from 'src/constants';
import {
  CancelOrderDto,
  CreateOrderDto,
  GetOrderDto,
  GetOrderListDto,
  GetOrderListResponseDto,
  GetOrderPositionListDto,
  GetOrderPositionListResponseDto,
  OrderDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class OrdersApi {
  private _configService: ConfigService;

  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async create({ params, body }: CreateOrderDto): Promise<OrderDto> {
    const url = replacePlaceholders(OrderUrlConstant.CREATE, params);

    return httpRequest<OrderDto>({
      method: 'POST',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }

  async list({ params, queryParams }: GetOrderListDto): Promise<GetOrderListResponseDto> {
    const url = replacePlaceholders(OrderUrlConstant.LIST, params);

    return httpRequest<GetOrderListResponseDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
      queryParams,
    });
  }

  async retrieve({ params }: GetOrderDto): Promise<OrderDto> {
    const url = replacePlaceholders(OrderUrlConstant.LIST, params);

    return httpRequest<OrderDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async cancel({ params }: CancelOrderDto): Promise<void> {
    const url = replacePlaceholders(OrderUrlConstant.CANCEL, params);

    return httpRequest<void>({
      method: 'PUT',
      url,
      authToken: this._configService.authToken,
    });
  }

  async listPositions({ params }: GetOrderPositionListDto): Promise<GetOrderPositionListResponseDto> {
    const url = replacePlaceholders(OrderUrlConstant.POSITION_LIST, params);

    return httpRequest<GetOrderPositionListResponseDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }
}
