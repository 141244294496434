import * as React from 'react';

import { Labeled, SelectField, TextField } from 'react-admin';
import { Row, Col } from 'react-grid-system';

import {
  annualIncomeChoices,
  employmentStatusChoices,
  liquidityNetWorthChoices,
  taxBracketChoices,
  totalNetWorthChoices,
  yearsEmployedChoices,
} from '../../../../constants/choices/financial.information.choices';
import { FinancialInformationDto } from '../../../../dtos/accounts.dtos';
import { CountryField, StateField } from '../../../Fields';

export const FinancialInformationShow = (record: FinancialInformationDto) => {
  const { employerAddress, annualIncome, employmentStatus } = record;

  return (
    <div className='showContent'>
      <Row>
        <Col sm={6}>
          <h2 className='title'>Financial Information</h2>
        </Col>
      </Row>
      {annualIncome && (
        <>
          <Row>
            <Col md={6}>
              <Labeled label='Annual Income'>
                <SelectField source='annualIncome' record={record} choices={annualIncomeChoices} />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Total Net Worth'>
                <SelectField source='totalNetWorth' record={record} choices={totalNetWorthChoices} />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Liquid Net Worth'>
                <SelectField source='liquidNetWorth' record={record} choices={liquidityNetWorthChoices} />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Tax Bracket'>
                <SelectField source='taxBracket' record={record} choices={taxBracketChoices} />
              </Labeled>
            </Col>
          </Row>
        </>
      )}

      <Row>
        {employmentStatus && (
          <Col md={6}>
            <Labeled label='Employment Status'>
              <SelectField source='employmentStatus' record={record} choices={employmentStatusChoices} />
            </Labeled>
          </Col>
        )}

        {employerAddress ? (
          <Col md={6}>
            <Labeled label='Employer Name'>
              <TextField source='employerName' record={record} />
            </Labeled>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      {employerAddress ? (
        <>
          <Row>
            <Col md={6}>
              <Labeled label='Job Title'>
                <TextField source='jobTitle' record={record} />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Years Employed'>
                <SelectField source='yearsEmployed' record={record} choices={yearsEmployedChoices} />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Street Address (Line 1)'>
                <TextField source='address1' record={employerAddress} />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Street Address (Line 2)'>
                <TextField source='address2' record={employerAddress} />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='Country'>
                <CountryField record={employerAddress} source='country' />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='State'>
                <StateField record={employerAddress} source='state' />
              </Labeled>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Labeled label='City'>
                <TextField source='city' record={employerAddress} />
              </Labeled>
            </Col>
            <Col md={6}>
              <Labeled label='Postal Code'>
                <TextField source='postalCode' record={employerAddress} />
              </Labeled>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
