import { BaseListResponseDto, PaginationQueryParams, SortDirectionQueryParams } from 'src/dtos';

export enum TradingBlockTransferMechanismDto {
  Wire = 'Wire',
  ACH = 'Ach',
  Check = 'Check',
}

export enum TradingBlockTransferDirectionDto {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing',
}

export enum TradingBlockTransferStateDto {
  PendingRepCreateApproval = 'PendingRepCreateApproval',
  PendingFirmCreateApproval = 'PendingFirmCreateApproval',
  Pending = 'Pending',
  Rejected = 'Rejected',
  Canceled = 'Canceled',
  Approved = 'Approved',
  FundsHeld = 'FundsHeld',
  FundsPosted = 'FundsPosted',
  SentToBank = 'SentToBank',
  Complete = 'Complete',
  PendingPrinting = 'PendingPrinting',
  Void = 'Void',
  Returned = 'Returned',
  StopPayment = 'StopPayment',
  CreateRejected = 'CreateRejected',
  Postponed = 'Postponed',
  FailedAtBank = 'FailedAtBank',
  Requested = 'Requested',
}

export interface TransferDto {
  id: number;
  accountId: number;
  transferId: null | number;
  externalTransferId: string;
  mechanism: TradingBlockTransferMechanismDto;
  direction: TradingBlockTransferDirectionDto;
  achRelationship: number;
  state: TradingBlockTransferStateDto;
  amount: number;
  requestedAmount: number;
  memos: string[];
  fees: number[];
  bankName: string;
  createDate: string;
  lastModifiedDate?: string;
  deletedDate?: string;
  rejectReason?: string;
  adminNote?: string;
  adminNotes?: {
    id: number;
    text: string;
    createBy: string;
    createDate: string;
  }[];
}

// NOTE: this dto comes from the transfer details endpoint without accountid path parameter
// /v1/api/cashiering-transfer/{transferId}/details
export interface AdminTransferDetailsDto {
  accountId: number;
  accountName: string;
  accountNumber: string;
  accountTitle: string;
  amount: number;
  bankAccountOwnerNameSecret: string;
  bankName: string;
  created: {
    userId: number;
    name: string;
    date: string;
  };
  note?: string;
  adminNote?: string;
  notes?: {
    id: number;
    text: string;
    createBy: string;
    createDate: string;
  }[];
  adminNotes?: {
    id: number;
    text: string;
    createBy: string;
    createDate: string;
  }[];
  disbursementType?: string;
  repReviewed?: {
    userId: number;
    name: string;
    date: string;
  };
  rejected?: any;
  pendingWithdrawalAmount?: number;
  pendingFullAmountWithdrawal?: boolean;
  accountStatus?: string;
  repCode?: string;
  bankAccountBalance?: number | null;
  date: string;
  direction: string;
  firmReviewed: {
    userId: number;
    name: string;
    date: string;
  };
  lastModified: {
    userId: number;
    name: string;
    date: string;
  };
  mechanism: string;
  status: string;
  text: string;
  transferId: number;
}

export interface SearchTransferItemDto {
  accountId: number;
  accountNumber: string;
  accountTitle: string;
  accountName: string;
  type: string;
  status: string;
  date: string;
  transfer: {
    transferId: number;
    subaccountId: number | null;
    text: string;
    direction: string;
    mechanism: string;
    amount: number;
    bankAccountOwnerNameSecret: string;
    bankName: string;
  };
  accountStatus: string;
  repCode: string;
}

export interface TransferDetailsDto extends TransferDto {}

export interface BaseTransferParamsDto {
  accountId: number;
}

export enum SearchTransferSortByQueryParamTypeDto {
  Date = 'Date',
  AccountNumber = 'AccountNumber',
  AccountTitle = 'AccountTitle',
  AccountName = 'AccountName',
}

export interface SearchTransferListQueryParamsDto
  extends Partial<SortDirectionQueryParams>,
    Partial<PaginationQueryParams> {
  filterBy?: { query?: string; state?: string };
  sortBy?: SearchTransferSortByQueryParamTypeDto;
}

export interface SearchTransferListDto {
  queryParams?: SearchTransferListQueryParamsDto;
}

export interface SearchTransferListResponseDto extends BaseListResponseDto<SearchTransferItemDto[]> {}

export interface GetTransferListQueryParamsDto {
  startDate?: string;
  endDate?: string;
  mechanisms?: string;
  states?: string;
  sortBy?: string;
}

export interface GetTransferListDto {
  params: BaseTransferParamsDto;
  queryParams?: GetTransferListQueryParamsDto;
}

export interface GetTransferListResponseDto
  extends BaseListResponseDto<
    {
      tradingBlockTransfer: TransferDto;
    }[]
  > {}

export interface GetTransferParamsDto {
  id: string;
}

export interface GetTransferDto {
  params: GetTransferParamsDto;
}

export interface UpdateTransferParamsDto extends BaseTransferParamsDto {
  id: string;
}

export interface UpdateTransferBodyDto {
  adminNote?: string;
  note?: string;
}

export interface UpdateTransferDto {
  params: UpdateTransferParamsDto;
  body: UpdateTransferBodyDto;
}

export interface CancelTransferParamsDto extends BaseTransferParamsDto {
  id: string;
}

export interface CancelTransferBodyDto {
  comment?: string;
}

export interface CancelTransferDto {
  params: CancelTransferParamsDto;
  body: CancelTransferBodyDto;
}

export interface FirmApproveTransferParamsDto extends BaseTransferParamsDto {
  id: string;
}

export interface FirmApproveTransferBodyDto {
  adminNote?: string;
  note?: string;
}

export interface FirmApproveTransferDto {
  params: FirmApproveTransferParamsDto;
  body: FirmApproveTransferBodyDto;
}

export interface RepApproveTransferParamsDto extends BaseTransferParamsDto {
  id: string;
}

export interface RepApproveTransferBodyDto {
  adminNote?: string;
  note?: string;
}

export interface RepApproveTransferDto {
  params: RepApproveTransferParamsDto;
  body: RepApproveTransferBodyDto;
}

export interface RejectTransferParamsDto extends BaseTransferParamsDto {
  id: string;
}

export interface RejectTransferBodyDto {
  adminNote?: string;
  note?: string;
}

export interface RejectTransferDto {
  params: RejectTransferParamsDto;
  body: RejectTransferBodyDto;
}
