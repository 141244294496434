import * as React from 'react';

import { Grid } from '@mui/material';
import { RichTextInput } from 'ra-input-rich-text';
import { required } from 'react-admin';

interface DisclaimerCreateEditProps {
  isEditMode?: boolean;
}

export const DisclaimerCreateEdit = ({ isEditMode = false }: DisclaimerCreateEditProps) => (
  <Grid container direction='row' spacing={2}>
    <Grid item xs={12}>
      <h2>{isEditMode ? 'Edit' : 'Add'} Disclaimer Information *</h2>
    </Grid>
    <Grid item xs={12}>
      <RichTextInput label={false} source='disclaimer' validate={[required()]} fullWidth />
    </Grid>
  </Grid>
);
export default DisclaimerCreateEdit;
