import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material';
import {
  useShowController,
  useNotify,
  useRefresh,
  Button,
  SelectInput,
  Form,
  required,
  useUpdate,
  BooleanInput,
} from 'react-admin';
import { DataProviderResource, OfferOrderConstant } from 'src/constants';
import { OfferOrderRefundMechanismDto, OfferOrderRefundReasonDto, UpdateOfferOrderBodyDto } from 'src/dtos';
import { OfferOrderDetails } from 'src/models';

export interface UpdateOfferOrderStatusModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export interface CreateOfferOrderRefundView {
  mechanism: OfferOrderRefundMechanismDto;
  reason?: OfferOrderRefundReasonDto;
  adminNote?: string;
  bankName?: string;
}

export const UpdateOfferOrderStatusModal = ({ isOpen, onClose }: UpdateOfferOrderStatusModalProps) => {
  const { record } = useShowController<OfferOrderDetails>();

  const notify = useNotify();
  const refresh = useRefresh();
  const [update, { isLoading }] = useUpdate<UpdateOfferOrderBodyDto & { id: string }>();

  const onUpdateStatus = (values: any) => {
    update(
      DataProviderResource.OfferOrders,
      {
        id: record?.id,
        data: {
          status: values.status,
          useEmailNotifier: values.useEmailNotifier,
        },
      },
      {
        onSuccess: () => {
          notify(`Offer order status was successfully updated.`, {
            type: 'success',
            undoable: true,
          });
          onClose();
        },
        onSettled: () => {
          refresh();
        },
        onError: err => {
          notify(`Error updating offer order status: ${err}`, {
            type: 'error',
          });
        },
      },
    );
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth='lg'>
      <DialogTitle>Update Offer Order Status</DialogTitle>
      <DialogContent style={{ marginTop: '32px' }}>
        <Form
          onSubmit={onUpdateStatus}
          resource={DataProviderResource.OfferOrders}
          record={{ status: record?.status?.value, useEmailNotifier: true }}>
          <Grid container direction='column'>
            <SelectInput
              source='status'
              label='Status'
              choices={OfferOrderConstant.STATUS_OPTION_LIST}
              disabled={isLoading}
              validate={required()}
            />
            <BooleanInput source='useEmailNotifier' label='Send email on status change?' disabled={isLoading} />
            <DialogActions>
              <Button
                variant='contained'
                label='Cancel'
                disabled={isLoading}
                color='inherit'
                size='medium'
                onClick={onClose}
              />
              <Button
                variant='contained'
                type='submit'
                label='Update'
                disabled={isLoading}
                color='primary'
                size='medium'
              />
            </DialogActions>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
