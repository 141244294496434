import * as React from 'react';

import { Grid } from '@mui/material';
import {
  ArrayInput,
  ImageField,
  ImageInput,
  SimpleFormIterator,
  required,
  BooleanInput,
  TextInput,
  FormDataConsumer,
} from 'react-admin';
import { formatPreviewImage } from 'src/utils';

export const AssetsCreate = () => (
  <Grid container direction='row' spacing={2}>
    <Grid item xs={12}>
      <h2>Add Assets Information</h2>
    </Grid>
    <Grid item xs={12}>
      <ArrayInput source='_assets_temp' label={false}>
        <SimpleFormIterator inline fullWidth>
          <BooleanInput label='Is Video' source='isVideo' validate={[required()]} fullWidth />
          <FormDataConsumer>
            {({ scopedFormData, getSource, ...rest }) =>
              scopedFormData &&
              scopedFormData.isVideo && (
                <TextInput
                  {...rest}
                  source={getSource?.('url') || ''}
                  label='Video Url'
                  validate={[required()]}
                  fullWidth
                />
              )
            }
          </FormDataConsumer>
          <ImageInput
            source='previewImageUrl'
            label='Preview Image'
            accept='image/png,image/jpg,image/jpeg,image/bmp,image/gif,image/webp,image/psd'
            format={formatPreviewImage}
            validate={required()}>
            <ImageField source='src' />
          </ImageInput>
        </SimpleFormIterator>
      </ArrayInput>
    </Grid>
  </Grid>
);
export default AssetsCreate;
