export const states = [
  { id: 'Unknown', name: 'Unknown' },
  { id: 'PendingRepCreateApproval', name: 'Pending Representor Create Approval' },
  { id: 'PendingFirmCreateApproval', name: 'Pending Firm Create Approval' },
  { id: 'Pending', name: 'Pending' },
  { id: 'Rejected', name: 'Rejected' },
  { id: 'Canceled', name: 'Canceled' },
  { id: 'Approved', name: 'Approved' },
  { id: 'FundsHeld', name: 'Funds Held' },
  { id: 'FundsPosted', name: 'Funds Posted' },
  { id: 'SentToBank', name: 'Sent To Bank' },
  { id: 'Complete', name: 'Complete' },
  { id: 'PendingPrinting', name: 'Pending Printing' },
  { id: 'Void', name: 'Void' },
  { id: 'Returned', name: 'Returned' },
  { id: 'StopPayment', name: 'Stop Payment' },
  { id: 'CreateRejected', name: 'Create Rejected' },
  { id: 'Postponed', name: 'Postponed' },
  { id: 'FailedAtBank', name: 'Failed At Bank' },
  { id: 'Requested', name: 'Requested' },
];

export const direction = [
  { id: 'Incoming', name: 'Inbound' },
  { id: 'Outgoing', name: 'Outbound' },
];

export const disbursementTypes = [
  { id: 'PartialBalance', name: 'Partial Balance' },
  { id: 'FullBalance', name: 'Full Balance' },
  { id: 'FullBalanceAndCloseAccount', name: 'Full Balance And Close Account' },
];

export enum DecisionTypes {
  REP_APPROVE = 'REP_APPROVE',
  FIRM_APPROVE = 'FIRM_APPROVE',
  EDIT = 'EDIT',
  CANCEL = 'CANCEL',
  REJECT = 'REJECT',
}
