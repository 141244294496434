import { LegacyUserUrlConstant } from 'src/constants';
import { GetLegacyUserListDto, GetLegacyUserListResponseDto } from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest } from 'src/utils';

export class LegacyUsersApi {
  private _configService: ConfigService;

  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  list({ queryParams }: GetLegacyUserListDto): Promise<GetLegacyUserListResponseDto> {
    return httpRequest<GetLegacyUserListResponseDto>({
      method: 'GET',
      url: LegacyUserUrlConstant.LIST,
      queryParams,
      authToken: this._configService.authToken,
    });
  }
}
