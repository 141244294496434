import { Chip } from '@mui/material';
import { isEmpty } from 'lodash';
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ShowButton,
  TextField,
} from 'react-admin';
import { getOfferOrderPaymentStatusColor, getOfferOrderStatusDtoTagStyle, getCurrencyFormatter } from 'src/utils';

import { OfferOrder } from '../../models/offerOrders.models';
import { OfferOrderListEditActions } from './OfferOrderListEditActions';
import { OfferOrderListFilters } from './OfferOrderListFilters';
import OfferOrdersListActions from './OfferOrdersListActions';

const defaultSort = { field: 'createdAt', order: 'DESC' };

export const OfferOrderList = () => {
  return (
    <List
      title='Offer Orders'
      sort={defaultSort}
      filters={<OfferOrderListFilters />}
      emptyWhileLoading
      actions={<OfferOrdersListActions />}>
      <Datagrid bulkActionButtons={<OfferOrderListEditActions />}>
        <DateField source='createdAt' label='Created at' showTime />
        <ReferenceField label='Offer name' source='offerId' reference='offers' sortable={false} link={'show'}>
          <TextField source='name' />
        </ReferenceField>
        <ReferenceField label='Category' source='offerId' reference='offers' sortable={false} link={false}>
          <ReferenceField source='categoryId' reference='offer-options' sortable={false} link={false}>
            <TextField source='name' fullWidth />
          </ReferenceField>
        </ReferenceField>
        <ReferenceField label='User' source='userId' reference='users' sortable={false}>
          <FunctionField
            label='User'
            sortBy='user.lastName'
            render={(record: any) => `${record.firstName} ${record.lastName}`}
          />
        </ReferenceField>
        <ReferenceField label='Account id' source='userId' reference='users' sortable={false} link={false}>
          <TextField label='Account id' source='account.accountId' />
        </ReferenceField>
        <ReferenceField label='Account number' source='userId' reference='users' sortable={false} link={false}>
          <TextField label='Account number' source='account.accountNumber' />
        </ReferenceField>
        <FunctionField
          label='Payment type'
          render={(record: OfferOrder) => {
            if (record.paymentType.isCard || !isEmpty(record.refunds)) {
              return (
                <div>
                  <span style={{ marginRight: '4px' }}>{record.paymentType.label}</span>
                  <Chip
                    size='small'
                    label={record.paymentStatus.label}
                    color={getOfferOrderPaymentStatusColor(record.paymentStatus.value)}
                  />
                </div>
              );
            }

            return record.paymentType.label;
          }}
        />
        <FunctionField
          label='Status'
          render={(record: OfferOrder) => (
            <div style={getOfferOrderStatusDtoTagStyle(record.status.value)}> {record.status.label}</div>
          )}
        />
        <FunctionField
          label='Total cost'
          render={(record: OfferOrder) => (
            <div>
              {getCurrencyFormatter().format(record.totalCost)}{' '}
              {record.conditional && `(${getCurrencyFormatter().format(record.conditional.totalInvestment)})`}
            </div>
          )}
        />
        <NumberField
          source='cashBalance'
          label='Cash balance'
          options={{ style: 'currency', currency: 'USD' }}
          sortable={false}
        />
        <NumberField
          source='cashAvailable'
          label='Cash available'
          options={{ style: 'currency', currency: 'USD' }}
          sortable={false}
        />
        <ShowButton label='Show' />
      </Datagrid>
    </List>
  );
};
