import { useEffect } from 'react';

import { useList, ListContextProvider, Datagrid, TextField, Pagination, Loading, NumberField } from 'react-admin';

import { usePagination } from '../../hooks/usePagination';
import { OrderPosition } from '../../models/order.model';

export interface AccountPositionsListProps {
  items: OrderPosition[];
  isLoading: boolean;
  omitColumn?: Partial<keyof OrderPosition>[];
  labels?: Partial<Record<keyof OrderPosition, string>>;
}

export const AccountPositionList = ({ items, isLoading, omitColumn = [], labels }: AccountPositionsListProps) => {
  const { dataPage, setData, setPage, page, perPage, setPerPage } = usePagination<OrderPosition>({});

  const listContext = useList({ data: dataPage, isLoading });

  useEffect(() => {
    setData(items);
  }, [items]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <ListContextProvider
      value={{
        ...listContext,
        setPage,
        page,
      }}>
      <Datagrid isRowSelectable={() => false} bulkActionButtons={false}>
        <TextField source='name' />
        {!omitColumn.includes('symbol') && <TextField source='symbol' />}
        <TextField source='quantity' label='Quantity' />
        <NumberField source='purchasePrice' label='Purchase Price' options={{ style: 'currency', currency: 'USD' }} />
        <NumberField source='value' label={labels?.value ?? 'Value'} options={{ style: 'currency', currency: 'USD' }} />
        <NumberField source='totalCost' label='Total Cost' options={{ style: 'currency', currency: 'USD' }} />
        <NumberField
          source='totalValue'
          label={labels?.totalValue ?? 'Total Value'}
          options={{ style: 'currency', currency: 'USD' }}
        />
        {!omitColumn.includes('change') && (
          <NumberField
            source='change'
            label='Change'
            options={{
              style: 'percent',
              signDisplay: 'exceptZero',
              maximumFractionDigits: 2,
              minimalFractionDigits: 2,
            }}
          />
        )}
      </Datagrid>
      <Pagination
        perPage={perPage}
        total={items.length}
        rowsPerPageOptions={[5, 10]}
        page={page}
        setPerPage={setPerPage}
      />
    </ListContextProvider>
  );
};
