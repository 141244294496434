import React, { useState } from 'react';

import { MoreVert } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { Button, Labeled, useGetList } from 'react-admin';
import { Row, Col } from 'react-grid-system';
import {
  AccountDto,
  TradingBlockAccountApplicationStateEnum,
  TradingBlockInvestigationInvestigationUploadedDocument,
  TradingBlockInvestigationApplicationUploadedDocument,
} from 'src/dtos';
import { GetInvestigationListResponse, InvestigationDocumentType } from 'src/models';

import Styles from '../../../../constants/styles';
import { FileViewerModal } from '../../../../lib/FileViewerModal/FileViewerModal';
import StatusField from '../../../Fields/StatusField';
import { CipCategoryList } from './components/CipCategoryList';
import ContinueApplication from './components/continueApplication';
import DecideActions from './components/decideActions';
import DocumentActionsDropdown from './components/DocumentActionsDropdown';
import { DocumentDetails } from './components/DocumentDetails';

export const InvestigationShow = (record: AccountDto) => {
  const { accountId, primaryAccountHolder } = record;
  const [viewDocument, setViewDocument] = useState<{ url: string; type: InvestigationDocumentType } | null>(null);
  const [curDocument, setCurDocument] = useState<
    (TradingBlockInvestigationInvestigationUploadedDocument | TradingBlockInvestigationApplicationUploadedDocument) & {
      investigationType: 'application' | 'investigation';
    }
  >();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data, isLoading, error } = useGetList<GetInvestigationListResponse>('account-investigations', {
    filter: { accountId },
  });

  const handleClickListBtn = (
    event: React.MouseEvent<HTMLElement>,
    document: (
      | TradingBlockInvestigationInvestigationUploadedDocument
      | TradingBlockInvestigationApplicationUploadedDocument
    ) & { investigationType: 'application' | 'investigation' },
  ) => {
    setAnchorEl(event.currentTarget);
    setCurDocument(document);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onFileViewerModalClose = () => {
    setViewDocument(null);
  };

  const onApplicationDocumentView = (document: { url: string; type: InvestigationDocumentType }) => {
    setViewDocument(document);
  };

  const onInvestigationDocumentView = (document: { url: string; type: InvestigationDocumentType }) => {
    setViewDocument(document);
  };

  if (isLoading)
    return (
      <div className='alignAllCenter'>
        <CircularProgress />
      </div>
    );

  if (error) return <div>An error occurred. Received {JSON.stringify(error)} </div>;

  if (!accountId) return <div> No account id was found</div>;

  const overallStatus = data && data?.length > 0 ? data[0].state : null;

  const nameSurname = primaryAccountHolder?.firstName + ' ' + primaryAccountHolder?.lastName;

  return (
    <div className='showContent'>
      <Row>
        <Col sm={12}>
          <h2 className='title'>Investigation</h2>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <div>
            <span className='fullNameLabel'>{nameSurname}</span>
            <br></br>
            <span>({accountId})</span>
          </div>
        </Col>

        <Col md={3}>
          <Labeled label='Investigation Status'>
            {
              <StatusField
                status={overallStatus}
                success={TradingBlockAccountApplicationStateEnum.Complete}
                warning={[
                  TradingBlockAccountApplicationStateEnum.Pending,
                  TradingBlockAccountApplicationStateEnum.New,
                  TradingBlockAccountApplicationStateEnum.InvestigationSubmitted,
                  TradingBlockAccountApplicationStateEnum.ReadyForBackOffice,
                  TradingBlockAccountApplicationStateEnum.BackOffice,
                  TradingBlockAccountApplicationStateEnum.AccountSetup,
                  TradingBlockAccountApplicationStateEnum.ActionRequired,
                  TradingBlockAccountApplicationStateEnum.PendingSubmit,
                ]}
                fail={[
                  TradingBlockAccountApplicationStateEnum.Rejected,
                  TradingBlockAccountApplicationStateEnum.Suspended,
                  TradingBlockAccountApplicationStateEnum.Error,
                  TradingBlockAccountApplicationStateEnum.Cancelled,
                ]}
              />
            }
          </Labeled>
        </Col>
      </Row>
      <>
        {data?.map((investigationData: GetInvestigationListResponse) => {
          return investigationData.investigations?.map(investigation => (
            <div key={'investigation' + investigation.investigationId}>
              <div className='investigationBox'>
                <Row>
                  <Col md={6}>
                    <h3 className='subHeader1'>#{investigation.investigationId} - Investigation</h3>
                  </Col>
                  <Col md={6}>
                    <DecideActions accountId={accountId} status={investigationData.state} />
                  </Col>
                </Row>
                <h3 className='subHeader2'>Admin Note</h3>
                <Col md={6} style={{ paddingTop: 0 }}>
                  <div style={{ padding: 0 }}>
                    <ul style={{ padding: 0, margin: 0, listStylePosition: 'inside' }}>
                      <li>{investigation.adminNote ?? 'NA'}</li>
                    </ul>
                  </div>
                </Col>
                <h3 className='subHeader2'>Admin Notes</h3>
                <Col md={6} style={{ paddingTop: 0 }}>
                  <div style={{ padding: 0 }}>
                    <ul style={{ padding: 0, margin: 0, listStylePosition: 'inside' }}>
                      {investigation.adminNotes?.map(anAdminNote => {
                        return (
                          <li key={anAdminNote.id}>
                            {anAdminNote.text} (created on: {anAdminNote.createdDate}, created by:{' '}
                            {anAdminNote.createdBy})
                          </li>
                        );
                      }) ?? <li>NA</li>}
                    </ul>
                  </div>
                </Col>

                {investigation.cipCategories && (
                  <>
                    <h3 className='subHeader2'> CIP Categories </h3>
                    <CipCategoryList categories={investigation.cipCategories} />
                  </>
                )}
                {investigation.uploadedDocuments && (
                  <>
                    <h3 className='subHeader2'> Documents </h3>
                    {investigation.uploadedDocuments.map(document => (
                      //NOTE: we have used this way here because MaterialUi wont accept having popovers inside of children
                      <Row key={document.documentName} style={{ margin: 20 }} className='hoverItem'>
                        <DocumentDetails
                          {...document}
                          accountId={accountId}
                          investigation={{ type: 'investigation', id: investigation.investigationId }}
                          onView={onInvestigationDocumentView}
                        />
                        <Button
                          aria-haspopup='listbox'
                          aria-controls='lock-menu'
                          onClick={e => {
                            handleClickListBtn(e, { ...document, investigationType: 'investigation' });
                          }}
                          style={Styles.investigation.moreButton}>
                          <MoreVert fontSize='small' />
                        </Button>
                      </Row>
                    ))}
                  </>
                )}
              </div>
            </div>
          ));
        })}
        <br />
        {data?.map((investigationData: GetInvestigationListResponse) => {
          return investigationData.applications?.map(application => (
            <div key={'application' + application.accountHolderId}>
              <div className='investigationBox'>
                <Row>
                  <Col md={6}>
                    <h3 className='subHeader1'>Application</h3>
                  </Col>
                  <Col md={6}>
                    <ContinueApplication accountId={accountId} />
                  </Col>
                </Row>

                {application.appCategories && (
                  <>
                    <h3 className='subHeader2'>APP Categories </h3>
                    <CipCategoryList categories={application.appCategories} />
                  </>
                )}
                {application.uploadedDocuments && (
                  <>
                    <h3 className='subHeader2'> Documents </h3>
                    {application.uploadedDocuments.map(document => (
                      //NOTE: we have used this way here because MaterialUi wont accept having popovers inside of children
                      <Row key={document.miscDocumentId} style={{ margin: 20 }} className='hoverItem'>
                        <DocumentDetails
                          {...document}
                          accountId={accountId}
                          investigation={{ type: 'application', accountHolderId: application.accountHolderId }}
                          onView={onApplicationDocumentView}
                        />
                        <Button
                          aria-haspopup='listbox'
                          aria-controls='lock-menu'
                          onClick={e => {
                            handleClickListBtn(e, { ...document, investigationType: 'application' });
                          }}
                          style={Styles.investigation.moreButton}>
                          <MoreVert fontSize='small' />
                        </Button>
                      </Row>
                    ))}
                  </>
                )}
              </div>
            </div>
          ));
        })}
      </>
      {curDocument && (
        <DocumentActionsDropdown
          accountId={accountId}
          element={curDocument}
          onClose={handleClose}
          anchorElement={anchorEl}
        />
      )}
      {viewDocument && <FileViewerModal onClose={onFileViewerModalClose} file={viewDocument} />}
    </div>
  );
};
