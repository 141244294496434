import * as _ from 'lodash';
import { GetListParams, GetListResult } from 'react-admin';
import { getClientApi } from 'src/data-communication';
import { OrderPositionDto, PrivateQuoteSnapDto } from 'src/dtos';
import {
  mapGetOrdersListResponseDtoToModel,
  mapGetPositionsListResponseDtoToModel,
  mapListParamsToBaseQueryParamsDto,
} from 'src/mappers';

import { Order, OrderPosition } from '../models/order.model';
import { SnapQuote } from '../models/quoteMedia.model';
import { getSnapQuoteList } from './snapQuotes.providers';

const clientApi = getClientApi();

export const getPositionList = async (params: GetListParams): Promise<GetListResult<OrderPosition>> => {
  const { accountId } = params.meta;
  let positions: OrderPosition[] = [];
  const { data } = await clientApi.orders.listPositions({
    params: {
      accountId,
    },
  });

  const symbols: string[] = data.map((aPositionDto: OrderPositionDto) => aPositionDto.Symbol);

  let snapQuotes: SnapQuote[] = [];

  try {
    if (symbols.length) {
      const response = await getSnapQuoteList({
        meta: {
          symbols,
        },
      });
      snapQuotes = response.data;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching quote media.');
    // eslint-disable-next-line no-console
    console.error(error);
  }

  const { data: privateQuotes } = await clientApi.privateQuotes.list({
    queryParams: {},
  });

  let privateQuotesSnap: PrivateQuoteSnapDto[] = [];

  try {
    const privateSymbols = privateQuotes.map(aPrivateQuote => aPrivateQuote.symbol);

    if (privateSymbols.length) {
      const privateQuotesSnapResponse = await clientApi.privateQuotes.snap({
        queryParams: {
          symbols: privateSymbols,
        },
      });
      privateQuotesSnap = privateQuotesSnapResponse.data;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error fetching private quotes snap.');
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return {
    data: mapGetPositionsListResponseDtoToModel(data, snapQuotes, privateQuotes, privateQuotesSnap),
    total: positions.length,
  };
};

export const getOrderList = async (params: GetListParams): Promise<GetListResult<Order>> => {
  const { accountId } = params.meta;

  const { data, total } = await clientApi.orders.list({
    params: {
      accountId,
    },
    queryParams: {
      ...mapListParamsToBaseQueryParamsDto({ ...params, meta: undefined }),
    },
  });

  const symbols: string[] = data.reduce((all: string[], current) => {
    if (current.UnderlyingSymbol) {
      all.push(current.UnderlyingSymbol);
    }

    return all;
  }, []);
  let snapQuotes: SnapQuote[] = [];

  try {
    if (!_.isEmpty(symbols)) {
      const response = await getSnapQuoteList({
        meta: {
          symbols,
        },
      });
      snapQuotes = response.data;
    }
  } catch (error) {
    // TODO: replace console logs with logger
    // eslint-disable-next-line no-console
    console.error('Error fetching quote media snap.');
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return {
    data: mapGetOrdersListResponseDtoToModel(data, snapQuotes),
    total,
  };
};
