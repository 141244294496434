import * as React from 'react';

import { FormDataConsumer, required, SelectInput, SimpleForm } from 'react-admin';
import { ConfigTypeDto } from 'src/dtos';

import { configOptions } from '../../constants/choices/config.choices';
import BankLinkCreateEdit from './components/BankLink/BankLinkCreateEdit';
import BankTransferCreateEdit from './components/BankTransfer/BankTransferCreateEdit';

interface ConfigCreateEditFormProps {
  isEditMode?: boolean;
}

export const ConfigCreateEditForm = ({ isEditMode = false }: ConfigCreateEditFormProps) => {
  return (
    <SimpleForm>
      <SelectInput
        label='Configuration Type'
        source='configType'
        choices={configOptions}
        validate={[required()]}
        fullWidth
        disabled={isEditMode}
      />
      <FormDataConsumer>
        {({ formData }) =>
          formData.configType === ConfigTypeDto.BANK_LINK && <BankLinkCreateEdit isEditMode={isEditMode} />
        }
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) =>
          formData.configType === ConfigTypeDto.BANK_TRANSFER && <BankTransferCreateEdit isEditMode={isEditMode} />
        }
      </FormDataConsumer>
    </SimpleForm>
  );
};

export default ConfigCreateEditForm;
