import { List, Datagrid, TextField, NumberField, DateField, TextInput, NumberInput, EditButton } from 'react-admin';

const defaultSort = { field: 'createdAt', order: 'DESC' };

const quoteFilters = [
  <TextInput key='symbol' label='Symbol/Ticker' source='symbol' />,
  <TextInput key='name' label='Name' source='name' alwaysOn />,
  <NumberInput key='purchasePrice' label='Purchase Price' source='purchasePrice' />,
  <NumberInput key='currentPrice' label='Current Price' source='currentPrice' />,
];

export const PrivateQuotesList = () => (
  <List title='Private Quotes' sort={defaultSort} filters={quoteFilters}>
    <Datagrid rowClick='show' bulkActionButtons={false}>
      <TextField source='symbol' sortBy='symbol' />
      <TextField source='name' sortBy='name' />
      <NumberField source='purchasePrice' sortBy='purchasePrice' options={{ style: 'currency', currency: 'USD' }} />
      <NumberField source='currentPrice' sortBy='currentPrice' options={{ style: 'currency', currency: 'USD' }} />
      <DateField source='createdAt' sortBy='createdAt' />
      <EditButton />
    </Datagrid>
  </List>
);
