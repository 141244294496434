import _ from 'lodash';
import {
  CreateParams,
  CreateResult,
  DeleteParams,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyReferenceParams,
  GetManyReferenceResult,
  GetOneParams,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { getClientApi } from 'src/data-communication';
import { OfferDocumentDto } from 'src/dtos';
import { paginate } from 'src/utils';

const clientApi = getClientApi();

export const listOfferDocuments = async (params: GetListParams): Promise<GetListResult<OfferDocumentDto>> => {
  const { pagination, sort } = params;

  const queryParams = {
    skip: pagination?.perPage && pagination.perPage * (pagination.page - 1),
    take: pagination?.perPage,
    sortBy: sort?.field,
    sortDirection: sort?.order.toLowerCase(),
  };
  const data = await clientApi.offerDocuments.list({});
  const sortDirection: 'asc' | 'desc' = queryParams.sortDirection?.toLowerCase() as 'asc' | 'desc';
  let result = _.orderBy(data, [queryParams.sortBy], [sortDirection]);

  if (params?.filter?.type) {
    result = result.filter(anOfferDocument => anOfferDocument.type === params.filter.type);
  }

  if (params?.filter?.offerId) {
    result = result.filter(anOfferDocument => anOfferDocument.offerId === params.filter.offerId);
  }
  const paginatedResult = paginate(result, params?.pagination?.perPage ?? result.length, params?.pagination?.page ?? 1);

  return {
    data: paginatedResult,
    total: data.length,
  };
};

export const getOfferDocument = async (
  params: GetOneParams<OfferDocumentDto>,
): Promise<GetOneResult<OfferDocumentDto & { _files: { src: string; title: string } }>> => {
  const data = await clientApi.offerDocuments.retrieve({ params });

  return {
    data: { ...data, _files: { src: data.url, title: data.url } },
  };
};

export const getManyReferenceOfferDocuments = async (
  params: GetManyReferenceParams,
): Promise<GetManyReferenceResult<OfferDocumentDto>> => {
  const data = await clientApi.offerDocuments.list({ queryParams: { offerId: params.id.toString() } });

  return {
    data,
    total: data.length,
  };
};

export const createOfferDocument = async (params: CreateParams): Promise<CreateResult<OfferDocumentDto>> => {
  const { offerId, _files } = params.data;
  const newOffer = _.omit(params?.data, ['_files']);

  const url = await clientApi.offerDocuments.upload({
    body: { rawFile: _files?.rawFile, filename: `offers/${offerId}/offer-document-${_files?.title}` },
  });

  const data = await clientApi.offerDocuments.create({
    body: {
      offerId,
      url,
      type: newOffer.type,
    },
  });

  return {
    data,
  };
};

export const patchOfferDocument = async (params: UpdateParams): Promise<UpdateResult<OfferDocumentDto>> => {
  const { id, type, url, _files, offerId } = params.data;

  let newUrl = url;

  if (_files?.rawFile) {
    newUrl = await clientApi.offerDocuments.upload({
      body: { rawFile: _files.rawFile, filename: `offers/${offerId}/offer-document-${_files?.title}` },
    });
  }

  const data = await clientApi.offerDocuments.patch({
    params: { id },
    body: { type, url: newUrl },
  });

  return {
    data,
  };
};

export const deleteOfferDocument = async (params: DeleteParams): Promise<DeleteResult<{ id: string }>> => {
  await clientApi.offerDocuments.delete({ params });

  return {
    data: { id: params.id.toString() },
  };
};
