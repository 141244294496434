import { ConfigService, getConfigService } from 'src/services/ConfigService';

import { AccountHoldersApi } from './AccountHoldersApi';
import { AccountsApi } from './AccountsApi';
import { AchRelationshipsApi } from './AchRelationshipsApi';
import { BalancesApi } from './BalancesApi';
import { ConfigsApi } from './ConfigsApi';
import { InvestigationsApi } from './InvestigationsApi';
import { LegacyUsersApi } from './LegacyUsersApi';
import { OfferDocumentsApi } from './OfferDocumentsApi';
import { OfferOptionsApi } from './OfferOptionsApi';
import { OfferOrdersApi } from './OfferOrdersApi';
import { OffersApi } from './OffersApi';
import { OfferWatchesApi } from './OfferWatchesApi';
import { OrdersApi } from './OrdersApi';
import { PrivateQuotesApi } from './PrivateQuotesApi';
import { SnapQuotesApi } from './SnapQuotesApi';
import { TransfersApi } from './TransfersApi';
import { UsersApi } from './UsersApi';

export class ClientApi {
  users: UsersApi;
  legacyUsers: LegacyUsersApi;
  investigations: InvestigationsApi;
  accounts: AccountsApi;
  accountHolders: AccountHoldersApi;
  balances: BalancesApi;
  offers: OffersApi;
  privateQuotes: PrivateQuotesApi;
  snapQuotes: SnapQuotesApi;
  configs: ConfigsApi;
  offerOrders: OfferOrdersApi;
  offerOptions: OfferOptionsApi;
  offerDocuments: OfferDocumentsApi;
  offerWatches: OfferWatchesApi;
  orders: OrdersApi;
  transfers: TransfersApi;
  achRelationships: AchRelationshipsApi;

  constructor(configService: ConfigService) {
    this.users = new UsersApi(configService);
    this.legacyUsers = new LegacyUsersApi(configService);
    this.investigations = new InvestigationsApi(configService);
    this.accounts = new AccountsApi(configService);
    this.accountHolders = new AccountHoldersApi(configService);
    this.balances = new BalancesApi(configService);
    this.offers = new OffersApi(configService);
    this.privateQuotes = new PrivateQuotesApi(configService);
    this.snapQuotes = new SnapQuotesApi(configService);
    this.configs = new ConfigsApi(configService);
    this.offerOrders = new OfferOrdersApi(configService);
    this.offerOptions = new OfferOptionsApi(configService);
    this.offerDocuments = new OfferDocumentsApi(configService);
    this.offerWatches = new OfferWatchesApi(configService);
    this.orders = new OrdersApi(configService);
    this.transfers = new TransfersApi(configService);
    this.achRelationships = new AchRelationshipsApi(configService);
  }
}

const clientApi = new ClientApi(getConfigService());

export const getClientApi = () => {
  return clientApi;
};
