import { GetOneParams, GetOneResult, UpdateManyParams, UpdateManyResult } from 'react-admin';
import { getClientApi } from 'src/data-communication/ClientApi';
import { DownloadAccountAgrementResponse } from 'src/models';

const clientApi = getClientApi();

export const syncAccountStatusList = async (params: UpdateManyParams): Promise<UpdateManyResult<{ id: string }>> => {
  const { ids } = params;
  await clientApi.accounts.syncStatusList(ids.map(anId => anId.toString()));

  return { data: ids.map(anId => anId.toString()) };
};

export const downloadAccountAgreement = async (
  params: GetOneParams,
): Promise<GetOneResult<DownloadAccountAgrementResponse>> => {
  const { id } = params;

  const response = await clientApi.accounts.downloadAgreement(id);

  const fileUrl = URL.createObjectURL(response);

  return {
    data: { id, file: { url: fileUrl, type: 'pdf' } },
  };
};
