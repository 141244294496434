import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { Form, TextInput, SaveButton, useNotify, useRefresh, useUpdate } from 'react-admin';
import { AchRelationshipActionType } from 'src/constants/choices/ach.relationships.choices';
import { AchRelationship, AchRelationshipDetails, SearchAchRelationshipItem } from 'src/models';

import Styles from '../../constants/styles';

export interface DecideRelationshipModalProps {
  type: AchRelationshipActionType;
  element: SearchAchRelationshipItem | AchRelationship | AchRelationshipDetails;
  openModal: boolean;
  modalClosed?: any;
  onSubmit?: any;
}

const DecideRelationshipModal = ({
  type = AchRelationshipActionType.APPROVE,
  element,
  openModal = false,
  modalClosed = function () {},
  onSubmit = null,
}: DecideRelationshipModalProps) => {
  const [open, setOpen] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  const handleClose = () => {
    setOpen(false);
    modalClosed();
  };
  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  const notify = useNotify();
  const refresh = useRefresh();
  const [update] = useUpdate();

  const getNotificationMessage = (type: AchRelationshipActionType) => {
    switch (type) {
      case AchRelationshipActionType.APPROVE:
        return notify('Approved successfully', { type: 'success' });
      case AchRelationshipActionType.REJECT:
        return notify('Rejected successfully', { type: 'success' });
      case AchRelationshipActionType.CANCEL:
        return notify('Canceled successfully', { type: 'success' });
      case AchRelationshipActionType.EDIT:
        return notify('Edited successfully', { type: 'success' });

      default:
        break;
    }
  };

  const handleSubmit = (dataToUpdate: any) => {
    if (onSubmit) return onSubmit(dataToUpdate);

    setBtnDisabled(true);
    update(
      'ACHRelationship.' + type,
      {
        id: dataToUpdate.relationshipId,
        data: {
          accountId: dataToUpdate.accountId,
          note: dataToUpdate.note,
          adminNote: dataToUpdate.admiNNote,
          comment: dataToUpdate.comment,
          nickName: dataToUpdate.nickName,
        },
      },
      {
        onError: (error: any) => {
          notify(error?.message ?? 'An uncaught error occurred!', { type: 'error' });
          handleClose();
        },
        onSuccess: () => {
          handleClose();
          getNotificationMessage(type);
          refresh();
        },
        onSettled: () => {
          setBtnDisabled(false);
        },
      },
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={Styles.achRelationship.modal}>
          <Typography variant='h6' component='h2'>
            {type} RELATIONSHIP
          </Typography>

          <Form onSubmit={handleSubmit} defaultValues={element}>
            {type !== AchRelationshipActionType.CANCEL && <TextInput source='note' fullWidth multiline />}
            {type === AchRelationshipActionType.CANCEL && <TextInput source='comment' fullWidth multiline />}
            {type === AchRelationshipActionType.EDIT && <TextInput source='nickName' fullWidth multiline />}

            <div style={{ display: 'flex', justifyContent: 'space-between', margin: 10 }}>
              <Button variant='outlined' color='inherit' onClick={handleClose}>
                Close
              </Button>
              <SaveButton disabled={btnDisabled} label={btnDisabled ? 'Loading' : type} />
            </div>
          </Form>
        </Box>
      </Modal>
    </div>
  );
};

export default DecideRelationshipModal;
