import * as React from 'react';

import { Grid } from '@mui/material';
import { format } from 'date-fns';
import {
  BooleanField,
  DateField,
  ImageField,
  ReferenceField,
  RichTextField,
  SelectField,
  SimpleShowLayout,
  TextField,
  useGetList,
  useGetOne,
  useRecordContext,
  WithRecord,
} from 'react-admin';
import { DataProviderResource, DateConstant } from 'src/constants';
import { OfferOptionTypeDto, GetOfferOrderListAggregateGroupByQueryParamDto } from 'src/dtos';
import { OfferOrderAggregate } from 'src/models/offerOrders.models';

import { sortOrderChoices } from '../../../../constants/choices/sort.order.choices';
import { OfferDetailsDto } from '../../../../dtos/offers.dtos';
import AccumulatedInvestmentField from './AccumulatedInvestmentField';

export const InfoShow = () => {
  const record = useRecordContext<OfferDetailsDto>();
  const [offerStatusName, setOfferStatusName] = React.useState<string>();
  const [ordersSummation, setOrdersSummation] = React.useState<OfferOrderAggregate[]>([]);

  const { data: offerOrderAggregate } = record.id
    ? useGetList<OfferOrderAggregate>(DataProviderResource.OfferOrdersAggregate, {
        filter: {
          offerId: record.id,
        },
        meta: {
          groupBy: [
            GetOfferOrderListAggregateGroupByQueryParamDto.OfferId,
            GetOfferOrderListAggregateGroupByQueryParamDto.Status,
          ],
        },
      })
    : { data: [] };

  const { data: offerStatus, isLoading } = useGetOne('offer-options', {
    id: record.statusId,
    meta: { type: OfferOptionTypeDto.STATUS },
  });

  React.useEffect(() => {
    setOfferStatusName(offerStatus?.name || '');
  }, [offerStatus]);

  React.useEffect(() => {
    setOrdersSummation(offerOrderAggregate ?? []);
  }, [offerOrderAggregate]);

  const addAccumulatedInvestment = () => {
    if (offerStatusName === 'Closed' && !isLoading)
      return <AccumulatedInvestmentField offerOrderAggregate={ordersSummation} />;
  };

  return (
    <Grid container direction='row' spacing={2}>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <h2>General Information</h2>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <ImageField source='logoUrl' title='title' />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6}>
        <SimpleShowLayout>
          <TextField label='Short URL' source='shortUrlRoute' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <TextField label='Name' source='name' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <DateField label='Start Date' source='startDate' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <TextField label='Close Date' source='closeDate' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <TextField label='Anticipated Start Date' source='anticipatedStartDate' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <TextField label='Yield' source='yield' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <TextField label='Yield description' source='yieldDescription' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <TextField label='Sector' source='sector' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <BooleanField label='Recurring' source='recurring' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <BooleanField label='Featured' source='featured' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <BooleanField label='New' source='new' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <BooleanField label='Visible to end users' source='visibleToEndUsers' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <SimpleShowLayout>
          <SelectField label='Sort Order' source='sortOrder' choices={sortOrderChoices} fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <ReferenceField source='statusId' reference='offer-options' link={false}>
            <TextField label='Offer Status' source='name' fullWidth />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <ReferenceField source='offerTypeId' reference='offer-options' link={false}>
            <TextField label='Offer Type' source='name' fullWidth />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <ReferenceField source='industryId' reference='offer-options' link={false}>
            <TextField label='Industry' source='name' fullWidth />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <ReferenceField source='categoryId' reference='offer-options' link={false}>
            <TextField label='Category' source='name' fullWidth />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <ReferenceField source='securityTypeId' reference='offer-options' link={false}>
            <TextField label='Security Type' source='name' fullWidth />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={6} sm={4} md={2}>
        <SimpleShowLayout>
          <BooleanField label='Allow Card Payments' source='allowCardPayments' fullWidth />
        </SimpleShowLayout>
      </Grid>
      {record.privateQuoteId && (
        <Grid item xs={4}>
          <SimpleShowLayout>
            <ReferenceField source='privateQuoteId' reference='private-quotes'>
              <TextField label='Private Quote' source='symbol' fullWidth />
            </ReferenceField>
          </SimpleShowLayout>
        </Grid>
      )}
      {record.subscriptionAgreementId && (
        <Grid item xs={6}>
          <SimpleShowLayout>
            <ReferenceField source='subscriptionAgreementId' reference='offer-documents'>
              <TextField label='Subscription Agreement' source='url' fullWidth />
            </ReferenceField>
          </SimpleShowLayout>
        </Grid>
      )}
      {addAccumulatedInvestment()}
      <Grid item xs={12}>
        <SimpleShowLayout>
          <WithRecord
            label='Close Date(s)'
            render={record => {
              return (
                <ul>
                  {record?.closeDates?.map((date: string) => (
                    <li key={`date_${date}`}>{format(new Date(date), DateConstant.US_FORMAT)}</li>
                  ))}
                </ul>
              );
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <WithRecord
            label='Non visible app fields'
            render={record => {
              return (
                <ul>
                  {record?.nonVisibleAppFields?.map((field: any) => (
                    <li key={`field_${field}`}>{field}</li>
                  ))}
                </ul>
              );
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <WithRecord
            label='Investment Ranges'
            render={record => {
              return (
                <ul>
                  {record?.investmentRanges?.map((field: any) => (
                    <li key={`field_${field}`}>{field}</li>
                  ))}
                </ul>
              );
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <RichTextField label='Exit Description' source='exitDescription' fullWidth />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={12}>
        <SimpleShowLayout>
          <RichTextField label='Subtext On Closed Offer' source='subtextOnClosed' fullWidth />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};
export default InfoShow;
