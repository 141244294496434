import * as React from 'react';

import countries from '../../assets/countries.json';

export interface CountryFieldProps {
  record: any;
  source: string;
}

const getCountry = (abbreviation: string) => {
  return countries.find(item => item.abbreviation === abbreviation);
};

const CountryField = (props: CountryFieldProps) => {
  const { source, record } = props;

  const abbreviation = record[source];
  const country = getCountry(abbreviation);

  return country ? <span>{country.name}</span> : null;
};
export default CountryField;
