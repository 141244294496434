import { ConfigUrlConstant } from 'src/constants';
import {
  ConfigDto,
  CreateConfigDto,
  DeleteConfigDto,
  GetConfigDto,
  GetConfigListDto,
  GetConfigListResponseDto,
  PatchConfigDto,
} from 'src/dtos';
import { ConfigService } from 'src/services';
import { httpRequest, replacePlaceholders } from 'src/utils';

export class ConfigsApi {
  private _configService: ConfigService;
  constructor(configService: ConfigService) {
    this._configService = configService;
  }

  async create({ body }: CreateConfigDto): Promise<ConfigDto> {
    return httpRequest<ConfigDto>({
      method: 'POST',
      url: ConfigUrlConstant.LIST,
      body,
      authToken: this._configService.authToken,
    });
  }

  async list({ queryParams }: GetConfigListDto): Promise<GetConfigListResponseDto> {
    return httpRequest<GetConfigListResponseDto>({
      method: 'GET',
      url: ConfigUrlConstant.LIST,
      queryParams,
      authToken: this._configService.authToken,
    });
  }

  async retrieve({ params }: GetConfigDto): Promise<ConfigDto> {
    const url = replacePlaceholders(ConfigUrlConstant.RETRIEVE, params);

    return httpRequest<ConfigDto>({
      method: 'GET',
      url,
      authToken: this._configService.authToken,
    });
  }

  async patch({ params, body }: PatchConfigDto): Promise<ConfigDto> {
    const url = replacePlaceholders(ConfigUrlConstant.PATCH, params);

    return httpRequest<ConfigDto>({
      method: 'PUT',
      url,
      body,
      authToken: this._configService.authToken,
    });
  }

  async delete({ params }: DeleteConfigDto): Promise<void> {
    const url = replacePlaceholders(ConfigUrlConstant.DELETE, params);

    await httpRequest<ConfigDto>({
      method: 'DELETE',
      url,
      authToken: this._configService.authToken,
    });
  }
}
