import React from 'react';

import styled from '@emotion/styled';
import PanToolIcon from '@mui/icons-material/PanTool';
import { Badge, Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Draggable } from 'react-beautiful-dnd';

import { DragDropElementTree } from '../ExportModal/models/export.models';

const useStyles = makeStyles({
  combineWithElement: {
    backgroundColor: (props: any) => {
      return props.combineTargetFor ? 'rgb(229 250 234)' : 'rgb(169 205 239)';
    },
  },
});

interface DraggableAreaProps {
  item: DragDropElementTree;
  indexedElement: number;
  children: React.ReactElement;
}

export const DraggableArea = ({ item, indexedElement, children }: DraggableAreaProps) => {
  const BootstrapBadge = styled(Badge)(() => ({
    margin: '0.5rem',
  }));

  return (
    <Draggable key={item.id} draggableId={item.id} index={indexedElement}>
      {(provided, snapshot) => {
        const classes = useStyles({ combineTargetFor: snapshot.combineTargetFor });

        return (
          <Grid item xs='auto' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <BootstrapBadge
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              overlap='rectangular'
              badgeContent={
                <PanToolIcon
                  style={{
                    width: 16,
                    height: 16,
                    color: '#2bb92b',
                  }}
                />
              }>
              <Box
                className={classes.combineWithElement}
                component={'div'}
                sx={{
                  p: 2,
                  border: '1px dashed grey',
                  position: 'relative',
                  boxShadow:
                    '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
                }}>
                {children}
              </Box>
            </BootstrapBadge>
          </Grid>
        );
      }}
    </Draggable>
  );
};
export default React.memo(DraggableArea);
